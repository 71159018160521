@charset "utf-8";

@use "global" as g;

.gBreadcrumb {
	position: relative;
	z-index: 4;
	margin: -32px 0 -32px;
	@include g.tabletP {
		margin: -26px 0 -26px;
	}
	.container {
		position: relative;
		display: flex;
		&::before,
		&::after {
			content: "";
			position: absolute;
		}
		&::before {
			background-color: #fff;
			border-radius: 5px;
			box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05);
			top: 0;
			bottom: 0;
			left: 32px;
			right: 32px;
			@include g.tabletP {
				left: 20px;
				right: 20px;
			}
		}
		&::after {
			background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 40%);
			border-radius: 0 5px 5px 0;
			top: 0;
			right: #{g.$containerPadding}px;
			width: 64px;
			height: 100%;
			pointer-events: none;
			@include g.tabletP {
				background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 20%);
				right: #{g.$containerPaddingSp}px;
				width: 25px + 5px;
			}
		}
	}
	&__inner {
		background-color: #fff;
		display: flex;
		align-items: center;
		position: relative;
		white-space: nowrap;
		min-height: 32px*2;
		margin: 0 40px;
		padding: 10px 0;
		overflow: auto;
		@include g.tabletP {
			min-height: 26px*2;
			margin: 0 20px;
		}
		&::after {
			content: "";
			display: block;
			flex-shrink: 0;
			width: .5em;
			height: 1em;
		}
	}
	&__item {
		display: flex;
		@include g.fz(12);
		@include g.tabletP {
			@include g.fz(10);
		}
		&:not(:last-child) {
			&::after {
				content: "";
				background: url(#{g.$cmnPath}ico_arrow02.svg) no-repeat center / cover;
				align-self: center;
				width: 5px;
				height: 9px;
				margin: 0 12px;
				transform: translateY(1px);
				@include g.tabletP {
					width: 4px;
					height: 7px;
					margin: 0 8px;
					transform: translateY(0px);
				}
			}
		}
		&[aria-current = "page"] {
			color: #616161;
		}
	}
}//.gBreadcrumb