@charset "utf-8";

/*
pr
*/


@use "global" as g;

$_id:pr;
$_imgPath:'../img/#{$_id}/';

body#page_#{$_id} {
	.mySection {
		$_: ".mySection";
		position: relative;
		& + #{$_} {
			@include g.tabletP {
				margin-top: 80px;
				padding-top: 84px;
				&::before {
					content: "";
					background-color: #eee;
					border-top: 1px solid nth(g.$partsColor, 2);
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 4px;
				}
			}
		}
	}//.mySection

	.myBlockBack {
		$_: ".myBlockBack";
		background-color: nth(g.$partsColor, 1);
		padding: 20px 0 80px;
		@include g.tabletP {
			padding: 20px 0 60px;
		}
	}//.myBlockBack

	.myTitleDetail {
		$_: ".myTitleDetail";
		background-color: nth(g.$partsColor, 1);
		&__container {
			padding-top: 60px;
			padding-bottom: 92px;
			@include g.tabletP {
				padding-top: 40px;
				padding-bottom: 66px;
			}
		}
		&__inner {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			gap: 30px 20px;
			@include g.tabletP {
				gap: 20px 20px;
			}
		}
		&__date {
			color: #616161;
		}
		&__title {
			@include g.fz(40);
			font-weight: 900;
			letter-spacing: 0.03em;
			line-height: 1.5;
			width: 100%;
			@include g.tabletP {
				@include g.fz(28);
			}
		}
		&__link {
			position: relative;
			color: nth(g.$mainColor, 1);
			@include g.fz(14);
			padding-left: 12px;
			@include g.tabletP {
				@include g.fz(12);
			}
			&::before {
				content: "";
				background-position: 0 0;
				background-repeat: no-repeat;
				background-image: url(#{g.$cmnPath}ico_arrow02.svg);
				background-size: 5px 8.57px;
				position: absolute;
				top: 8px;
				left: 0;
				width: 5px;
				height: 8.57px;
				@include g.tabletP{
					top: 6px;
				}
			}
		}
	}//.myTitleDetail

}
