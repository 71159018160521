@charset "utf-8";

/*
#overview
modTxt
*/

@use "global" as g;
@use 'sass:math';


/*
#styleguide
modTxtBold

```
<p class="modTxtBold">text</p>
```
*/

.modTxtBold{
	font-weight: bold;
}//modTxtBold


/*
#styleguide
modTxtCenter

```
<p class="modTxtCenter">text</p>
```
*/

.modTxtCenter {
	text-align: center;
}//.modTxtCenter


/*
#styleguide
modTxtBlock01

```

<div class="modTxtBlock01">
	<p>text</p>
	<p>text</p>
	<p>text</p>
</div>
```
*/

.modTxtBlock01{
	margin-bottom: 80px;
	@include g.tabletP{
		margin-bottom: 60px;
	}

	ul + p,
	ol + p,
	p + p{
		margin-top: 40px;
		@include g.tabletP{
			margin-top: 30px;
		}
	}

	p + ol,
	p + ul{
		margin-top: 20px;
		@include g.tabletP{
			margin-top:10px;
		}
	}

	a:not(.modTxtLink){
		text-decoration: underline;
		color:nth(g.$mainColor,1);
		&:hover{
			text-decoration: none;
		}
	}

	section>.container &{
		&:last-child{
			margin-bottom: 0;
		}
	}


}//.modTxtBlock01

/*
#styleguide
modTxtLink

```

<a class="modTxtLink" href="#"><span class="modTxtLink__txt">リンク</span></a>
<a class="modTxtLink -pdf" href="#"><span class="modTxtLink__txt">リンク（PDF）</span></a>
```
*/

.modTxtLink {
	$_: '.modTxtLink';

	display: inline-block;
	position: relative;
	padding-left: 15px;

	&:hover{
		#{$_}__txt{
			text-decoration: none;
		}
	}

	&__txt {
		position: relative;
		text-decoration: underline;

		&::before {
			content: "";
			position: absolute;
			top: 7px;
			left: -15px;
			background-position: 0 0;
			background-repeat: no-repeat;
			background-image: url(#{g.$cmnPath}ico_arrow02.svg);
			background-size: 7px 12px;
			width: 7px;
			height: 12px;

			@include g.tabletP{
				top: 6px;
				bottom: auto;
			}
		}
	}

	&.-pdf {
		&::after {
			content: "";
			position: relative;
			top:0.2em;
			background-position: 0 0;
			background-repeat: no-repeat;
			background-image: url(#{g.$cmnPath}ico_pdf.svg);
			background-size: 15px 20px;
			width: 15px;
			height: 20px;
			display: inline-block;
			margin-left: 12px;

			@include g.tabletP {
				background-size: 12px 16px;
				width: 12px;
				height: 16px;
				margin-left: 8px;
			}
		}
	}
}//.modTxtLink

/*
#styleguide
modTxtDate

```

<span class="modTxtDate">2022.02.02</span>
```
*/
.modTxtDate {
	display: inline-block;
	font-family: g.$fontAlpha;
	letter-spacing: 0.03em;
}

/*
#styleguide
modTxtCategory

```

<span class="modTxtCategory">出前館</span>
```
*/
.modTxtCategory {
	background-color: nth(g.$mainColor, 2);
	border-radius: 30px;
	display: inline-block;
	flex-shrink: 0;
	color: nth(g.$mainColor, 1);
	font-weight: bold;
	text-align: center;
	@include g.tabletPmin {
		@include g.fz(12);
		min-width: 104px;
		padding: 2px 20px 4px;
	}
	@include g.tabletP {
		@include g.fz(10);
		min-width: 90px;
		padding: 2px 20px;
	}
	&.-color02 {
		background-color: #fff;
		color: nth(g.$mainColor, 1);
	}
}


/*
#styleguide
modTxtCaution

```

<p class="modTxtCaution">※アクティブユーザー数とは、直近1年間に1回以上オーダーされたユーザー数を指します。</p>

```
*/

.modTxtCaution {
	@include g.fz(14);
	font-weight: 400;
	line-height: math.div(25, 14);
	letter-spacing: normal;
	margin-left: 1em;
	text-indent: -1em;
	color: #616161;
	padding-top: 5px;
    margin-bottom: 40px;

	@include g.tabletP{
		@include g.fz(12);
		line-height: math.div(21,12);
		padding-top: 2px;
		margin-bottom: 30px;
	}
}//.modTxtCaution
