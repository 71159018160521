@charset "utf-8";

@use "global" as g;


/* form
------------------------------------------------------------*/
textarea,
input[type="text"],
input[type="search"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="url"],
input[type="number"],
select{
	background-color: #fff;
	border:solid 1px map-get(g.$formColor, 'border');
	border-radius:5px;
	padding:9px 15px;

	&.error {
		background-color: map-get( map-get(g.$formColor, 'error'), bg );
		border-color:map-get( map-get(g.$formColor, 'error'), border );
	}

	&::placeholder{
		color:map-get(g.$formColor, 'placeholder');
	}

}


select,
textarea,
input[type="text"],
input[type="search"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="url"],
input[type="number"]{
	width: 100%;

	&.-middle{
		max-width: 384px;
	}
}



/* radio
（実装例）
<label class="modFormRadio"><input type="radio" class="modFormRadio__input"><span class="modFormRadio__label">ラジオボタン01</span></label>
<label class="modFormRadio"><input type="radio" class="modFormRadio__input"><span class="modFormRadio__label">ラジオボタン02</span></label>
-------------*/
.modFormRadio {
	$_: &;
	&__label {
		cursor: pointer;
		display: inline-block;
		padding: 3px 10px 3px 24px;
		position: relative;
		&::before,
		&::after {
			border-radius: 100%;
			content: '';
			position: absolute;
			top: 50%;
			transition: all .2s;
		}
		&::before {
			background:  map-get( map-get(g.$formColor, 'check'), bg );
			border: 1px solid map-get( map-get(g.$formColor, 'check'), border );
			height: 16px;
			left: 0;
			margin-top: -8px;
			width: 16px;
		}
		&::after {
			background: map-get( map-get(g.$formColor, 'check'), color );
			height: 10px;
			left: 3px;
			margin-top: -5px;
			opacity: 0;
			transform: scale(0.5);
			width: 10px;
		}
	}
	&__input {
		@include g.accessibilityHidden;
		&.error {
			& + #{$_}__label {
				&::before{
					background-color:map-get( map-get(g.$formColor, 'error'), bg );
					border: 1px solid  map-get( map-get(g.$formColor, 'error'), border );
				}
			}
		}
		&:checked {
			& + #{$_}__label {
				&::before {
					background:  map-get( map-get(g.$formColor, 'check'), bg02 );
					border: 1px solid map-get( map-get(g.$formColor, 'check'), color );
				}
				&::after {
					opacity: 1;
					transform: scale(1);
				}
			}
		}
	}
}

/* checkbox
（実装例）
<label class="modFormCheckbox"><input type="checkbox" class="modFormCheckbox__input"><span class="modFormCheckbox__label">チェックボックス01</span></label>
<label class="modFormCheckbox"><input type="checkbox" class="modFormCheckbox__input"><span class="modFormCheckbox__label">チェックボックス02</span></label>
-------------*/
.modFormCheckbox {
	$_: &;
	&__label {
		cursor: pointer;
		display: inline-block;
		padding: 3px 10px 3px 22px;
		position: relative;
		transition: all .2s;
		&::before,
		&::after {
			content: '';
			position: absolute;
		}
		&::before {
			background:  map-get( map-get(g.$formColor, 'check'), bg );
			border: 1px solid map-get( map-get(g.$formColor, 'check'), border );
			border-radius: 3px;
			height: 14px;
			left: 0;
			margin-top: -8px;
			top: 50%;
			width: 14px;
		}
		&::after {
			border-bottom: 2px solid map-get( map-get(g.$formColor, 'check'), color );
			border-left: 2px solid map-get( map-get(g.$formColor, 'check'), color );
			height: 4px;
			left: 3px;
			margin-top: -4px;
			opacity: 0;
			top: 50%;
			transform: rotate(-45deg) scale(0.5);
			width: 8px;
		}
	}
	&__input {
		@include g.accessibilityHidden;
		&:checked {
			& + #{$_}__label {
				&::before {
					background:  map-get( map-get(g.$formColor, 'check'), bg02 );
					border: 1px solid map-get( map-get(g.$formColor, 'check'), color );
				}
				&::after {
					opacity: 1;
					transform: rotate(-45deg) scale(1);
				}
			}
		}
	}
}
