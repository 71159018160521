@charset "utf-8";

@use "global" as g;

#wrapper {}
.gWrapper {
	padding-top: g.$headerHeight;
	@include g.tabletP {
		padding-top: g.$headerHeightSp;
	}
}