@charset "utf-8";

/*
safety guideline
*/


@use "global" as g;
@use 'sass:math';

$_id:ir_information/policy/safety_guideline;
$_imgPath:'../img/#{$_id}/';

.brpc {
    display: inline;
    @include g.tabletP {
        display: none;
    }
}

html {
    scroll-padding-top: 100px;
}

.modTable01__tr {
    &:not(:last-child) {
        td {
            border-bottom: 0;
        }
    }
    .modTable01__td {
        padding: 40px 0;
        display: flex;
        flex-direction: column;
        @include g.tabletP {
            padding: 20px 0;
        }

        &__title {
            line-height: 1.5;
            font-size: 20px;
            font-weight: 800;
            letter-spacing: 0.03em;
            margin-bottom: 10px;

            @include g.tabletP {
                font-size: 15.8px;
                margin-bottom: 10px;
            }
        }
    }
}

.modTxtBlock01 {
    &.mb-half{
        margin-bottom: 40px;
        @include g.tabletP {
            margin-bottom: 30px;
        }
    }
    &.mb-small {
        margin-bottom: 30px;
        @include g.tabletP {
            margin-bottom: 30px;
        }
    }
}

.subSection {
    .modTxtBlock01 {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .heading {
            font-weight: 700;
        }

        p {
            margin: 0;
        }
    }

    &:not(:last-child){
        margin-bottom: 80px;
        @include g.tabletP {
            margin-bottom: 60px;
        }
    }
}

.iconWithLabelWrapper {
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;

    @include g.tabletP {
        grid-template-columns: 1fr;
        gap: 30px;
    }

    .iconWithLabel{
        display: grid;
        grid-template-rows: subgrid;
        grid-row: span 2;
        gap: 5px;
        justify-items: center;
        text-align: center;

        @include g.tabletP {
            max-width: 250px;
        }

        .iconWithLabel__icon {
            width: 176px;
            &.no-square {
                width: 176px;
                aspect-ratio: 176 / 142;
                margin: 0;
            }
            aspect-ratio: 1;
            object-fit: contain;
        }

        &__label{
            word-break: keep-all;
            span {
                white-space: nowrap;
            }

            &.small {
                @include g.fz(14);
            }
        }
    }
}

.linkCardWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @include g.tabletP {
        gap: 10px;
    }

    .linkCard {
        display: flex;
        flex-direction: row;
        background-color: #f5f5f5;
        padding: 20px;
        gap: 21px;
        border-radius: 5px;
        gap: 21px;

        @include g.tabletP {
            padding: 15px;
            gap: 15px;
        }

        img {
            width: 120px;
            height: 120px;
            object-fit: contain;
            padding: 7.5px;
            border-radius: 5px;
            background-color: white;
        }

        .title {
            font-weight: 700;
            margin-bottom: 4px;
            letter-spacing: .03em;
            line-height: 1.5;
            @include g.fz(18);
            @include g.tabletP {
                @include g.fz(14);
                line-height: 1.75;
                margin-bottom: 0;
                letter-spacing: unset;
            }
        }

        .description {
            margin-bottom: 16px;
            @include g.fz(14);
            @include g.tabletP {
                @include g.fz(12);
                margin-bottom: 10px;
            }
        }

    }
}

.extLink {
    $_1: &;

    display: block;
    float: right;
    position: relative;
    text-decoration: underline;
    line-height: 1.75;
    @include g.fz(14);
    margin-right: 20px;
    &:hover {
        text-decoration: underline;
    }
    @include g.tabletP {
        margin-right: 15px;
        @include g.fz(12);
    }
    
    &__icon {
        position: absolute;
        @include g.tabletPmin {
            font-size: 12px;
            margin: .55em 0 0 5px;
        }
        @include g.tabletP {
            font-size: 10px;
            margin: .7em 0 0 5px;
        }
        width: 1em;
        height: 1em;
        &::after {
            content: "";
            background: url(#{g.$cmnPath}ico_blank.svg) no-repeat center / cover;
            position: absolute;
            top: 0;
            left: 0;
            width: inherit;
            height: inherit;
            transition: transform map-get(g.$iconMove02, 'sp') map-get(g.$iconMove02, 'easing');
        }
        #{$_1}:hover & {
            &::after {
                transform: scale(-1, -1);
            }
        }
    }
}

.iconWithDescriptionWrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;
    &.narrow {
        gap: 30px;
    }

    @include g.tabletP {
        gap: 30px;
    }

    .iconWithDescription{
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-items: center;
        @include g.tabletP {
            align-items: start;
        }

        img {
            max-width: 120px;
            max-height: 120px;
            min-width: 120px;
            min-height: 120px;
            @include g.tabletP {
                max-width: 96px;
                max-height: 96px;
                min-width: 96px;
                min-height: 96px;
            }
            flex-shrink: 0;
            aspect-ratio: 1;
            object-fit: contain;


            border: 2px solid #e0e0e0;
            border-radius: 5px;

            @include g.tabletP {
                width: 84px;
                padding: 6px;
            }
        }

        .title {
            flex-grow: 1;
            font-weight: 700;
            line-height: 1.5;
            @include g.fz(18);
            letter-spacing: 0.03em;
            @include g.tabletP {
                @include g.fz(14);
                letter-spacing: 0;
            }
        }

        .description {
            @include g.tabletP {
                @include g.fz(14);
            }
        }

        
    }
}

.actionOnAccident {
  list-style: none;
  padding-left: 0;
  display: table;
  @include g.fz(16);
  @include g.tabletP {
    @include g.fz(14);
  }
  line-height: 1.75;

  li {
    display: table-row;
    counter-increment: table-ol;
    height: 1.75em;
    @include g.tabletP {
        margin-bottom: 5px;
    }

    &:after {
        content: "";
        display: block;
        margin-bottom: 0.5em;
    }
    &:before {
        content: counter(table-ol) ".";
        display: table-cell;
        padding-right: 0.4em;
        text-align: left;
    }
  }
}

.reliefMoneyContainer {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    .reliefMomney {
        padding: 16px 0;
        border-radius: 5px;
        border: 1px solid #DA3734;
        line-height: 1.75;
        font-weight: 700;
        text-align: center;
        word-break: keep-all;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .small {
            @include g.fz(10);
        }
        
        @include g.fz(14);
        @include g.tabletP {
            @include g.fz(12);
        }
    }
}
