@charset "utf-8";

/*
modal
*/


@use "global" as g;

html:has(.commonModal__bg.open) {
    overflow: hidden;
}

.commonModal {
    &__bg {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1000;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s;

        &.open {
            visibility: visible;
            opacity: 1;
        }
    }

    &__content {
        position: fixed;
        display: flex;
        align-items: center;
        flex-direction: column;
        z-index: 1001;
        border-radius: 20px;
        background-color: #fff;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.3s;
        outline: none;
        opacity: 0;
        pointer-events: none;
        @include g.tabletP {
            bottom: 0;
            width: 100%;
            left: 0;
            top: unset;
            transform: translate(0, 100%);
            border-radius: 16px 16px 0 0;
            opacity: 1;
        }
        visibility: hidden;
        transition: all 0.3s;

        &.open {
            pointer-events: unset;
            visibility: visible;
            transform: translate(-50%, -50%);
            @include g.tabletP {
                transform: translate(0, 0);
            }
            opacity: 1;
        }

        .topThumb {
            display: none;
            cursor: pointer;
            @include g.tabletP {
                display: block;
            }
            position: relative;
            &:before {
                content: "";
                position: absolute;
                border-radius: 2px;
                background-color: #e0e0e0;
                width: 48px;
                height: 4px;
                left: 0;
            }
            margin: -10px -20px;
            padding: 20px;
            width: 48px;
            height: 4px;
        }

        .header {
            display: flex;
            position: relative;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
            margin: 24px 0 12px;
            @include g.tabletP {
                margin: 12px 0;
            }
            width: 100%;
            height: 32px;

            .title {
                font-weight: 800;
                margin-left: 24px;
                line-height: 1.5;
                
                @include g.tabletPmin{
                    @include g.fz(20);
                    letter-spacing: 0.03em;
                }

                @include g.tabletP {
                    @include g.fz(16);
                }
            }
            .close {
                position: relative;
                margin-right: 24px;
                width: 32px;
                height: 32px;
                background: transparent;
                border: none;
                outline: none;
                border-radius: 16px;

                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 32px;
                    height: 32px;
                    display: block;
                    background-color: #eeeef0;
                    border-radius: 16px;
                }
                &:after {
                    content: "";
                    position: absolute;
                    background-image: url(#{g.$cmnPath}ico_close_black.svg);
                    width: 16px;
                    height: 16px;
                    top: 8px;
                    left: 8px;
                    display: block;
                }
            }
        }


        .separator {
            display: block;
            width: 100%;
            height: 1px;
            background-color: #e5e5e5;
        }

        .body {
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 24px;
            width: 100%;

            line-height: 1.75;
            @include g.fz(16);
            @include g.tabletP {
                @include g.fz(14);
            }

            .heading {
                font-weight: 700;
            }

            .mb-10 {
                margin-bottom: 10px;
            }
        }
        
    }
}