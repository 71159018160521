@charset "utf-8";

/*
#overview
modTitle
*/

@use "global" as g;
@use 'sass:math';

$_imgPath: "../img/";

@function to-string($list, $glue: '') {
  $result: '';

  @for $i from 1 through length($list) {
    $e: nth($list, $i);

	$result: if(
	$i == 1,
	$e,
	$result + $glue + $e
	);
  }

  @return $result;
}
/*
#styleguide
modTitlePage

```
<h1 class="modTitlePage -pattern">
		<span class="container modTitlePage__container">
			<span class="modTitlePage__txtSub">Sharing delivery</span>
			<span class="modTitlePage__txtMain">企業の配送事業受託について</span>
		</span>
	</h1>
```
*/
.modTitlePage {
	$_: &;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	color: #fff;
	z-index: 3;
	@each $_id, $_classlist in (
		company: (index, message, profile, cg, history, executive),
		ir_information: ((policy, safety_guideline), ),
		business: (e-gift),
		under: (index, under)
	) {
		@each $_class in $_classlist {
			$__class: $_class;
			$_path: $_class;
			@if type-of($_class) == list {
				$__class: to-string($_class, '.-');
				$_path: to-string($_class, '/');
			}
			$k: type-of($_class);
			#page_#{$_id}.-#{$__class} & {
				background-image: url(#{$_imgPath}#{$_id}/#{$_path}/bg_titlePage.jpg);
				@include g.retina {
					background-image: url(#{$_imgPath}#{$_id}/#{$_path}/bg_titlePage@2x.jpg);
				}
				@include g.tabletP {
					background-image: url(#{$_imgPath}#{$_id}/#{$_path}/bg_titlePage_sp.jpg);
					@include g.retina {
						background-image: url(#{$_imgPath}#{$_id}/#{$_path}/bg_titlePage_sp@2x.jpg);
					}
				}
			}
		}
	}

	&.-pattern{
		background-image: url(#{g.$cmnPath}bg_title_pattern.png);
		background-position: 0 0;
		background-size: 268px auto;
		background-repeat: repeat;

		@include g.tabletP{
			background-size: 134px auto;
		}

		@include g.retina(){
			background-image: url(#{g.$cmnPath}bg_title_pattern@2x.png);
		}
	}

	&.-vision {
		background-image: url(/assets/img/company/vision/bg_titlePage.jpg);
		@include g.retina {
			background-image: url(/assets/img/company/vision/bg_titlePage@2x.jpg);
		}
		@include g.tabletP{
			background-color: #DA3734;
			background-image: url(/assets/img/company/vision/bg_title_vision_sp.png);
			background-size: 207px 200px;
			background-position: right 18px top 16px;
			@include g.retina {
				background-image: url(/assets/img/company/vision/bg_title_vision_sp@2x.png);
			}
			&::after {
				content: "";
				background: rgba(0, 0, 0, 0.4);
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
			}
			#{$_}__container {
				position: relative;
				z-index: 2;
			}
		}
	}
	
	&.-guideline {
		background-image: url(/assets/img/company/guideline/bg_titlePage.jpg);
		@include g.retina {
			background-image: url(/assets/img/company/guideline/bg_titlePage@2x.jpg);
		}
		@include g.tabletP{
			background-color: #DA3734;
			background-image: url(/assets/img/company/guideline/bg_title_vision_sp.png);
			background-size: 223px 242px;
			background-position: right 0 top 2px;
			@include g.retina {
				background-image: url(/assets/img/company/guideline/bg_title_vision_sp@2x.png);
			}
			&::after {
				content: "";
				background: rgba(0, 0, 0, 0.4);
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
			}
			#{$_}__container {
				position: relative;
				z-index: 2;
			}
		}
	}

	&__container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: 400px;
		padding-bottom: 32px;
		@include g.tabletP {
			min-height: 260px;
			padding-bottom: 26px;
		}
	}
	&__txtSub {
		font-family: g.$fontAlpha;
		font-weight: bold;
		letter-spacing: 0.03em;
		margin-bottom: 10px;
	}
	&__txtMain {
		@include g.fz(40);
		letter-spacing: 0.03em;
		font-weight: 900;
		line-height: 1.5;
		@include g.tabletP {
			@include g.fz(28);
		}

		body#page_en &{
			font-family: g.$fontAlpha;
		}
	}
}//.modTitlePage

/*
#styleguide
modTitle01

```
	<h2 class="modTitle01">テクノロジーで時間価値を高める</h2>
```
*/

.modTitle01{
	position: relative;
	padding-top: 40px;
	margin-bottom: 40px;
	font-weight: 900;
	@include g.fz(32);
	line-height: 1.5;
	letter-spacing: 0.03em;
	@include g.tabletP{
		padding-top: 33px;
		margin-bottom: 30px;
		@include g.fz(24);
	}

	body#page_en &{
		font-family: g.$fontAlpha;
	}

	&::before{
		content:"";
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 40px;
		height: 3px;
		border-radius: 10px;
		background-color: nth(g.$mainColor,1);
	}

	& + .modTable01{
		margin-top: 60px;
		@include g.tabletP{
			margin-top: 40px;
		}
	}


}//.modTitle01

.modSubtitle01 {
	position: relative;

	@include g.fz(20);
	font-weight: 800;
	line-height: 1.5;
	text-align: left;
	margin-bottom: 20px;
	letter-spacing: 0.03em;

	@include g.tabletP{
		@include g.fz(15.8);
		letter-spacing: unset;
	}


	body#page_en &{
		font-family: g.$fontAlpha;
	}
}//.modSubtitle01



/*
#styleguide
modTitle02

```
	<h3 class="modTitle02">ビジネスモデル</h3>
```
*/

.modTitle02 {
	@include g.fz(28);
	font-weight: 900;
	line-height: math.div(42, 28);
	line-height: 42px;
	letter-spacing: 0.03em;
	margin-bottom: 40px;

	@include g.tabletP {
		@include g.fz(21);
		font-size: 21px;
		line-height: math.div(32, 21);
		line-height: 32px;
		margin-bottom: 30px;
	}


	body#page_en &{
		font-family: g.$fontAlpha;
	}

}//.modTitle02



/*
#styleguide
xxx

```
```
*/
.xxx {
	$_: &;
}//.xxx
