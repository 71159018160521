@charset "utf-8";

/*
top
*/


@use "global" as g;
@use 'sass:math';

$_id:top;
$_imgPath:'../img/#{$_id}/';

$spFixedMvScale: 1.8;
$spFixedMvWidth: 375 * 1.8;
@mixin spForMV {
	@media only screen and (max-width: 1100px) {
		@content;
	}
}
@mixin spSforMV {
	@media only screen and (max-width: #{375 * 1.8 * 1px}) {
		@content;
	}
}
@mixin pcMforMV {
	@media only screen and (max-width: 1600px) {
		@content;
	}
}

body#page_#{$_id} {

	$_fadeInBottomEasing:g.$ease;
	$_fadeInBottomSp:0.4s;
	$_fadeInBottomOff:50px;

	@keyframes fadeInBottom {
		0% {
			transform: translate3d(0,$_fadeInBottomOff,0);
			opacity: 0;
		}

		to {
			transform: translate3d(0,0,0);
			opacity: 1;
		}
	}
	&.IE {
		.myMvOverLayer {
			display: none;
		}
		.myMainvisualMain__img {
			display: block;
		}
		.myMainvisualMain__lead {
			span:first-child {
				opacity: 1;
			}
		}
	}
	.myMvOverLayer {
		position: fixed;
		z-index: 10;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		// background: transparent;
		background: #fff;
		&.is-playOpening {
			background: transparent;
		}
		&.is-hide {
			display: none;
		}
	}
	.myMainvisual{
		position: relative;
		&__newsWrap {
			position: absolute;
			bottom: 0;
			left: 50%;
			z-index: 6;
			width: 100%;
			transform: translate(-50%,50%);
			@include spForMV{
			}
		}

	}//.myMainvisual

	.myMainvisualMain {
		position: relative;
		margin-top: -#{g.$headerHeight};
		height: 820px;
		@include pcMforMV{
			height: g.get_vw(820, 1600);
		}
		@include spForMV{
			height: 667 * 1.8 * 1px;
		}
		@include spSforMV{
			height: g.get_vw(667, 375);
		}

		@include g.tabletP {
			margin-top: -#{g.$headerHeightSp};
		}
		&__inner {
			position: absolute;
			top: 0;
			left: g.get_vw(1096, 1600);
			z-index: 6;
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 100%;
			color: #fff;
			@include spForMV{
				display: block;
				top: auto;
				left: 0;
				width: 100%;
				height: auto;
				bottom: 95 * $spFixedMvScale * 1px;
				padding: 0;
			}
			@include spSforMV{
				bottom: g.get_vw(88, 375);
				padding: 0;
			}
		}
		&__title {
			font-size: g.get_vw(46, 1600);
			font-weight: 900;
			line-height: 1.5;
			@include spForMV{
				margin-right: 30 * $spFixedMvScale * 1px;
				margin-left: 30 * $spFixedMvScale * 1px;
				font-size: 28 * $spFixedMvScale * 1px;
			}
			@include spSforMV{
				margin-right: g.get_vw(30, 375);
				margin-left: g.get_vw(30, 375);
				font-size: g.get_vw(28, 375);
			}
		}
		&__lead {
			position: relative;
			margin-top: 30px;
			font-size: g.get_vw(18, 1600);
			font-weight: 700;
			height: 1.8em;
			@include spForMV{
				margin-top: 15 * $spFixedMvScale * 1px;
				margin-right: 30 * $spFixedMvScale * 1px;
				margin-left: 30 * $spFixedMvScale * 1px;
				font-size: 14 * $spFixedMvScale * 1px;
			}
			@include spSforMV{
				margin-top: g.get_vw(15, 375);
				margin-right: g.get_vw(30, 375);
				margin-left: g.get_vw(30, 375);
				font-size: g.get_vw(14, 375);
			}
			span {
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0;
			}
		}
		&__btn {
			max-width: g.get_vw(280, 1600);
			min-width: 280px;
			margin-top: 60px;
			@include spForMV{
				max-width: none;
				min-width: 0;
				width: 100%;
				margin-top: 30 * $spFixedMvScale * 1px;
				padding-right: 20 * $spFixedMvScale * 1px;
				padding-left: 20 * $spFixedMvScale * 1px;
			}
			@include spSforMV{
				margin-top: g.get_vw(30, 375);
				padding-right: g.get_vw(20, 375);
				padding-left: g.get_vw(20, 375);
			}
			.modBtn01 {
				background: transparent;
				border: 0;
				font-family:g.$fontAlpha;
				font-weight: 700;
				letter-spacing: 0.03em;
				padding: g.get_vw(19 + 2, 1600) g.get_vw(40 + 2, 1600);
				border-radius: g.get_vw(40, 1600);
				@include spForMV{
					padding: ((17 + 2) * $spFixedMvScale * 1px) ((54 + 2) * $spFixedMvScale * 1px);
					font-size: (14 + 2) * $spFixedMvScale * 1px;
					border-radius: 40px * $spFixedMvScale;
				}
				@include spSforMV{
					padding: g.get_vw(17 + 2, 375) g.get_vw(54 + 2, 375);
					font-size: g.get_vw(14 + 2, 375);
					border-radius: g.get_vw(40, 375);
				}
				&:before {
					transform: scale(0);
					opacity: 0;
					width: 100%;
					height: 100%;
					border-radius: g.get_vw(40, 1600);
					@include spForMV{
						border-radius: 40px * $spFixedMvScale;
					}
					@include spSforMV{
						border-radius: g.get_vw(40, 375);
					}
				}
				&:after {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					display: block;
					width: 100%;
					height: 100%;
					border-radius: g.get_vw(40, 1600);
					border : solid 2px #fff;
					@include spForMV{
						border-radius: 40px * $spFixedMvScale;
					}
					@include spSforMV{
						border-radius: g.get_vw(40, 375);
					}
				}
				@include g.tabletPmin{
					&:hover {
						&:before {
							transition: all .3s cubic-bezier(.23,1,.58,1);
							transform: scale(1);
							opacity: 1;
						}
					}
				}
				&__icon.-arrowR {
					right: g.get_vw(25, 1600);
					@include spForMV{
						right: 25 * $spFixedMvScale * 1px;
						width: 14 * $spFixedMvScale * 1px;
						height: 12 * $spFixedMvScale * 1px;
					}
					@include spSforMV{
						right: g.get_vw(25, 375);
						width: g.get_vw(14, 375);
						height: g.get_vw(12, 375);
					}
					&:after {
						@include spForMV{
							width: 14 * $spFixedMvScale * 1px;
							height: 12 * $spFixedMvScale * 1px;
							background-size: (14 * $spFixedMvScale * 1px) (12 * $spFixedMvScale * 1px);
						}
						@include spSforMV{
							width: g.get_vw(14, 375);
							height: g.get_vw(12, 375);
							background-size: g.get_vw(14, 375) g.get_vw(12, 375);
						}
					}
				}
			}
		}
		&__img {
			// IE11用メインビジュアル画像
			display: none;
			width: 100%;
			background: url(#{$_imgPath}mv/img_mv_bg.jpg) no-repeat #DA3734;
			background-size: 100% auto;
			text-align: center;
			height: 820px;
			@include pcMforMV{
				height: g.get_vw(820, 1600);
			}
			@include spForMV{
				height: 667 * 1.8 * 1px;
				background-image: url(#{$_imgPath}mv/img_mv_bg_sp.jpg);
			}
			@include spSforMV{
				height: g.get_vw(667, 375);
			};
			img {
				width: 100%;
				height: auto;
				max-width: 1600px;
				@include spForMV{
					max-width: 375 * 1.8 * 1px;
				}
			}
		}
		canvas {
			position: relative;
			z-index: 5;
			width: 100%;
			pointer-events: none;
			// height: 100vh;
			:focus {
				outline: none;
			}
		}
	}//.myMainvisualMain
	.myMainvisualNews{
		background-color: #fff;
		box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05);
		border-radius: 5px;
		padding: 26px 36px 28px 42px;
		position: relative;

		@include g.tabletPmin{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
		}

		@include g.tabletP{
			padding: 15px 20px 18px;
		}

		$_:'.myMainvisualNews';

		&__title{
			width: 4.8em;
			color:nth(g.$mainColor,1);
			font-weight: 700;
			@include g.fz(14);
			@include g.tabletP{
				background-color: #fff;
				position: absolute;
				top: 15px;
				left: 20px;
				z-index: 1;
			}
		}

		&__inner{
			padding-right: 100px;
			overflow: hidden;
			@include g.tabletPmin{
				width: calc(100% - 4.8em);
			}

			@include g.tabletP{
				padding-right: 50px;
			}
		}

		.slick-arrow{
			position: absolute;
			bottom:0;
			top: 0;
			margin: auto 0;
			border:none;
			background-color: transparent;
			width: 7px;
			height: 12px;
			text-indent: -9999px;
			z-index: 10;
			color:nth(g.$mainColor,1);

			&::before{
				content: '';
				display: inline-block;
				background-image: url(#{g.$cmnPath}ico_arrow02.svg);
				background-size: 7px 12px;
				background-color: transparent;
				background-repeat: no-repeat;
				background-position: center;
				width: 7px;
				height: 12px;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				margin: auto 0;
			}
		}

		.slick-prev{
			right:-68px;
			&::before{
				transform: scale(-1, 1);
			}
			@include g.tabletP{
				right: -18px;
			}
		}

		.slick-next{
			right:-94px;
			@include g.tabletP{
				right: -48px;
			}
		}

		&__item{

			@at-root body#page_#{$_id} #{$_}__body:not(.slick-slider) #{$_}__item {
				&:not(:first-child) {
					display: none;
				}
			}

			&-link{

				@include g.tabletPmin{
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;

				}

				&:hover{
					@include g.tabletPmin{
						opacity: 1;
						#{$_}__item-date{

							color:nth(g.$mainColor,1);
						}
					}
				}
			}

			&-date{
				font-family: g.$fontAlpha;
				font-weight: 400;
				@include g.fz(14);
				letter-spacing: 0.03em;
				color: #616161;

				@include g.tabletPmin{
					width: 6.7em;

				}

				@include g.tabletP{
					margin-bottom: 8px;
					margin-left: calc(4em + 15px);
				}
			}

			&-txt{
				@include g.fz(14);
				@include g.tabletPmin{
					flex:1;

				}
			}
		}


	}//.myMainvisualNews

	.myTitle01{

		&::after{
			content:"";
			display: block;
			width: 40px;
			height: 3px;
			margin-top: 40px;
			background: #DA3734;
			border-radius: 10px;
			@include g.tabletP{
				margin-top: 30px;
			}

		}

		&__sub{
			margin-bottom: 10px;
			color:nth(g.$mainColor,1);
			font-family:g.$fontAlpha;
			font-weight: 700;
			letter-spacing: 0.03em;

		}
		&__title{
			font-weight: 900;
			@include g.fz(32);
			line-height: 1.5;
			letter-spacing: 0.03em;
			@include g.tabletP{
				@include g.fz(24);
			}
		}
	}//.myTitle01

	.myBlockMenu{
		padding-block: 120px 125px;

		@include g.tabletP{
			padding-block:110px 80px;
		}

		$_:'.myBlockMenu';

		&__body{

			@include g.sphonePmin{
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				gap:0 32px;
			}
		}


		&__item{
			position: relative;
			display: block;
			overflow: hidden;
			border-radius: 5px;
			@include g.sphonePmin{
				width: calc((100% - 32px)/2);
				margin-top: 32px;
				&:nth-child(-n + 2){
					margin-top: 0;
				}
			}

			@include g.sphoneP{
				&:not(:last-child){
					margin-bottom: 20px;
				}
			}

			&::after{
				content:"";
				background-color: rgba(#000,0.4);
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				transition:background-color 0.25s g.$easeOut;
			}

			@include g.tabletPmin{
				&:hover{
					// opacity: 1;
					// background-color: nth(g.$mainColor,1);
					// color:#fff;

					&::after{
						background-color: rgba(#000,0.5);

					}

					#{$_}__image{
						img{
							transform: scale(1.06) rotate(.001deg);
						}
					}

					#{$_}__icon{
						&::after{
							animation: iconMove map-get(g.$iconMove, 'sp') map-get(g.$iconMove, 'easing') map-get(g.$iconMove, 'delay') 1 map-get(g.$iconMove, 'end');
						}
					}

				}
			}

		}

		&__inner{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			z-index: 1;
			padding-bottom: 12px;
		}

		&__image{
			text-align: center;

			img{
				width: 100%;
				transition:transform 0.25s g.$easeInOut;
			}

		}

		&__detail{

		}

		&__sub,
		&__title{
			display: block;
		}

		&__sub{
			margin-bottom: 5px;
			font-family: g.$fontAlpha;
			font-weight: 700;
			text-align: center;
			letter-spacing: 0.03em;
			color:nth(g.$mainColor,1);
			@include g.tabletP{
				@include g.fz(14);
			}
		}

		&__title{
			font-weight: 900;
			@include g.fz(32);
			line-height: 1.5;
			text-align: center;
			letter-spacing: 0.03em;
			color: #FFFFFF;
			@include g.tabletP{
				@include g.fz(24);
			}

		}

		&__icon{
			position: absolute;
			right: 40px;
			inset-block:0;
			margin-block:auto;
			width: 30px;
			height: 30px;
			background-color: nth(g.$mainColor, 1);
			border-radius: 50%;
			transition:color,background-color 0.3s ease;
			overflow: hidden;
			z-index: 1;
			@include g.tabletP {
				right: 20px;
				width: 20px;
				height: 20px;
			}

			&::after{
				content:"";
				background-image: url(#{g.$cmnPath}ico_circle_arrow.svg);
				position: absolute;
				left: 0;
				inset-block:0;
				width: 30px;
				height: 30px;
				background-size: 30px 30px;
				background-repeat: no-repeat;
				background-position: 0 0;
				margin-block:auto;
				@include g.tabletP{
					width: 20px;
					height: 20px;
					background-size: 20px 20px;
				}
			}

		}

	}//.myBlockMenu

	.myBlockNews{
		padding-block:114px 125px;
		position: relative;

		$_:'.myBlockNews';

		@keyframes newsBg {
			0% {
				width: 0;
			}

			to {
				width: 100%;
			}
		}

		&.aos-animate{
			&::before{
				animation: newsBg 0.4s g.$easeInCubic forwards 0.0s;
			}
		}

		&::before{
			content:"";
			background-color: nth(g.$partsColor,1);
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: 0;
			z-index: -1;
		}

		@include g.tabletP{
			padding-block:60px 80px;
		}

		&__inner{

			@include g.tabletPmin{
				display: flex;
				flex-wrap: wrap;
				gap: 0 40px;
			}

			opacity: 0;
			transform: translate3d(0,$_fadeInBottomOff,0);

			&.aos-animate {
				animation: fadeInBottom $_fadeInBottomSp $_fadeInBottomEasing forwards 0.3s;
			}
		}


		&__side{
			@include g.tabletPmin{
				width: 22.1%;
			}

			@include g.tabletP{
				margin-bottom: 40px;
			}

		}

		&__category{
			margin-top: 61px;

			@include g.tabletP{
				margin-top: 40px;
			}
		}

		&__main{
			background-color: #fff;
			padding: 10px 50px 10px;
			border-radius: 5px;
			@include g.tabletPmin{
				flex:1;
			}

			@include g.tabletP{
				padding: 10px 20px 10px;
			}

			.eir_area_area_top_002 .s_eirList_item:first-child .s_eirItem {
				padding-top: 30px;
				@include g.max(800){
					padding-top: 20px;
				}
			}
		}


		&__btn{
			max-width: 592px;
			margin-right: auto;
			margin-left: auto;
			margin-top: 60px;
			@include g.tabletP{
				margin-top: 40px;
			}
		}

	}//.myBlockNews

	.myBlockService{
		padding-block:112px 118px;
		overflow: hidden;

		$_:'.myBlockService';

		@include g.tabletP{
			padding-block:80px ;

		}


		@keyframes serviceSliderFade {
			0% {
				transform: translate3d(100px,0,0);
				opacity: 0;
			}

			to {
				transform: translate3d(0,0,0);
				opacity: 1;
			}
		}

		&__inner {
			&.aos-animate{
				.myTitle01,
				#{$_}Control{
					animation: fadeInBottom $_fadeInBottomSp $_fadeInBottomEasing forwards 0.3s;
				}

				#{$_}__btn{
					animation: fadeInBottom $_fadeInBottomSp $_fadeInBottomEasing forwards 0.3s;
				}

				#{$_}Slider{
					animation: serviceSliderFade $_fadeInBottomSp $_fadeInBottomEasing forwards 0.6s;
				}
			}
		}


		.myTitle01,
		&Control,
		&__btn{
			opacity: 0;
			transform: translate3d(0,$_fadeInBottomOff,0);
		}

		&Slider{
			opacity: 0;
			transform: translate3d(100px,0,0);
		}


		&__header{
			position: relative;
			margin-bottom: 61px;

			@include g.tabletP{
				margin-bottom: 40px;
			}
		}

		&Control{
			display: flex;
			flex-wrap: wrap;
			position: absolute;
			bottom: 0;
			right: 0;

			$_:'.myBlockServiceControl';

			&__icon{
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				width: 30px;
				height: 30px;
				overflow: hidden;
				@include g.tabletP{
					width: 24px;
					height: 24px;
				}

				&::after{
					content:"";
					background-image: url(#{g.$cmnPath}ico_circle_arrow_color02.svg);
					background-size: 30px 30px;
					background-repeat: no-repeat;
					background-position: 0 0;
					position: absolute;
					top: 0;
					left: 0;
					width: 30px;
					height: 30px;
					@include g.tabletP{
						background-size: 24px 24px;
						width: 24px;
						height: 24px;
					}

				}

			}

			&__prev,
			&__next{
				width: 60px;
				height: 60px;
				border:solid 2px nth(g.$mainColor,1);
				border-radius: 50%;
				background-color: #fff;
				display: block;
				position: relative;

				@include g.tabletP{
					width: 45px;
					height: 45px;
				}

				&[disabled]{
					border-color:#BABABA;
					cursor: default;
					#{$_}__icon{
						&::after{
							filter: invert(77%) sepia(1%) saturate(0%) hue-rotate(4deg) brightness(96%) contrast(96%);
						}
					}
				}

				&.-little{
					display: none;
				}


				&:not([disabled]):not(.-little):hover{
					@include g.tabletPmin{

						#{$_}__icon{
							&::after{
								animation: iconMove map-get(g.$iconMove, 'sp') map-get(g.$iconMove, 'easing') map-get(g.$iconMove, 'delay') 1 map-get(g.$iconMove, 'end');
							}

						}

					}
				}



			}

			&__prev{
				transform: scale(-1, 1);
			}

			&__next{
				margin-left: 10px;

			}

		}

		&__btn{
			max-width: 592px;
			margin-right: auto;
			margin-left: auto;

			@include g.tabletP{
			}
		}

	}//.myBlockService

	.myBlockServiceSlider{
		overflow: hidden;
		margin-bottom: 60px;


		$_:'.myBlockServiceSlider';

		@include g.tabletP{
			margin-bottom: 40px;
		}

		&__wrap{
			@include g.tabletPmin{
				margin-left: calc(((100% - 1216px)/2) + 0px);

				@include g.max(1280){
					margin-left: #{g.$containerPadding}px;
				}
			}

		}

		&.-little{
			#{$_}__wrap{
				@include g.tabletPmin{
					margin-left: auto;
					margin-right: auto;
					padding-left:  #{g.$containerPadding}px;
					padding-right: #{g.$containerPadding}px;
					max-width: #{g.$containerPadding * 2 + g.$baseWidthWide + 32px};
					position: relative;
					left: -16px;

					@include g.max(1311){
						left: 0;
						margin-left: -32px;
					}
				}
			}
		}

		.slick-list{
			overflow: visible;
		}

		&__body{
			padding-bottom: 60px;
			position: relative;

			@include g.tabletP{
				padding-bottom: 40px;
			}
		}

		.slick-slide{

		}

		&__item{
			@include g.tabletPmin{
			}

			img{
				display:inline;
				@include g.tabletP{
					width: 100%;
				}
			}
		}

		&__link{
			display: block;
			@include g.tabletPmin{
				width: 592px;
				margin-right: 32px;

				&:hover{
					#{$_}__image{
						img{
							transform: scale(1.06) rotate(.001deg);
						}
					}

					#{$_}__title-icon{

						&.-arrow{
							&::after{
								animation: iconMove map-get(g.$iconMove, 'sp') map-get(g.$iconMove, 'easing') map-get(g.$iconMove, 'delay') 1 map-get(g.$iconMove, 'end');
							}
						}
						&.-blank{
							&::after{
								transform: scale(-1, -1);
							}
						}
					}
				}
			}

			@include g.tabletP{
				width: g.get_vw(246);
				margin-left: #{g.$containerPaddingSp}px;
			}
		}

		&.-little{
			#{$_}__link{
				@include g.tabletPmin{
					max-width: 100%;
					width: auto;
					margin-right: 0;
					margin-left: 32px;
				}
			}
		}

		&__image{
			border:solid 1px #ddd;
			border-radius: 5px;
			overflow: hidden;
			margin-bottom: 28px;

			@include g.tabletP{
				margin-bottom: 15px;
			}

			img{
				width: 100%;
				transition:transform 0.25s g.$easeInOut;

			}
		}

		&__title{
			margin-bottom: 15px;
			font-weight: 900;
			@include g.fz(24);
			line-height: 1.5;
			letter-spacing: 0.03em;
			@include g.tabletP{
				margin-bottom: 10px;
				@include g.fz(17);
			}

			&-icon{
				display: inline-block;
				position: relative;
				top: 0.15em;
				right: 0;
				width: 24px;
				height: 24px;
				background-color: nth(g.$mainColor, 1);
				border-radius: 50%;
				margin-left: 11px;
				overflow: hidden;
				@include g.tabletP {
					width: 18px;
					height: 18px;
					margin-left: 8px;
				}

				&::after{
					content:"";
					position: absolute;
					top: 0;
					left: 0;
					width: 24px;
					height: 24px;
					background-size: 24px 24px;
					background-repeat: no-repeat;
					background-position: 0 0;
					@include g.tabletP{
						width: 18px;
						height: 18px;
						background-size: 18px 18px;
					}
				}

				&.-arrow{
					&::after{
						background-image: url(#{g.$cmnPath}ico_circle_arrow.svg);
					}
				}

				&.-blank {
					&::after {
						background-image: url(#{g.$cmnPath}ico_circle_blank.svg);
						transition: transform map-get(g.$iconMove02, 'sp') map-get(g.$iconMove02, 'easing');
					}
				}
			}
		}

		&__txt{

		}






	}//.myBlockServiceSlider

	.myBlockServicePager{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		&.-little{
			@include g.tabletPmin{
				display: none;
			}
		}

		&-item{
			flex:1;
			height: 1px;
			background-color: #ccc;
			display: block;
			border:none;
			&[aria-expanded="true"]{
				background-color: nth(g.$mainColor,1);
			}
		}

	}//myBlockServicePager

	.myBlockRecruitMain{
		padding-block:120px;
		position: relative;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		overflow: hidden;

		$_:'.myBlockRecruitMain';

		@include g.tabletPmin{
			background-image: url(#{$_imgPath}bg_recruit.jpg);
			@include g.retina(){
				background-image: url(#{$_imgPath}bg_recruit@2x.jpg);
			}

		}

		@include g.tabletP{
			background-image: url(#{$_imgPath}bg_recruit_sp.jpg);
			padding-block:80px;
			@include g.retina(){
				background-image: url(#{$_imgPath}bg_recruit_sp@2x.jpg);
			}

		}

		@keyframes recruitBg {
			0% {
				left: 0;
			}

			to {
				left: 100%;
			}
		}

		&::before{
			content:"";
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: 100%;
			z-index: -1;
			background-color: #fff;
		}


		&.aos-animate{
			&::before{
				animation: recruitBg 0.4s g.$easeInCubic forwards 0s;
			}

		}

		&__inner {
			opacity: 0;
			transform: translate3d(0,$_fadeInBottomOff,0);
			&.aos-animate {
				animation: fadeInBottom $_fadeInBottomSp $_fadeInBottomEasing forwards 0.25s;
			}
		}

		.myTitle01{
			&__title{
				color:#fff;
			}
		}


		&__txt{
			margin-block:60px 59px;
			color:#fff;

			@include g.tabletP{
				margin-block: 40px;
			}
		}

		&__btn{
			max-width: 384px;
		}

	}//.myBlockRecruitMain

	.myBlockRecruitList{
		padding-block:79px;
		background-color: nth(g.$partsColor,1);

		$_:'.myBlockRecruitList';

		@include g.tabletP{
			padding-block:60px;
		}

		&__inner{
			@include g.sphonePmin{
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				gap: 0 32px;
			}

		}

		&__item{
			@include g.sphonePmin{
				width: calc((100% - 32px)/2);
				margin-top: 32px;
				&:nth-child(-n + 2){
					margin-top: 0;
				}
			}
			@include g.sphoneP{
				&:not(:last-child){
					margin-bottom: 20px;
				}
			}
		}

		&__link{
			background-color: #fff;
			border-radius: 5px;
			padding: 15px 107px 15px 15px;
			height: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
			gap:0 28px;
			position: relative;
			transition:background-color 0.15s g.$easeOut;

			@include g.tabletPmin{
				&:hover{
					background-color: nth(g.$mainColor,1);
					opacity: 1;
					color:#fff;

					#{$_}__icon{
						background-color: #fff;

						&.-arrow{
							&::after{
								background-image: url(#{g.$cmnPath}ico_circle_arrow_color02.svg);
								animation: iconMove map-get(g.$iconMove, 'sp') map-get(g.$iconMove, 'easing') map-get(g.$iconMove, 'delay') 1 map-get(g.$iconMove, 'end');
							}
						}

						&.-blank{
							&::after{
								background-image: url(#{g.$cmnPath}ico_circle_blank_color02.svg);
								transform: scale(-1, -1);
							}
						}
					}
				}
			}

			@include g.pc {
				padding: 15px;
			}

			@include g.tabletP{
				gap:0 20px;
			}

		}

		&__image{
			text-align: center;
			@include g.tabletPmin {
				flex-shrink: 0;
			}

			@include g.tabletL {
				width: 27.8%;
			}

			@include g.tabletP{
				width: math.percentage(math.div(120,305));
			}

			img{
				width: 100%;
			}

		}

		&__detail{
			flex:1;
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			@include g.tabletP{
			}

			&-inner{

			}

		}

		&__title{
			margin-bottom: 10px;
			font-weight: 900;
			@include g.fz(20);
			line-height: 1.5;
			letter-spacing: 0.03em;
			@include g.tabletP{
				margin-bottom: 5px;
				@include g.fz(16);
			}
		}

		&__txt{
			@include g.tabletP{
				@include g.fz(12);
			}

		}

		&__icon{
			display: block;
			width: 30px;
			height: 30px;
			background-color: nth(g.$mainColor, 1);
			border-radius: 50%;
			margin: auto;
			overflow: hidden;
			@include g.pcmin{
				position: absolute;
				top: 0;
				bottom: 0;
				right: 40px;
			}
			@include g.pc {
				display: inline-block;
				position: relative;
				top: 3px;
				width: 24px;
				height: 24px;
				margin-left: 12px;
			}
			@include g.tabletP {
				top: 1px;
				width: 16px;
				height: 16px;
				margin-left: 6px;
			}

			&::after{
				content:"";
				position: absolute;
				top: 0;
				left: 0;
				width: 30px;
				height: 30px;
				background-size: 30px 30px;
				background-repeat: no-repeat;
				background-position: 0 0;
				@include g.pc {
					width: 24px;
					height: 24px;
					background-size: 24px 24px;
				}
				@include g.tabletP{
					width: 16px;
					height: 16px;
					background-size: 16px 16px;
				}
			}

			&.-arrow{
				&::after{
					background-image: url(#{g.$cmnPath}ico_circle_arrow.svg);
				}
			}

			&.-blank{
				&::after{
					background-image: url(#{g.$cmnPath}ico_circle_blank.svg);
					transition: transform map-get(g.$iconMove02, 'sp') map-get(g.$iconMove02, 'easing');
				}
			}

		}

	}//.myBlockRecruitList
}
