@charset "utf-8";

/*
business
*/


@use "global" as g;
@use 'sass:math';

$_id:business;
$_imgPath:'../img/#{$_id}/';

body#page_#{$_id},body#page_en.-business.-demaecan {

	.myDemaecan{
		&__txt {
			margin: 40px 0;
			@include g.tabletP{
				margin: 30px 0;
				line-height: math.div(25,14);
			}
		}
		&__btn {
			margin: 60px auto;
			@include g.tabletP{
				margin: 40px auto;
			}
		}
	}//.myDemaecan

	.pb-0{
		padding-bottom: 0;
	}
	.myEgift{
		&__title01{
			font-size: 24px;
			font-weight: 900;
			margin-bottom: 20px;
			@include g.tabletL {
				font-size: 20px;
				margin-bottom: 15px;
			}
		}
		&__title02{
			font-size: 24px;
			font-weight: 900;
			margin-bottom: 40px;
			@include g.tabletL {
				font-size: 20px;
				margin-bottom: 10px;
			}
		}
		&__title03{
			font-size: 20px;
			font-weight: 900;
			margin-bottom: 20px;
			@include g.tabletL {
				font-size: 16px;
				margin-bottom: 0;
			}
		}
		&__giftType{
			border-top: 1px solid #ddd;
			border-bottom: 1px solid #ddd;
			padding: 40px 0;
			margin-bottom: 80px;
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			@include g.tabletL {
				margin-bottom: 60px;
				display: block;
			}
			&-item{
				width: calc((100% - 32px)/ 2);
				@include g.tabletL {
					width: 100%;
					max-width: 600px;
					margin: 0 auto 48px;
				}
			}
			&-item:last-child{
				@include g.tabletL {
					margin-bottom: 0;
				}
			}
			&-content{
				display: flex;
				flex-wrap: nowrap;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 20px;
				@include g.tabletL {
					flex-direction: column;
				}
			}
			&-figure{
				width: 178px;
				height: 178px;
				margin-right: 32px;
				@include g.tabletL {
					margin-right: 0;
					margin-bottom: 15px;
					order: 2;
				}
			}
			&-txt{
				flex: 1;
				@include g.tabletL {
					display: contents;
					h3 {
						order: 1;
					}
					p{
						order: 3;
					}
				}
			}
			& .modBtn01{
				width: 280px;
				height: 64px;
				line-height: 64px;
				padding: 0;
				@include g.tabletL {
					margin: auto;
				}
				&__icon{
					&.-blank {
						right: 24px;
					}
				}
			}
		}
		&__giftDetail{
			margin-bottom: 80px;
			@include g.tabletL {
				margin-bottom: 60px;
			}
			&-content{
				display: flex;
				flex-wrap: nowrap;
				justify-content: space-between;
				margin-bottom: 80px;
				@include g.tabletL {
					display: block;
					margin-bottom: 60px;
				}
				&:last-of-type{
					margin-bottom: 40px;
					@include g.tabletL {
						margin-bottom: 20px;
					}
				}
			}
			&-item{
				width: calc((100% - 96px)/ 3);
				@include g.tabletL {
					width: 100%;
					max-width: 600px;
					margin: 0 auto 10px;
				}
			}
			&-itemStepMain{
				@include g.tabletL {
					width: 100%;
					max-width: 400px;
					margin: 0 auto;
				}
			}
			&-itemStepArrow{
				width: 14px;
				height: 34px;
				margin-top: 175px;
				background-color: #bdbdbd;
				clip-path: polygon(0 0, 100% 50%, 0 100%);
				@include g.tabletL {
					width: 34px;
					height: 14px;
					margin: 20px auto;
					clip-path: polygon(0 0, 100% 0, 50% 100%);
				}
			}
			&-figure01{
				width: 178px;
				margin: 0 auto 15px;
			}
			&-figure02{
				max-width: 384px;
				margin: 0 auto 15px;
				position: relative;
			}
			&-stepTxt{
				display: flex;
				& .icon{
					display: block;
					width: 56px;
					height: 56px;
					margin-right: 16px;
				}
			}
			&-textSmall{
				font-size: 14px;
				color: #616161;
				& br{
					display: none;
				}
				@include g.tabletL {
					max-width: 400px;
					margin: 0 auto;
					text-align: center;
					& br{
						display: block;
					}
				}
			}
		}
	}//.myEgift
	.myQA{
		padding: 120px 20px;
		background-color: #f5f5f5;
		@include g.tabletL {
			padding-top: 80px;
			padding-bottom: 80px;
		}
		&__title{
			font-weight: 900;
			font-size: 32px;
			text-align: center;
			margin-bottom: 56px;
			@include g.tabletL {
				font-size: 24px;
				margin-bottom: 24px;
			}
			& span{
				display: block;
				font-size: 16px;
				font-weight: 700;
				color: nth(g.$mainColor, 1);
				@include g.tabletL {
					font-size: 14px;
				}
			}
		}
		&__box{
			background-color: #fff;
			max-width: 1216px;
			margin: auto;
			padding-top: clamp(8px, 2.5vw, 36px);
			padding-right: clamp(12px, 3.3333333333vw, 48px);
			padding-bottom: clamp(20px, 2.0138888889vw, 29px);
			padding-left: clamp(12px, 3.3333333333vw, 48px);
			border-radius: min(0.5555555556vw, 8px);
		}
		&__list{
			&-item{
				border-bottom: 1px solid #e0e0e0;
				cursor: pointer;
				&:last-child{
					border: none;
				}
				& dt{
					position: relative;
					display: flex;
					align-items: center;
					padding: clamp(29px, 2.7083333333vw, 38px) clamp(40px, 3.3333333333vw, 48px);
					font-weight: 700;
					&::before{
						position: absolute;
						top: clamp(29px, 2.7083333333vw, 39px);
						left: 7px;
						content: "Q";
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: min(5.3333333333vw, 20px);
						line-height: 1.3;
						color: #DA3734;
					}
					&::after{
						position: absolute;
						top: clamp(37px, 3.75vw, 54px);
						right: 12px;
						content: "";
						display: block;
						width: 20px;
						height: 20px;
						background-image: url(data:image/svg+xml;base64,IDxzdmcgd2lkdGg9IjIxIiBoZWlnaHQ9IjEyIiB2aWV3Qm94PSIwIDAgMjEgMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+IDxwYXRoIGQ9Ik0xOS41MTY4IDAuODE2NjAyQzE4Ljg2NjggMC4xNjY2MDIgMTcuODE2OCAwLjE2NjYwMiAxNy4xNjY4IDAuODE2NjAyTDEwLjAxNjggNy45NjY2TDIuODY2OCAwLjgxNjYwMkMyLjIxNjggMC4xNjY2MDIgMS4xNjY4IDAuMTY2NjAyIDAuNTE2Nzk3IDAuODE2NjAyQy0wLjEzMzIwMyAxLjQ2NjYgLTAuMTMzMjAzIDIuNTE2NiAwLjUxNjc5NyAzLjE2NjZMOC44NTAxMyAxMS40OTk5QzkuMTgzNDYgMTEuODMzMyA5LjYwMDEzIDExLjk4MzMgMTAuMDMzNSAxMS45ODMzQzEwLjQ2NjggMTEuOTgzMyAxMC44ODM1IDExLjgxNjYgMTEuMjE2OCAxMS40OTk5TDE5LjU1MDEgMy4xNjY2QzIwLjIwMDEgMi41MTY2IDIwLjIwMDEgMS40NjY2IDE5LjU1MDEgMC44MTY2MDJIMTkuNTE2OFoiIGZpbGw9IiM5RTlFOUUiLz4gPC9zdmc+);
						background-repeat: no-repeat;
						background-size: contain;
						background-position: center;
						transform: translateY(-50%);
						transition: all 0.3s;
						@include g.tabletL {
							width: 12px;
							height: 12px;
						}
					}
				}
				& dd{
					height: 0;
					overflow: hidden;
					position: relative;
					top: -12px;
					padding: 0 clamp(40px, 3.3333333333vw, 48px);
					font-size: 14px;
					opacity: 0;
					transition: all 0.4s;
					&::before{
						position: absolute;
						top: 0;
						left: 10px;
						content: "A";
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: min(4.8vw, 18px);
						line-height: 1.5;
					}
					& a{
						text-decoration: underline;
					}
				}
			}
			&-item.is-open{
				& dt::after{
					transform: translateY(-50%) rotate(-180deg);
				}
				& dd{
					height: auto;
					padding-bottom: clamp(13px, 1.3194444444vw, 19px);
					opacity: 1;
				}
			}
		}

	}//.myQA
}
