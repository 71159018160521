@charset "utf-8";

/*
#overview
wysiwyg
*/

@use "global" as g;
@use "sass:math";

/*
#styleguide
modWysiwyg

```
<div class="modWysiwyg">
	<p>本文テキストが入ります。この文章はダミーテキストです。本文テキストが入ります。この文章はダミーテキストです。<span class="modTxtBold">本文テキスト（太字）が入ります。この文章はダミーテキストです。本文テキスト（太字）が入ります。この文章はダミーテキストです。本文テキスト（太字）が入ります。この文章はダミーテキストです。</span>本文テキストが入ります。この文章はダミーテキストです。<a href="#">リンクテキストが入ります。この文章はダミーテキストです。</a>本文テキストが入ります。この文章はダミーテキストです。</p>
	<ul class="modWysiwyg__list01">
		<li class="modWysiwyg__list01-item">
			<span class="modWysiwyg__list01-head">※</span>
			注釈リストが入ります。この文章はダミーテキストです。
		</li>
		<li class="modWysiwyg__list01-item">
			<span class="modWysiwyg__list01-head">※</span>
			注釈リストが入ります。この文章はダミーテキストです。注釈リストが入ります。この文章はダミーテキストです。注釈リストが入ります。この文章はダミーテキストです。
		</li>
		<li class="modWysiwyg__list01-item">
			<span class="modWysiwyg__list01-head">※</span>
			注釈リストが入ります。この文章はダミーテキストです。注釈リストが入ります。この文章はダミーテキストです。注釈リストが入ります。この文章はダミーテキストです。
		</li>
	</ul>
	<ul class="modWysiwyg__list02">
		<li class="modWysiwyg__list02-item">箇条書きリストが入ります。この文章はダミーテキストです。</li>
		<li class="modWysiwyg__list02-item">箇条書きリストが入ります。この文章はダミーテキストです。箇条書きリストが入ります。この文章はダミーテキストです。箇条書きリストが入ります。この文章はダミーテキストです。</li>
		<li class="modWysiwyg__list02-item">箇条書きリストが入ります。この文章はダミーテキストです。箇条書きリストが入ります。この文章はダミーテキストです。箇条書きリストが入ります。この文章はダミーテキストです。</li>
	</ul>
	<ol class="modWysiwyg__list02">
		<li class="modWysiwyg__list02-item">番号付きリストが入ります。この文章はダミーテキストです。</li>
		<li class="modWysiwyg__list02-item">番号付きリストが入ります。この文章はダミーテキストです。番号付きリストが入ります。この文章はダミーテキストです。番号付きリストが入ります。この文章はダミーテキストです。</li>
		<li class="modWysiwyg__list02-item">番号付きリストが入ります。この文章はダミーテキストです。番号付きリストが入ります。この文章はダミーテキストです。番号付きリストが入ります。この文章はダミーテキストです。</li>
	</ol>
	<ul class="modWysiwyg__listLink">
		<li class="modTxtLink modWysiwyg__listLink-item"><a href="#" class="modTxtLink__txt">リンクリストが入ります。この文章はダミーテキストです。</a></li>
		<li class="modTxtLink modWysiwyg__listLink-item"><a href="#" class="modTxtLink__txt">リンクリストが入ります。この文章はダミーテキストです。リンクリストが入ります。この文章はダミーテキストです。リンクリストが入ります。この文章はダミーテキストです。</a></li>
		<li class="modTxtLink modWysiwyg__listLink-item"><a href="#" class="modTxtLink__txt">リンクリストが入ります。リンクリストが入ります。この文章はダミーテキストです。リンクリストが入ります。この文章はダミーテキストです。リンクリストが入ります。この文章はダミーテキストです。この文章はダミーテキストです。</a></li>
	</ul>
	<h3>見出し大が入ります。（H3相当サイズ）</h3>
	<p>本文テキストが入ります。この文章はダミーテキストです。本文テキストが入ります。この文章はダミーテキストです。本文テキストが入ります。この文章はダミーテキストです。本文テキストが入ります。この文章はダミーテキストです。本文テキストが入ります。この文章はダミーテキストです。本文テキストが入ります。この文章はダミーテキストです。本文テキストが入ります。この文章はダミーテキストです。</p>
	<h4>見出し中が入ります。（H4相当サイズ）</h4>
	<p>本文テキストが入ります。この文章はダミーテキストです。本文テキストが入ります。この文章はダミーテキストです。本文テキストが入ります。この文章はダミーテキストです。本文テキストが入ります。この文章はダミーテキストです。本文テキストが入ります。この文章はダミーテキストです。本文テキストが入ります。この文章はダミーテキストです。本文テキストが入ります。この文章はダミーテキストです。</p>
	<h5>見出し小が入ります。（H5相当サイズ）</h5>
	<p>本文テキストが入ります。この文章はダミーテキストです。本文テキストが入ります。この文章はダミーテキストです。本文テキストが入ります。この文章はダミーテキストです。本文テキストが入ります。この文章はダミーテキストです。本文テキストが入ります。この文章はダミーテキストです。本文テキストが入ります。この文章はダミーテキストです。本文テキストが入ります。この文章はダミーテキストです。</p>
	<figure class="modWysiwyg__image">
		<img src="/assets/img/pr/_dummy/img01.jpg" alt="">
		<figcaption class="modWysiwyg__image-caption">画像下にキャプションを入れることも可能です。この文章はダミーテキストです。</figcaption>
	</figure>
	<div class="modWysiwyg__box -gallery">
		<figure class="modWysiwyg__box-image"><img src="/assets/img/pr/_dummy/img01.jpg" alt=""></figure>
		<figure class="modWysiwyg__box-image"><img src="/assets/img/pr/_dummy/img01.jpg" alt=""></figure>
	</div>
	<div class="modWysiwyg__box">
		<figure class="modWysiwyg__box-image"><img src="/assets/img/pr/_dummy/img01.jpg" alt=""></figure>
		<div class="modWysiwyg__box-item">
			<p>本文テキストが入ります。この文章はダミーテキストです。本文テキストが入ります。この文章はダミーテキストです。本文テキストが入ります。この文章はダミーテキストです。本文テキストが入ります。この文章はダミーテキストです。</p>
		</div>
	</div>
	<div class="modWysiwyg__box -type02">
		<figure class="modWysiwyg__box-image -alignR"><img src="/assets/img/pr/_dummy/img01.jpg" alt=""></figure>
		<div class="modWysiwyg__box-item">
			<p>本文テキストが入ります。この文章はダミーテキストです。本文テキストが入ります。この文章はダミーテキストです。本文テキストが入ります。この文章はダミーテキストです。本文テキストが入ります。この文章はダミーテキストです。</p>
		</div>
	</div>
</div>

```
*/

.modWysiwyg {
	$_: &;
	p {
		&:not(:last-child) {
			margin-bottom: 20px;
			@include g.tabletP {
				margin-bottom: 15px;
			}
		}
		a {
			color: nth(g.$mainColor, 1);
			word-break: break-all;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
		sup, sub {
			@include g.tabletPmin {
				display: inline-block;
				@include g.fz(10);
				text-orientation: 0 0;
				transform: scale(.9);
			}
			@include g.tabletP {
				font-size: inherit;
				vertical-align: inherit;
			}
		}
	}
	&__list01 {
		$_2: &;
		display: table;
		width: 100%;
		&:not(:last-child) {
			margin-bottom: 40px;
			@include g.tabletP {
				margin-bottom: 30px;
			}
		}
		&-item {
			display: table-row;
			color: #616161;
			@include g.fz(14);
			@include g.tabletP {
				@include g.fz(12);
			}
			&:not(:first-child) {
				& > * {
					border-top: 5px solid transparent;
				}
			}
		}
		&-head {
			display: table-cell;
			white-space: nowrap;
			width: 0;
			padding-right: 5px;
		}
	}//__list01

	&__list02 {
		$_2: &;
		display: table;
		width: 100%;
		&:not(:last-child) {
			margin-bottom: 40px;
			@include g.tabletP {
				margin-bottom: 30px;
			}
		}
		&:not(:first-child) {
			margin-top: 40px;
			@include g.tabletP {
				margin-top: 30px;
			}
		}
		&-item {
			display: table-row;
			&::before {
				content: "\30FB";
				display: table-cell;
				white-space: nowrap;
				width: 0;
				padding-right: 5px;
			}
			&:not(:first-child) {
				&::before {
					border-top: 5px solid transparent;
				}
			}
		}
		@at-root ol#{&} {
			#{$_2}-item {
				counter-increment: list02;
				&::before {
					content: counter(list02) ".";
				}
			}
		}
	}//__list02

	&__listLink {
		$_2: &;
		&:not(:last-child) {
			margin-bottom: 40px;
			@include g.tabletP {
				margin-bottom: 30px;
			}
		}
		&:not(:first-child) {
			margin-top: 40px;
			@include g.tabletP {
				margin-top: 30px;
			}
		}
		&-item {
			&:not(:last-child) {
				margin-bottom: 5px;
			}
		}
	}//__listLink

	h3 {
		position: relative;
		@include g.fz(28);
		font-weight: 900;
		line-height: 1.5;
		letter-spacing: 0.03em;
		padding-top: 40px;
		margin-bottom: 20px;
		@include g.tabletP{
			@include g.fz(21);
			padding-top: 33px;
			margin-bottom: 15px;
		}

		&::before{
			content:"";
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 40px;
			height: 3px;
			border-radius: 10px;
			background-color: nth(g.$mainColor,1);
		}
		&:not(:first-child) {
			margin-top: 120px;
			@include g.tabletP {
				margin-top: 80px;
			}
		}
	}//h3

	h4 {
		@include g.fz(24);
		font-weight: 900;
		line-height: 1.5;
		letter-spacing: 0.03em;
		margin-bottom: 20px;
		@include g.tabletP {
			@include g.fz(17.5);
			margin-bottom: 15px;
		}
		&:not(:first-child) {
			margin-top: 60px;
			@include g.tabletP {
				margin-top: 40px;
			}
		}
	}//h4

	h5 {
		@include g.fz(20);
		font-weight: 900;
		letter-spacing: 0.03em;
		line-height: 1.5;
		margin-bottom: 20px;
		@include g.tabletP {
			@include g.fz(15.8);
			margin-bottom: 15px;
		}
		&:not(:first-child) {
			margin-top: 40px;
			@include g.tabletP {
				margin-top: 30px;
			}
		}
	}//h5

	&__image {
		text-align: center;
		&:not(:first-child) {
			margin-top: 60px;
			@include g.tabletP {
				margin-top: 40px;
			}
		}
		&:not(:last-child) {
			margin-bottom: 60px;
			@include g.tabletP {
				margin-bottom: 40px;
			}
		}
		img {
			width: 100%;
		}
		&-caption {
			@include g.fz(14);
			text-align: left;
			margin-top: 10px;
			@include g.tabletP {
				@include g.fz(12);
			}
		}
	}//__image

	&__box {
		$_2: &;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: 32px 32px;
		@include g.tabletP {
			gap: 15px 19px;
		}
		&:not(:first-child) {
			margin-top: 60px;
			@include g.tabletP {
				margin-top: 40px;
			}
		}
		&:not(:last-child) {
			margin-bottom: 60px;
			@include g.tabletP {
				margin-bottom: 40px;
			}
		}
		&-image,
		&-item {
			@include g.tabletPmin {
				width: calc(50% - 16px);
			}
			#{$_2}.-gallery & {
				@include g.tabletP {
					width: calc(50% - 9.5px);
				}
			}
		}
		&.-type02 {
			@include g.tabletPmin {
				flex-direction: row-reverse;
			}
		}
		&-image {
			img {
				width: 100%;
			}
		}
	}//__imagebox01
}//modTxtBold
