@charset "utf-8";
/*
#overview
modList
*/

@use "global" as g;

/*
#styleguide
modListNormal

```
<ul class="modListNormal">
    <li>リスト</li>
    <li>リスト</li>
</ul>
<ol class="modListNormal">
    <li>リスト</li>
    <li>リスト</li>
</ol>
```
*/


ul.modListNormal {
	margin-left: 2em;
	li{
		&:before {
			content: "\0030fb";
			display: inline-block;
			margin-left:-2em;
			text-align:center;
			width:2em;
		}
	}
}

ol.modListNormal {
	margin-left: 1.05em;
	li{
		list-style:decimal;
		&:not(:last-child){
			margin-bottom: 5px;
		}
	}
}

/*
#styleguide
modListAlpha

```
<ul class="modListAlpha">
    <li>リスト</li>
    <li>リスト</li>
</ul>
```
*/
.modListAlpha{
    padding-left: 1.5em;

	li{
	    list-style: lower-alpha;
	}
}//.modListAlpha

/*
#styleguide
modListIndent

```
<ul class="modListIndent01">
    <li>※テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</li>
    <li>※リスト</li>
</ul>
<ul class="modListIndent02">
    <li>１.　テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</li>
    <li>２.　リスト</li>
</ul>
```
*/
.modListIndent01 {
	li{
		margin-left: 1em;
		text-indent: -1em;
	}
}

.modListIndent02 {
	li{
		margin-left: 2.5em;
		text-indent: -2.5em;
	}
}


/*
#styleguide
modListIndex01

```

<div class="modListIndex01">
<ul class="modListIndex01__list -column02">
	<li class="modListIndex01__list-item">
		<a href="/company/message.html" class="modListIndex01__link">
			<figure class="modListIndex01__link-inner">
				<div class="modListIndex01__link-image">
					<picture>
					<source srcset="/assets/img/company/index/img_nav01_sp.jpg 1x,/assets/img/company/index/img_nav01_sp@2x.jpg 2x" media="(max-width: 600px)">
					<img src="/assets/img/company/index/img_nav01.jpg" srcset="/assets/img/company/index/img_nav01@2x.jpg 2x" alt="">
					</picture>
				</div>
				<figcaption class="modListIndex01__link-body">
					<span class="modListIndex01__link-sub">Top message</span>
					<span class="modListIndex01__link-title">トップメッセージ<span class="modListIndex01__link-icon -arrowR"></span></span>
				</figcaption>
			</figure>
		</a>
	</li>

	<li class="modListIndex01__list-item">
		<a href="/company/vision.html" class="modListIndex01__link">
			<figure class="modListIndex01__link-inner">
				<div class="modListIndex01__link-image">
					<picture>
					<source srcset="/assets/img/company/index/img_nav02_sp.jpg 1x,/assets/img/company/index/img_nav02_sp@2x.jpg 2x" media="(max-width: 600px)">
					<img src="/assets/img/company/index/img_nav02.jpg" srcset="/assets/img/company/index/img_nav02@2x.jpg 2x" alt="">
					</picture>
				</div>
				<figcaption class="modListIndex01__link-body">
					<span class="modListIndex01__link-sub">Mission Vision Value</span>
					<span class="modListIndex01__link-title">ミッション・ビジョン・バリュー<span class="modListIndex01__link-icon -blank"></span></span>
				</figcaption>
			</figure>
		</a>
	</li>
	<li class="modListIndex01__list-item">
		<a href="/company/vision.html" class="modListIndex01__link">
			<figure class="modListIndex01__link-inner">
				<div class="modListIndex01__link-image">
					<picture>
					<source srcset="/assets/img/company/index/img_nav07_sp.jpg 1x,/assets/img/company/index/img_nav07_sp@2x.jpg 2x" media="(max-width: 600px)">
					<img src="/assets/img/company/index/img_nav07.jpg" srcset="/assets/img/company/index/img_nav07@2x.jpg 2x" alt="">
					</picture>
				</div>
				<figcaption class="modListIndex01__link-body">
					<span class="modListIndex01__link-sub">Action guidelines</span>
					<span class="modListIndex01__link-title">行動指針<span class="modListIndex01__link-icon -blank"></span></span>
				</figcaption>
			</figure>
		</a>
	</li>
</ul>

<ul class="modListIndex01__list -column04">
	<li class="modListIndex01__list-item">
		<a href="/company/profile.html" class="modListIndex01__link -small">
			<figure class="modListIndex01__link-inner">
				<div class="modListIndex01__link-image">
					<picture>
					<source srcset="/assets/img/company/index/img_nav03_sp.jpg 1x,/assets/img/company/index/img_nav03_sp@2x.jpg 2x" media="(max-width: 600px)">
					<img src="/assets/img/company/index/img_nav03.jpg" srcset="/assets/img/company/index/img_nav03@2x.jpg 2x" alt="">
					</picture>
				</div>
				<figcaption class="modListIndex01__link-body">
					<span class="modListIndex01__link-sub">Company profile</span>
					<span class="modListIndex01__link-title">会社概要<span class="modListIndex01__link-icon -arrowR"></span></span>
				</figcaption>
			</figure>
		</a>
	</li>

	<li class="modListIndex01__list-item">
		<a href="/company/cg.html" class="modListIndex01__link -small">
			<figure class="modListIndex01__link-inner">
				<div class="modListIndex01__link-image">
					<picture>
					<source srcset="/assets/img/company/index/img_nav04_sp.jpg 1x,/assets/img/company/index/img_nav04_sp@2x.jpg 2x" media="(max-width: 600px)">
					<img src="/assets/img/company/index/img_nav04.jpg" srcset="/assets/img/company/index/img_nav04@2x.jpg 2x" alt="">
					</picture>
				</div>
				<figcaption class="modListIndex01__link-body">
					<span class="modListIndex01__link-sub">Corporate governance</span>
					<span class="modListIndex01__link-title">コーポレートガバナンス<span class="modListIndex01__link-icon -arrowR"></span></span>
				</figcaption>
			</figure>
		</a>
	</li>

	<li class="modListIndex01__list-item">
		<a href="/company/history.html" class="modListIndex01__link -small">
			<figure class="modListIndex01__link-inner">
				<div class="modListIndex01__link-image">
					<picture>
					<source srcset="/assets/img/company/index/img_nav05_sp.jpg 1x,/assets/img/company/index/img_nav05_sp@2x.jpg 2x" media="(max-width: 600px)">
					<img src="/assets/img/company/index/img_nav05.jpg" srcset="/assets/img/company/index/img_nav05@2x.jpg 2x" alt="">
					</picture>
				</div>
				<figcaption class="modListIndex01__link-body">
					<span class="modListIndex01__link-sub">History</span>
					<span class="modListIndex01__link-title">沿革<span class="modListIndex01__link-icon -arrowR"></span></span>
				</figcaption>
			</figure>
		</a>
	</li>

	<li class="modListIndex01__list-item">
		<a href="/company/executive.html" class="modListIndex01__link -small">
			<figure class="modListIndex01__link-inner">
				<div class="modListIndex01__link-image">
					<picture>
					<source srcset="/assets/img/company/index/img_nav06_sp.jpg 1x,/assets/img/company/index/img_nav06_sp@2x.jpg 2x" media="(max-width: 600px)">
					<img src="/assets/img/company/index/img_nav06.jpg" srcset="/assets/img/company/index/img_nav06@2x.jpg 2x" alt="">
					</picture>
				</div>
				<figcaption class="modListIndex01__link-body">
					<span class="modListIndex01__link-sub">Management</span>
					<span class="modListIndex01__link-title">役員紹介<span class="modListIndex01__link-icon -arrowR"></span></span>
				</figcaption>
			</figure>
		</a>
	</li>
</ul>
</div>

```
*/
.modListIndex01{

	&__list{
		$_:&;

		& + #{$_}{
			margin-top: 81px;
			@include g.tabletP{
				margin-top: 50px;
			}
		}

		&.-column02{
			@include g.sphonePmin{
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
			}

			#{$_}-item{
				@include g.sphonePmin{
					width: calc((100% - 32px*1)/2);
					margin-top: 79px;
					&:nth-child(-n + 2){
						margin-top: 0;
					}
				}

				@include g.sphoneP{
					&:not(:last-child){
						margin-bottom: 50px;
					}
				}
			}

		}

		&.-column03{
			@include g.sphonePmin{
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
			}

			#{$_}-item{
				@include g.sphonePmin{
					width: calc((100% - 32px*2)/3);
					margin-top: 79px;
					&:nth-child(-n + 3){
						margin-top: 0;
					}
				}

				@include g.sphoneP{
					&:not(:last-child){
						margin-bottom: 50px;
					}
				}

				.modListIndex01__link-title {
					@include g.fz(20);
					@include g.tabletP{
						@include g.fz(17.5);
					}
				}
			}

		}

		&.-column04{
			display: flex;
			flex-wrap: wrap;
			@include g.tabletP{
				justify-content: space-between;
			}

			#{$_}-item{
				@include g.tabletPmin{
					width: calc((100% - 32px*3)/4);
					margin-right: 32px;
					margin-top: 32px;
					&:nth-child(-n + 4){
						margin-top: 0;
					}
					&:nth-child(4n){
						margin-right: 0;
					}
				}
				@include g.tabletP{
					width: calc((100% - 19px*1)/2);
					margin-top: 50px;
					&:nth-child(-n + 2){
						margin-top: 0;
					}

				}
			}

		}

		&-item{

		}


	}

	&__link{
		display: block;

		$_:&;

		@include g.tabletPmin{
			&:hover{
				opacity: 1;

				#{$_}-image{
					img{
						transform: scale(1.06) rotate(.001deg);
					}
				}

				#{$_}-title{
					color:nth(g.$mainColor,1);
				}

				#{$_}-icon{

					&.-arrowR{
						&::after{
							animation: iconMove map-get(g.$iconMove, 'sp') map-get(g.$iconMove, 'easing') map-get(g.$iconMove, 'delay') 1 map-get(g.$iconMove, 'end');
						}
					}
					&.-blank{
						&::after{
							transform: scale(-1, -1);
						}
					}
				}

			}
		}

		&-image{
			margin-bottom: 24px;
			border-radius: 5px;
			overflow: hidden;
			text-align: center;

			@include g.tabletP{
				margin-bottom: 15px;
			}

			img{
				width: 100%;
				transition:transform 0.25s g.$easeInOut;
			}

			&.-border {
				border: 1px solid #DDD;
				border-radius: 5px;
			}

		}

		&.-small{
			#{$_}-image{
				@include g.tabletP{
					margin-bottom: 10px;
				}
			}
		}

		&-sub{
			display: block;
			color:nth(g.$mainColor,1);
			font-family: g.$fontAlpha;
			font-weight: 700;
			@include g.fz(14);
			letter-spacing: 0.03em;
			@include g.tabletP{
				@include g.fz(12);
			}
		}

		&.-small{
			#{$_}-sub{
				margin-bottom: 3px;
			}
		}

		&-title{
			display: inline-block;
			transition:color 0.25s g.$easeInOut;
			position: relative;
			font-weight: 900;
			@include g.fz(24);
			line-height: 1.5;
			letter-spacing: 0.03em;
			@include g.tabletP{
				@include g.fz(17);
			}

		}

		&.-small{
			#{$_}-title{
				@include g.fz(20);
				@include g.tabletP{
					@include g.fz(16);
				}
			}

			#{$_}-icon{
				width: 20px;
				height: 20px;
				margin-left: 10px;
				@include g.tabletP {
					width: 16px;
					height: 16px;
					margin-left: 6px;
				}

				&::after{
					width: 20px;
					height: 20px;
					background-size: 20px 20px;
					@include g.tabletP{
						width: 16px;
						height: 16px;
						background-size: 16px 16px;
					}

				}

			}
		}//title

		&-icon{
			display: inline-block;
			position: relative;
			top: 0.15em;
			right: 0;
			width: 24px;
			height: 24px;
			background-color: nth(g.$mainColor, 1);
			border-radius: 50%;
			margin-left: 11px;
			overflow: hidden;
			@include g.tabletP {
				width: 18px;
				height: 18px;
				margin-left: 8px;
			}

			&::after{
				content:"";
				position: absolute;
				top: 0;
				left: 0;
				width: 24px;
				height: 24px;
				background-size: 24px 24px;
				background-repeat: no-repeat;
				background-position: 0 0;
				@include g.tabletP{
					width: 18px;
					height: 18px;
					background-size: 18px 18px;
				}
			}

			&.-arrowR{
				&::after{
					background-image: url(#{g.$cmnPath}ico_circle_arrow.svg);
				}
			}

			&.-blank {
				&::after {
					background-image: url(#{g.$cmnPath}ico_circle_blank.svg);
					transition: transform map-get(g.$iconMove02, 'sp') map-get(g.$iconMove02, 'easing');
				}
			}

		}//icon

	}//link

}//.modListIndex01


/*
#styleguide
modListIndex02

```
<div class="modListIndex02">
	<div class="container">
		<ul class="modListIndex02__list">
			<li class="modListIndex02__item">
				<a href="/company/message.html" class="modListIndex02__item-link">
					<figure class="modListIndex02__item-inner">
						<div class="modListIndex02__item-image">
							<picture>
								<source srcset="/assets/img/ir_information/index/img_nav01_sp.jpg 1x,/assets/img/ir_information/index/img_nav01_sp@2x.jpg 2x" media="(max-width: 768px)">
								<img src="/assets/img/ir_information/index/img_nav01.jpg" srcset="/assets/img/ir_information/index/img_nav01@2x.jpg 2x" alt="">
							</picture>
						</div>
						<figcaption class="modListIndex02__item-body">
							<span class="modListIndex02__item-sub">Top message</span>
							<span class="modListIndex02__item-title">トップメッセージ</span>
						</figcaption>
						<span class="modListIndex02__item-icon -arrowR"></span>
					</figure>
				</a>
			</li>
		</ul>
	</div>
</div><!-- /.modListIndex02 -->
```
*/
.modListIndex02 {
	$_: &;
	&:not(:first-child) {
		margin-top: 120px;
		@include g.tabletP {
			margin-top: 80px;
		}
	}
	&__item {
		$_2: &;
		&-link {
			@include g.tabletPmin{
				&:hover{
					opacity: 1;

					#{$_2}-image{
						img{
							transform: scale(1.06) rotate(.001deg);
						}
					}

					#{$_2}-title{
						color:nth(g.$mainColor,1);
					}

					#{$_2}-icon{

						&.-arrowR{
							&::after{
								animation: iconMove map-get(g.$iconMove, 'sp') map-get(g.$iconMove, 'easing') map-get(g.$iconMove, 'delay') 1 map-get(g.$iconMove, 'end');
							}
						}
						&.-blank{
							&::after{
								transform: scale(-1, -1);
							}
						}
					}

				}
			}
		}
		&-inner {
			border-radius: 5px;
			display: block;
			position: relative;
			overflow: hidden;
		}
		&-image {
			img {
				width: 100%;
				transition:transform 0.25s g.$easeInOut;
			}
		}
		&-body {
			display: flex;
			flex-direction: column;
			justify-content: center;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			padding: 0 104px 20px;
			@include g.tabletP {
				padding: 0 30px 20px;
			}

			body#page_en &{
				padding-bottom: 0;
			}
		}
		&-sub {
			color: nth(g.$mainColor, 1);
			font-family: g.$fontAlpha;
			font-weight: bold;
			letter-spacing: 0.03em;
			margin-bottom: 6px;
		}
		&-title {
			color: #fff;
			@include g.fz(32);
			font-weight: 900;
			letter-spacing: 0.03em;
			line-height: 1.5;
			transition:color 0.25s g.$easeInOut;
			@include g.tabletPmin {
				#{$_2}:hover & {
					color: #fff;
				}
			}
			@include g.tabletP {
				@include g.fz(24.5);
			}

			body#page_en &{
				font-family: g.$fontAlpha;
			}
		}
		&-icon{
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 40px;
			width: 30px;
			height: 30px;
			background-color: nth(g.$mainColor, 1);
			border-radius: 50%;
			margin: auto;
			overflow: hidden;
			@include g.tabletP {
				right: 20px;
				width: 20px;
				height: 20px;
			}

			&::after{
				content:"";
				position: absolute;
				top: 0;
				left: 0;
				width: 30px;
				height: 30px;
				background-size: 30px 30px;
				background-repeat: no-repeat;
				background-position: 0 0;
				@include g.tabletP{
					width: 20px;
					height: 20px;
					background-size: 20px 20px;
				}
			}

			&.-arrowR{
				&::after{
					background-image: url(#{g.$cmnPath}ico_circle_arrow.svg);
				}
			}

			&.-blank {
				&::after {
					background-image: url(#{g.$cmnPath}ico_circle_blank.svg);
					transition: transform map-get(g.$iconMove02, 'sp') map-get(g.$iconMove02, 'easing');
				}
			}

		}//-icon
	}//__item
}//.modListIndex02

/*
#styleguide
modListNews

```
<ul class="modListNews">
    <li class="modListNews__item">
        <a href="#" class="modListNews__item-inner">
            <span class="modTxtDate modListNews__item-date">2022.05.10</span>
            <span class="modTxtCategory modListNews__item-category">出前館</span>
            <span class="modListNews__item-txt">『出前館』、加盟店を表彰する「Demaecan Award 2021」を初開催！</span>
            <span class="modListNews__item-icon"></span>
        </a>
    </li>
    <li class="modListNews__item">
        <a href="#" target="_blank" class="modListNews__item-inner -blank">
            <span class="modTxtDate modListNews__item-date">2022.03.31</span>
            <span class="modTxtCategory modListNews__item-category">Web</span>
            <span class="modListNews__item-txt"><span class="modListNews__item-title">タウンニュース</span><br>クイックコマース拡大　料理宅配各社　日用品もお届け　安定収入、人材確保狙う</span>
            <span class="modListNews__item-icon"></span>
        </a>
    </li>
    <li class="modListNews__item">
        <a href="#" target="_blank" class="modListNews__item-inner -pdf">
            <span class="modTxtDate modListNews__item-date">2022.03.31</span>
            <span class="modTxtCategory modListNews__item-category">Web</span>
            <span class="modListNews__item-txt"><span class="modListNews__item-title">タウンニュース</span><br>クイックコマース拡大　料理宅配各社　日用品もお届け　安定収入、人材確保狙う</span>
            <span class="modListNews__item-icon"></span>
        </a>
    </li>
    <li class="modListNews__item">
        <span class="modListNews__item-inner">
            <span class="modTxtDate modListNews__item-date">2022.03.31</span>
            <span class="modTxtCategory modListNews__item-category">新聞</span>
            <span class="modListNews__item-txt"><span class="modListNews__item-title">読売新聞</span><br>クイックコマース拡大　料理宅配各社　日用品もお届け　安定収入、人材確保狙う</span>
        </span>
    </li>
    <li class="modListNews__item">
        <span class="modListNews__item-inner">
            <span class="modTxtDate modListNews__item-date">2022.05.02</span>
            <span class="modListNews__item-txt"><span class="modListNews__item-title">フジテレビ「めざましテレビ」</span><br>ヤフーや出前館、宅配サービス「Yahoo!マート」本格スタート</span>
        </span>
    </li>
    <li class="modListNews__item">
        <a href="#" target="_blank" class="modListNews__item-inner -blank">
            <span class="modListNews__item-logo">
                <picture>
                    <source srcset="/assets/img/ir_information/library/index/logo_sr_sp.jpg 1x,/assets/img/ir_information/library/index/logo_sr_sp@2x.jpg 2x" media="(max-width: 768px)">
                    <img src="/assets/img/ir_information/library/index/logo_sr.jpg" srcset="/assets/img/ir_information/library/index/logo_sr@2x.jpg 2x" alt="Shared Research">
                </picture>
            </span>
            <span class="modListNews__item-txt">シェアードリサーチ社による当社の調査レポートはこちら<span class="modListNews__item-icon"></span></span>
        </a>
    </li>
</ul>

<ul class="modListNews">
	<li class="modListNews__item -type02">
		<p class="modListNews__item-ttl">2020年8月期</p>
		<div class="modListNews__item-link">
			<a href="#" class="modListNews__item-inner -pdf" target="_blank">
				<span class="modListNews__item-txt">有価証券報告書（639KB）</span>
				<span class="modListNews__item-icon"></span>
			</a>
			<a href="#" class="modListNews__item-inner -pdf" target="_blank">
				<span class="modListNews__item-txt">有価証券報告書の訂正報告書（643KB）</span>
				<span class="modListNews__item-icon"></span>
			</a>
		</div>
	</li>
	<li class="modListNews__item -type02">
		<p class="modListNews__item-ttl">2020年8月期</p>
		<div class="modListNews__item-link">
			<a href="#" class="modListNews__item-inner -pdf" target="_blank">
				<span class="modListNews__item-txt">第2四半期報告書（154KB）</span>
				<span class="modListNews__item-icon"></span>
			</a>
			<a href="#" class="modListNews__item-inner -pdf" target="_blank">
				<span class="modListNews__item-txt">第2四半期報告書の訂正報告書（13KB）</span>
				<span class="modListNews__item-icon"></span>
			</a>
			<a href="#" class="modListNews__item-inner -pdf" target="_blank">
				<span class="modListNews__item-txt">第2四半期報告書の訂正報告書（160KB）</span>
				<span class="modListNews__item-icon"></span>
			</a>
		</div>
	</li>
</ul>

トップ用
<ul class="modListNews -top">
    <li class="modListNews__item">
        <a href="#" class="modListNews__item-inner">
            <span class="modTxtDate modListNews__item-date">2022.05.10</span>
            <span class="modTxtCategory modListNews__item-category">出前館</span>
            <span class="modListNews__item-txt">『出前館』、加盟店を表彰する「Demaecan Award 2021」を初開催！</span>
            <span class="modListNews__item-icon"></span>
        </a>
    </li>
</ul>
```
*/
.modListNews{
	$_: &;
	&:not(:first-child) {
		margin-top: 60px;
		@include g.tabletP {
			margin-top: 40px;
		}
	}


	&__item {
		border-top: 1px solid nth(g.$partsColor, 2);
		&:last-child {
			border-bottom: 1px solid nth(g.$partsColor, 2);
		}

		&.-type02 {
			display: flex;

			#{$_}__item-ttl {
				padding: 30px 0;
				margin-right: 30px;
				@include g.tabletP{
					padding: 20px 0;
					margin-right: 10px;
				}
			}
			#{$_}__item-link {
				flex: 1;
			}
			#{$_}__item-inner {
				&:not(:last-child){
					border-bottom: 1px solid #DDDDDD;
				}
				@include g.tabletP{
					padding: 18px 0;
				}
			}
			#{$_}__item-txt {
				@include g.tabletP{
					margin: 2.5px 0 0;
				}
			}
		}
		&-inner {
			display: flex;
			align-items: center;
			position: relative;
			padding: 30px 0;
			@include g.tabletP {
				flex-wrap: wrap;
				padding: 20px 0;
				&[href] {
					padding-right: 40px;
				}
			}
			&.-type02 {
				flex: 1;
				#{$_}__item-txt {
					margin: 0;
				}
			}
		}
		&-date {
			flex-shrink: 0;
			@include g.tabletPmin {
				min-width: 112px;
				margin-right: 10px;
			}
			@include g.tabletP {
				min-width: 82px;
				margin-right: 15px;
			}
			#{$_}__item-inner:not(:hover) & {
				color: #616161;
			}
		}
		&-category {
			@include g.tabletPmin {
				margin-right: 30px;
			}
		}
		&-logo {
			@include g.tabletPmin {
				min-width: 280px;
				margin-right: 30px;
			}
			@include g.tabletP {
				text-align: center;
				margin-bottom: 10px;
				margin-right: -40px;
			}
		}
		&-title {
			font-weight: bold;
		}
		&-txt {
			display: block;
			flex-basis: 100%;
			position: relative;
			margin-right: 30px;
			@include g.tabletP {
				margin: 5px 0 0;
			}
		}
		&-icon {
			display: block;
			flex-shrink: 0;
			position: relative;
			width: 20px;
			height: 20px;
			background-color: nth(g.$mainColor, 1);
			border-radius: 50%;
			overflow: hidden;
			@include g.tabletP {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				margin: auto;
			}
			#{$_}__item-txt & {
				position: absolute;
				top: 4px;
				bottom: 0;
				right: -30px;
				@include g.tabletP {
					top: 2px;
					right: -40px;
				}
			}
			&::after {
				content: "";
				background: url(#{g.$cmnPath}ico_circle_arrow.svg) no-repeat center / 20px;
				display: block;
				width: 20px;
				height: 20px;
			}
			#{$_}__item-inner.-blank & {
				&::after {
					background-image: url(#{g.$cmnPath}ico_circle_blank.svg);
					transition: map-get(g.$iconMove02, 'sp') map-get(g.$iconMove02, 'easing');
				}
			}
			#{$_}__item-inner.-pdf & {
				&::after {
					background-image: url(#{g.$cmnPath}ico_circle_pdf.svg);
				}
			}
		}
		&-new {
			border: 1px solid nth(g.$mainColor, 1);
			border-radius: 20px;
			position: relative;
			top: -2px;
			color: nth(g.$mainColor, 1);
			@include g.fz(12);
			font-family: g.$fontAlpha;
			letter-spacing: 0.03em;
			margin-left: 11px;
			padding: 2px 10px 3px;
			@include g.tabletP {
				top: 1px;
				@include g.fz(10);
				margin-left: 10px;
			}
		}
		@include g.tabletPmin {
			&-inner:not(.-blank):not(.-pdf):hover {
				#{$_}__item-icon {
					&::after {
						animation: iconMove map-get(g.$iconMove, 'sp') map-get(g.$iconMove, 'easing') map-get(g.$iconMove, 'delay') 1 map-get(g.$iconMove, 'end');
					}
				}
			}
			&-inner.-blank:hover {
				#{$_}__item-icon {
					&::after {
						transform: scale(-1, -1);
					}
				}
			}
		}
	}

	&.-top{
		#{$_}__item{
			&:first-child{
				border-top: none;

				// #{$_}__item-inner{
				// 	padding-top: 0;
				// }
			}

			&:last-child{
				border-bottom: none;
			}
		}
	}
}//.modListNews

/*
#styleguide
modListCategory

```
<ul class="modListCategory">
</ul>

縦
<div class="modListCategory -vertical">
	<button type="button" class="modListCategory__item -selected">ニュースリリース</button>
	<button type="button" class="modListCategory__item">IRニュース</button>
	<button type="button" class="modListCategory__item">掲載記事</button>
	<button type="button" class="modListCategory__item">テレビ・ラジオ放映</button>
</div>

色違い
<div class="modListCategory -color02">
	<button type="button" class="modListCategory__item -selected">ニュースリリース</button>
	<button type="button" class="modListCategory__item">IRニュース</button>
	<button type="button" class="modListCategory__item">掲載記事</button>
	<button type="button" class="modListCategory__item">テレビ・ラジオ放映</button>
</div>
```
*/
.modListCategory{
	$_: &;
	display: flex;
	flex-wrap: wrap;
	gap: 15px 10px;
	@include g.tabletP {
		gap: 10px 10px;
	}

	&.-vertical{
		@include g.tabletPmin{
			display: block;
		}

		#{$_}__item{
			display: block;
			@include g.tabletP{
			}
			&:not(:last-child){
				@include g.tabletPmin{
					margin-bottom: 15px;
				}
			}
		}
	}

	&__item {
		background-color: #F5F5F5;
		border-radius: 40px;
		border: none;
		position: relative;
		color: #212121;
		@include g.fz(14);
		padding: 10px 25px 13px;
		z-index: 1;
		@include g.tabletP {
			@include g.fz(12);
			width: calc(50% - 5px);
			padding: 9px 20px 12px;
		}
		&::before{
			content: "";
			display: block;
			position: absolute;
			z-index: -1;
			inset: 0;
			margin: auto;
			border-radius: 999999px;
			opacity: 0;
			transition: opacity 0.35s cubic-bezier(0.23, 1, 0.58, 1), transform 0.5s cubic-bezier(0.23, 1, 0.58, 1);
			transform: scale(0);
			background-color: nth(g.$mainColor, 1);
			will-change: transform;
		}
		&.-selected {
			color: #fff;
			font-weight: bold;
			&::before {
				opacity: 1;
				transform: scale(1);
			}
		}
		@include g.tabletPmin{
			&:hover{
				transition: background-color 0.1s 0.15s cubic-bezier(0.23, 1, 0.58, 1);
				color: #fff;
				&::before{
					opacity: 1;
					transition: all 0.3s cubic-bezier(0.23, 1, 0.58, 1);
					transform: scale(1);
				}
			}
		}
	}

	&.-color02{
		#{$_}__item{
			background-color: #fff;
		}
	}
}//.modListCategory


/*
#styleguide
modListLinks

```
<ul class="modListLinks">
	<li class="modListLinks__item"><a class="modTxtLink -pdf" href="#"><span class="modTxtLink__txt">第22期定時株主総会継続会 開催ご通知（694KB）</span></a></li>
	<li class="modListLinks__item"><a class="modTxtLink -pdf" href="#"><span class="modTxtLink__txt">第22期定時株主総会継続会 開催ご通知（694KB）</span></a></li>
	<li class="modListLinks__item"><a class="modTxtLink -pdf" href="#"><span class="modTxtLink__txt">第22期定時株主総会継続会 開催ご通知（694KB）</span></a></li>
</ul>
```
*/
.modListLinks{

	&__item{
		&:not(:last-child){
			margin-bottom: 8px;
		}
	}

}//.modListLinks

/*
#styleguide
modListCaution01

```
<ul class="modListCaution01">
	<li class="modListCaution01__item">
		<span class="modListCaution01__head">※</span>
		注釈リストが入ります。この文章はダミーテキストです。
	</li>
</ul>
```
*/
.modListCaution01 {
	$_: &;
	display: table;
	width: 100%;
	&:not(:first-child) {
		margin-top: 60px;
		@include g.tabletP {
			margin-top: 40px;
		}
	}
	&:not(:last-child) {
		margin-bottom: 40px;
		@include g.tabletP {
			margin-bottom: 30px;
		}
	}
	&__item {
		display: table-row;
		color: #616161;
		@include g.fz(14);
		@include g.tabletP {
			@include g.fz(12);
		}
		&:not(:first-child) {
			& > * {
				border-top: 5px solid transparent;
			}
		}
	}
	&__head {
		display: table-cell;
		white-space: nowrap;
		width: 0;
		padding-right: 5px;
	}
}//.modListCaution01
