@charset "utf-8";
/*
#overview
modBlock
*/

@use "global" as g;
@use 'sass:math';


/*
#styleguide
modBlockImage01

中央寄せ、下margin
```
<div class="modBlockImage01">
<picture>
<source srcset="/assets/img/company/message/img_message_sp.jpg 1x,/assets/img/company/message/img_message_sp@2x.jpg 2x" media="(max-width: 600px)">
<img src="/assets/img/company/message/img_message.jpg" srcset="/assets/img/company/message/img_message@2x.jpg 2x" alt="">
</picture>
</div>
margin半分版
<div class="modBlockImage01 -type02">
<picture>
<source srcset="/assets/img/company/message/img_message_sp.jpg 1x,/assets/img/company/message/img_message_sp@2x.jpg 2x" media="(max-width: 600px)">
<img src="/assets/img/company/message/img_message.jpg" srcset="/assets/img/company/message/img_message@2x.jpg 2x" alt="">
</picture>
</div>
```
*/

.modBlockImage01{
	margin-bottom: 80px;
	text-align: center;
	@include g.tabletP{
		margin-bottom: 60px;
	}

	&.-type02{
		margin-bottom: 40px;
		@include g.tabletP{
			margin-bottom: 30px;
		}
	}
}//.modBlockImage01


/*
#styleguide
modBlockInner01

```
<div class="modBlockInner01">
上下余白つきます
</div>
<div class="modBlockInner01 -type02">
余白違い 少し狭い
</div>
```
*/
.modBlockInner01{
	padding-top: 153px;
	padding-bottom: 120px;
	@include g.tabletP{
		padding-top: 106px;
		padding-bottom: 80px;
	}

	&.-type02{
		padding-top: #{32+80}px;
		@include g.tabletP{
			padding-top: #{26+60}px;
		}
	}

	>.container{
		>*{
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
}//.modBlockInner01


/*
#styleguide
modBlockApp

```
<div class="modBlockApp">
	<div class="modBlockApp__inner">
		<p class="modBlockApp__txt">アプリのダウンロードはこちらから</p>
		<div class="modBlockApp__list">
			<a class="modBlockApp__item" href="#" target="_blank">
				<picture>
					<source srcset="<%= cmnPath %>img_app01_sp.png 1x,<%= cmnPath %>img_app01_sp@2x.png 2x" media="(max-width: 768px)">
					<img src="<%= cmnPath %>img_app01.png" srcset="<%= cmnPath %>img_app01@2x.png 2x" alt="AppStoreからダウンロード">
				</picture>
			</a>
			<a class="modBlockApp__item" href="#" target="_blank">
				<picture>
					<source srcset="<%= cmnPath %>img_app02_sp.png 1x,<%= cmnPath %>img_app02_sp@2x.png 2x" media="(max-width: 768px)">
					<img src="<%= cmnPath %>img_app02.png" srcset="<%= cmnPath %>img_app02@2x.png 2x" alt="GooglePlayで手に入れよう">
				</picture>
			</a>
		</div>
	</div>
</div><!-- /.modBlockApp -->
```
*/
.modBlockApp {
	background: #F5F5F5;
	border-radius: 5px;
	margin-bottom: 80px;

	@include g.tabletP{
		margin-bottom: 60px;
	}

	&__inner {
		padding: 27px 30px 30px;
	}

	&__txt {
		@include g.fz(20);
		font-weight: 900;
		letter-spacing: 0.03em;
		text-align: center;
		margin-bottom: 18px;

		@include g.tabletP {
			@include g.fz(16);
			line-height: math.div(24, 16);
			margin-bottom: 16px;
		}
	}

	&__list {
		display: flex;
		justify-content: center;
		gap: 21px;

		@include g.tabletP{
			gap: 10px;
		}
	}

	&__item {
		display: block;
		transition: opacity 0.3s ease;
	}
}//.modBlockApp



/*
#styleguide
modBlockPdf

```
	<div class="modBlockPdf">
		<div class="modBlockPdf__adobe">
			<div class="modBlockPdf__adobe-bnr"><a href="https://get.adobe.com/jp/reader/otherversions/" target="_blank"><img src="/assets/img/common/bnr_adobe_acrobat.png" alt="GET Adobe Acrobat Reader"></a></div>
			<p class="modBlockPdf__adobe-detail">PDF形式のファイルをご覧になるためにはAdobe Readerが必要です。<br>Adobe Readerをお持ちでない場合は、こちらのアイコンからダウンロードして下さい。</p>
		</div>
		<div class="modBlockPdf__body">
			<p>本ページ上には、将来の業績に関する記述が含まれています。こうした記述は、将来の業績を保証するものではなく、リスクや不確実性を内包するものです。将来の業績は、経営環境の変化などにより、実際の結果と異なる可能性があることにご留意ください。<br>
				本画面に掲載されているコンテンツは、<a href="http://www.pronexus.co.jp/" class="modBlockPdf__link" target="_blank">(株)プロネクサス<span class="modBlockPdf__link-icon"></span></a>より情報の提供を受けております。</p>
			<div class="modBlockPdf__body-logo"><a href="http://www.pronexus.co.jp/" target="_blank"><img src="/assets/img/common/logo_eir.png" alt="Powered by E-IR"></a></div>
		</div>
	</div><!-- /.modBlockPdf -->

```
*/
.modBlockPdf{
	margin-top: 80px;

	@include g.tabletP{
		margin-top: 60px;
	}

	&__adobe{
		margin-bottom: 40px;
		@include g.tabletPmin{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		@include g.tabletP{
			margin-bottom: 30px;
		}

		&-bnr{
			@include g.tabletPmin{
				width: 14.5%;
			}

			@include g.tabletP{
				margin-bottom: 10px;
			}


			img{
				@include g.tabletP{
					width: 176px;
				}
			}

		}

		&-detail{
			@include g.fz(14);

			@include g.tabletPmin{
				flex:1;
				padding-left: 32px;
			}

			@include g.tabletP{
				@include g.fz(12);
			}
		}

	}

	&__body{
		font-size: 14px;
		color: #616161;
		@include g.tabletP{
			@include g.fz(12);
		}


		&-logo{
			text-align: right;

			img{
				@include g.tabletP{
					width: 105px;
				}
			}
		}


	}


	&__link{
		margin-right: 4px;
		color:nth(g.$mainColor,1);
		text-decoration: underline;

		$_:&;

		@include g.tabletPmin{
			&:hover{
				#{$_}-icon{
					&::after{
						transform: scale(-1, -1);
					}

				}
			}
		}

		&-icon{
			position: relative;
			display: inline-block;
			width: 10px;
			height: 10px;
			@include g.tabletP{
				width: 8px;
				height: 8px;
			}

			&::after {
				content: "";
				position: absolute;
				left: 0;
				inset-block:0;
				margin-block:auto;
				background-position: 0 0;
				background-repeat: no-repeat;
				background-image: url(#{g.$cmnPath}ico_blank.svg);
				background-size: 10px 10px;
				width: 10px;
				height: 10px;
				transition: transform map-get(g.$iconMove02, 'sp') map-get(g.$iconMove02, 'easing');

				@include g.tabletP {
					background-size: 8px 8px;
					width: 8px;
					height: 8px;
				}
			}
		}
	}
}//.modBlockPdf


/*
#styleguide
modBlockPdf

```
				<section class="modBlockLink">
					<div class="modBlockLink__inner">
						<a href="#" class="modBlockLink__item">
							<h2 class="modBlockLink__title">株式基本情報<span class="modBlockLink__title-icon"></span></h2>
							<p class="modBlockLink__txt">当社の株式の概要及び基本情報をご覧いただけます。</p>
						</a>
						<a href="#" target="_blank" class="modBlockLink__item -blank">
							<h2 class="modBlockLink__title">株価情報<span class="modBlockLink__title-icon"></span></h2>
							<p class="modBlockLink__txt">Yahoo!ファイナンスにて、当社の株価情報をご覧いただけます。</p>
						</a>
						<a href="#" class="modBlockLink__item">
							<h2 class="modBlockLink__title">株主還元<span class="modBlockLink__title-icon"></span></h2>
							<p class="modBlockLink__txt">当社の株主還元の基本方針をご覧いただけます。</p>
						</a>
					</div>
				</section><!-- /.modBlockLink -->
```
*/

.modBlockLink {
	$_: &;

	&__inner {
		@include g.tabletPmin{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			gap: 0 32px;
		}
	}
	&__item {
		display: block;
		text-decoration: none;
		width: calc((100% - 32px) / 2);
		padding: 60px 0;
		border-bottom: 1px solid #ddd;

		@include g.tabletPmin{
			&:nth-child(-n + 2) {
				border-top: 1px solid #ddd;
			}

			&:not(.-blank):hover {
				#{$_}__title-icon {
					&::after {
						animation: iconMove map-get(g.$iconMove, 'sp') map-get(g.$iconMove, 'easing') map-get(g.$iconMove, 'delay') 1 map-get(g.$iconMove, 'end');
					}
				}
			}

			&.-blank:hover {
				#{$_}__title-icon {
					&::after {
						transform: scale(-1, -1);
					}
				}
			}
		}

		@include g.tabletP{
			width: 100%;
			padding: 40px 0;

			&:first-child {
				border-top: 1px solid #ddd;
			}
		}
	}

	&__title {
		@include g.fz(24);
		font-weight: 900;
		line-height: math.div(36, 24);
		letter-spacing: 0.03em;

		@include g.tabletP {
			@include g.fz(18);
			line-height: math.div(26, 18);
		}

		&-icon {
			display: inline-block;
			position: relative;
			top: 3px;
			width: 24px;
			height: 24px;
			background-color: nth(g.$mainColor, 1);
			border-radius: 50%;
			margin-left: 10px;
			overflow: hidden;
			@include g.tabletP {
				width: 18px;
				height: 18px;
				top: 2px;
				margin-left: 6px;
			}
			&::after {
				content: "";
				background: url(#{g.$cmnPath}ico_circle_arrow.svg) no-repeat center / 24px;
				display: block;
				width: 24px;
				height: 24px;
				@include g.tabletP {
					background: url(#{g.$cmnPath}ico_circle_arrow.svg) no-repeat center / 18px;
					width: 18px;
					height: 18px;
				}
			}
			#{$_}__item.-blank & {
				&::after {
					background-image: url(#{g.$cmnPath}ico_circle_blank.svg);
					transition: map-get(g.$iconMove02, 'sp') map-get(g.$iconMove02, 'easing');
					@include g.tabletP{
						background-size: 18px 18px;
						width: 18px;
						height: 18px;
					}
				}
			}
		}
	}
	&__txt {
		@include g.fz(16);
		font-weight: 400;
		line-height: math.div(28, 16);
		letter-spacing: normal;
		margin-top: 15px;

		@include g.tabletP {
			@include g.fz(14);
			line-height: math.div(25, 14);
			margin-top: 10px;
		}
	}
}//.modBlockLink

/*
#styleguide
modBlockNewsHead

```
	<div class="modBlockNewsHead -searchOnly">
		<form action="/">
			<div class="modBlockNewsHead__inner">
				<div class="modFormSelect modBlockNewsHead__search">
					<select name="search" class="modFormSelect__input">
						<option>Year</option>
						<option>2022</option>
						<option>2021</option>
						<option>2020</option>
						<option>2019</option>
						<option>2018</option>
						<option>2017</option>
					</select>
					<span class="modFormSelect__icon"></span>
				</div>
			</div>
		</form>
	</div>
```
*/

.modBlockNewsHead {
	$_: &;
	margin-bottom: 80px;
	@include g.tabletP {
		margin-bottom: 60px;
	}
	&:not(:first-child) {
		margin-top: 60px;
		margin-bottom: 60px;
		@include g.tabletP {
			margin-top: 40px;
			margin-bottom: 40px;
		}
	}
	&__inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 20px 30px;
		@include g.tabletP {
			flex-wrap: wrap;
		}

		@at-root #{$_}.-searchOnly &{
			@include g.tabletPmin{
				justify-content: flex-end;
			}
		}
	}


	&__category {
		width: 100%;
	}
	&__search {
		width: 100%;
		@include g.tabletPmin {
			max-width: 280px;
		}
	}
}//.modBlockNewsHead

/*
#styleguide
modBlockMapWidget

```
<div class="modBlockMapWidget">
	<p class="modBlockMapWidget__title">タイトル</p>
	<p class="modBlockMapWidget__txt">テキストや住所が入ります。</p>
	<a href="#" target="_blank" class="modBlockMapWidget__link">拡大地図を表示</a>
</div>
```
*/

.modBlockMapWidget {
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1);
	font-family: g.$baseFontFamily;
	max-width: 280px;
	margin: 10px;
	padding: 15px 20px 20px;
	@include g.tabletP {
		padding: 7px 20px 10px;
	}
	&__title {
		@include g.fz(14);
		font-weight: bold;
		margin-bottom: 5px;
		@include g.tabletP {
			display: none;
		}
	}
	&__txt {
		color: #616161;
		@include g.fz(12);
		margin-bottom: 10px;
		@include g.tabletP {
			display: none;
		}
	}
	&__link {
		color: nth(g.$mainColor, 1);
		text-decoration: underline;
		@include g.fz(12);
		font-weight: bold;
		@include g.tabletP {

		}
	}
}//.modBlockMapWidget

.modBlockInPageAnchors {
	line-height: 1.75;
	
	display: flex;
	grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
    margin-bottom: 80px;

	@include g.tabletP {
	  display: grid;
      flex-wrap: wrap;
	  grid-template-columns: 1fr 1fr;
      margin-bottom: 74px;
	  gap: 40px 0;
    }

    &_link {
      display: flex;
      flex-flow: column;
      align-items: center;
      text-decoration: none;
	  
      &:hover {
        .modBlockInPageAnchors_arw {
          transform: translateY(5px);
        }
      }
    }

    &_text {
      color: var(--brand-color-demaecan-black-212121, #212121);
      @include g.fz(16);
      font-weight: 700;
      margin-bottom: 8px;

	  @include g.tabletP {
		@include g.fz(12);
	  }
    }

    &_arw {
      position: relative;
      transition: ease-in-out 0.2s;
	  margin: g.fluidSize(4, 2.5, 1600, 375) 0;
      width: g.fluidSize(21, 12, 1600, 375);
      height: g.fluidSize(12, 7, 1600, 375);
      object-fit: contain;
    }
}//.modBlockInPageAnchors