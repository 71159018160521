@charset "utf-8";

@use "global" as g;
@use "sass:math";

#gNav {}
.gNav {
	$_: &;
	background-color: nth(g.$partsColor, 1);
	position: fixed;
	top: g.$headerHeight;
	left: 0;
	right: 0;
	max-height: calc(100vh - #{g.$headerHeight});
	padding: 60px 0 60px;
	overflow: auto;
	body[data-ready] & {
		transition: .5s;
	}
	@include g.tabletP {
		top: g.$headerHeightSp;
		max-height: calc(100vh - #{g.$headerHeightSp});
		padding: 0 0 100px;
	}
	body:not([data-nav__is_open = "true"]) & {
		opacity: 0;
		pointer-events: none;
		visibility: hidden;
	}
	&__inner {
		@include g.tabletPmin {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			gap: 60px 0;
		}
	}//__inner
	&Block {
		$_2: &;
		@include g.tabletPmin {
			width: calc(33.3333% - #{32px * math.div(2, 3)});
			&.-type02 {
				width: 100%;
				#{$_}Block__group {
					width: calc(33.3333% - #{32px * math.div(2, 3)});
				}
				#{$_}Block__item {
					&:not(:last-child) {
						margin-bottom: 20px;
					}
				}
			}
		}
		@include g.tabletP {
			margin: 0 -#{g.$containerPaddingSp}px;
		}
		&__body {
			@include g.tabletPmin {
				display: flex !important;
				flex-wrap: wrap;
				justify-content: space-between;
				width: 100%;
				height: auto !important;
			}
			@include g.tabletP {
				display: none;
			}
		}
		&__group {
			position: relative;
			width: 100%;
			@include g.tabletP {
				&:not(:last-child) {
					&::after {
						content: "";
						background-color: nth(g.$partsColor, 2);
						position: absolute;
						bottom: 0;
						left: #{g.$containerPaddingSp}px;
						right: #{g.$containerPaddingSp}px;
						height: 1px;
					}
				}
			}
		}
		&__list {
			overflow: hidden;
		}
		&__title {
			$_3: &;
			font-weight: 900;
			position: relative;
			letter-spacing: 0.03em;
			@include g.tabletPmin {
				border-bottom: 1px solid nth(g.$partsColor, 2);
				display: flex;
				align-items: center;
				@include g.fz(20);
				margin-bottom: 20px;
				padding-bottom: 20px;
			}
			@include g.tabletP {
				@include g.fz(15.8);
				padding: 0 #{g.$containerPaddingSp}px;
			}
			&-link {
				display: flex;
				align-items: center;
				position: relative;
				@include g.tabletPmin {
					&:hover {
						#{$_3}-icon {
							&::after {
								animation: iconMove map-get(g.$iconMove, 'sp') map-get(g.$iconMove, 'easing') map-get(g.$iconMove, 'delay') 1 map-get(g.$iconMove, 'end');
							}
						}
					}
				}
				@include g.tabletP {
					padding: 24px 0 23px;
				}
			}
			&-icon {
				position: relative;
				flex-shrink: 0;
				width: 20px;
				height: 20px;
				margin-left: 8px;
				background-color: nth(g.$mainColor, 1);
				border-radius: 50%;
				overflow: hidden;
				&::after {
					content: "";
					background: url(#{g.$cmnPath}ico_circle_arrow.svg) no-repeat center / contain;
					position: absolute;
					top: 0;
					left: 0;
					width: inherit;
					height: inherit;
				}
				@include g.tabletP {
					display: none;
				}
			}
			&-toggle {
				@include g.tabletPmin {
					background: url(#{g.$cmnPath}ico_circle_arrow.svg) no-repeat center / 20px;
					border: none;
					display: none;
					flex-shrink: 0;
					width: 20px;
					height: 20px;
					margin-left: 8px;
				}
				@include g.tabletP {
					border-bottom: 1px solid transparent;
					display: block;
					position: absolute;
					top: 0;
					left: #{g.$containerPaddingSp}px;
					right: #{g.$containerPaddingSp}px;
					width: auto;
					height: 100%;
					margin-left: 0;
					#{$_}Block__title:not(:last-child) & {
						border-bottom-color: nth(g.$partsColor, 2);
					}
					&::before,
					&::after {
						content: "";
						position: absolute;
						top: 2px;
						bottom: 0;
						right: 0;
						width: 20px;
						height: 20px;
						margin: auto;
					}
					&::before {
						background-color: nth(g.$mainColor, 1);
						border-radius: 50%;
					}
					&::after {
						background: url(#{g.$cmnPath}ico_circle_arrow.svg) no-repeat center / contain;
					}
					&:not([data-acc = "btn"]) {
						pointer-events: none;
						transform: none;
					}
					&[data-acc = "btn"] {
						cursor: pointer;
						&::after {
							background-image: url(#{g.$cmnPath}ico_circle_plus.svg);
						}
						&[aria-expanded = "true"] {
							border-bottom-color: transparent;
							&::after {
								background-image: url(#{g.$cmnPath}ico_circle_minus.svg);
							}
						}
					}
				}
			}
		}
		&__item {
			position: relative;
			@include g.tabletPmin {
				&:not(:last-child) {
					margin-bottom: 10px;
				}
			}
			@include g.tabletP {
				background-color: #fff;
				padding: 0 #{g.$containerPaddingSp}px;
				&:not(:last-child) {
					&::after {
						content: "";
						background-color: nth(g.$partsColor, 2);
						position: absolute;
						bottom: 0;
						left: #{g.$containerPaddingSp}px;
						right: #{g.$containerPaddingSp}px;
						height: 1px;
					}
				}
			}
		}
		&__icon {
			position: absolute;
			@include g.tabletP {
				display: none;
			}
			&.-blank {
				@include g.tabletPmin {
					width: 12px;
					height: 12px;
					margin: .55em 0 0 9px;
					&::after {
						content: "";
						background: url(#{g.$cmnPath}ico_blank.svg) no-repeat center / cover;
						position: absolute;
						top: 0;
						left: 0;
						width: inherit;
						height: inherit;
						transition: transform map-get(g.$iconMove02, 'sp') map-get(g.$iconMove02, 'easing');
					}
					#{$_2}__link:hover & {
						&::after {
							transform: scale(-1, -1);
						}
					}
				}
			}
		}
		&__link {
			position: relative;
			font-weight: bold;
			@include g.tabletPmin {
				display: inline-block;
				padding-left: 15px;
				&::before {
					content: "";
					background: url(#{g.$cmnPath}ico_arrow02.svg) no-repeat center / contain;
					position: absolute;
					top: .55em;
					bottom: 0;
					left: 0;
					width: 7px;
					height: 12px;
				}
			}
			@include g.tabletP {
				display: block;
				padding: 20px 0 21px;
			}
		}
		&__toggle {
			@include g.tabletP {
				border: none;
				position: absolute;
				top: 0;
				right: 0;
				width: 60px;
				height: 64.5px;
				&::before {
					content: "";
					position: absolute;
					top: 0;
					right: 0;
					width: calc(100vw - #{(g.$containerPaddingSp * 2px)});
					height: 100%;
				}
				&::after {
					content: "";
					background-color: nth(g.$mainColor, 1);
					mask: url(#{g.$cmnPath}ico_arrow02.svg) no-repeat center / 7px;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					transition: transform .15s;
				}
				&.-blank {
					&::after {
						mask-image: url(#{g.$cmnPath}ico_blank.svg);
						mask-size: 14px;
					}
				}
			}
			&:not([data-acc = "btn"]) {
				pointer-events: none;
				transform: none;
			}
			&[data-acc = "btn"] {
				cursor: pointer;
				&::after {
					transform: rotate(90deg);
				}
				&[aria-expanded = "true"] {
					&::after {
						background-color: #616161;
						transform: rotate(-90deg);
					}
				}
			}
		}
		&__child {
			$_3: &;
			@include g.tabletPmin {
				display: block !important;
				padding: 10px 0 0 17px;
			}
			@include g.tabletP {
				display: none;
				padding-bottom: 20px;
			}
			&-item {
				&:not(:last-child) {
					margin-bottom: 10px;
					@include g.tabletP {
						margin-bottom: 15px;
					}
				}
			}
			&-icon {
				position: absolute;
				width: 10px;
				height: 10px;
				margin: .55em 0 0 5px;
				&.-blank {
					&::after {
						content: "";
						background: url(#{g.$cmnPath}ico_blank.svg) no-repeat center / cover;
						position: absolute;
						top: 0;
						left: 0;
						width: inherit;
						height: inherit;
						transition: map-get(g.$iconMove02, 'sp') map-get(g.$iconMove02, 'easing');
					}
					@include g.tabletPmin {
						#{$_3}-link:hover & {
							&::after {
								transform: scale(-1, -1);
							}
						}
					}
				}
			}
			&-link {
				display: inline-block;
				position: relative;
				@include g.tabletPmin {
					@include g.fz(14);
					padding-left: 18px;
				}
				@include g.tabletP {
					padding-left: 16px;
				}
				&::before {
					content: "";
					position: absolute;
					left: 0;
					@include g.tabletPmin {
						background-color: #616161;
						top: .875em;
						width: 8px;
						height: 1px;
					}
					@include g.tabletP {
						background: url(#{g.$cmnPath}ico_arrow02.svg) no-repeat center / contain;
						position: absolute;
						top: .55em;
						bottom: 0;
						left: 0;
						width: 6px;
						height: 10px;
					}
				}
			}
		}
	}//Block
	&__list01 {
		@include g.tabletPmin {
			display: flex;
			justify-content: space-between;
			gap: 0 32px;
			width: 100%;
		}
		@include g.tabletP {
			margin-top: 40px;
		}
		&-item {
			color: #fff;
			text-align: center;
			@include g.tabletPmin {
				flex-basis: 100%;
			}
			@include g.tabletP {
				@include g.fz(14);
				&:not(:last-child) {
					margin-bottom: 10px;
				}
			}
		}
		&-link {
			background-color: nth(g.$mainColor, 1);
			border-radius: 40px;
			display: block;
			position: relative;
			color: inherit;
			padding: 25px 30px 27px;
			@include g.tabletP {
				padding-top: 18px;
				padding-bottom: 21px;
			}
			&.-blank {
				&::after {
					content: "";
					background-color: #fff;
					mask: url(#{g.$cmnPath}ico_blank.svg) no-repeat center / cover;
					position: absolute;
					top: 0;
					bottom: 0;
					right: 40px;
					width: 16px;
					height: 16px;
					margin: auto;
					@include g.tabletP {
						right: 25px;
						width: 14px;
						height: 14px;
					}
				}
			}
		}
	}//__list01
	&__list02 {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		@include g.tabletPmin {
			border-top: 1px solid nth(g.$partsColor, 2);
			padding-top: 40px;
			gap: 20px 40px;
		}
		@include g.tabletP {
			justify-content: space-between;
			gap: 10px 0;
			margin-top: 40px;
		}
		&-item {
			@include g.fz(14);
			@include g.tabletP {
				width: calc(50% - 10px);
				@include g.fz(12);
			}
		}
		&-link {
			display: block;
			position: relative;
			padding-left: 14px;
			&::before {
				content: "";
				background: url(#{g.$cmnPath}ico_arrow02.svg) no-repeat center / contain;
				position: absolute;
				top: .55em;
				bottom: 0;
				left: 0;
				width: 6px;
				height: 10px;
			}
		}
	}//__list02

	// .gHeader hoverで表示する各ページのナビ .gNav をオーバーライドする
	@at-root .gHeaderNav {
		@media(hover: hover) {
			body:not([data-nav__is_open = "true"]) &.-active {
				.gHeaderNav__outer {
					opacity: 1;
					background-size: 100% 100%;
					pointer-events: auto;
					visibility: visible;
					body[data-ready] & {
						transition: background-size .2s g.$easeOutCubic;
					}
				}
				& + .gHeaderNav__overlay {
					opacity: 1;
					visibility: visible;
				}
				.gNavBlock__title,
				.gNavBlock__body {
					opacity: 1;
					transform: translateY(0);
				}
				.gNavBlock__title {
					body[data-ready] & {
						transition: .2s .2s;
					}
				}
				.gNavBlock__body {
					body[data-ready] & {
						transition: .2s .35s;
					}
				}
			}
		}
		&__outer,
		&__overlay {
			pointer-events: none;
			visibility: hidden;
		}
		&__outer {
			background: linear-gradient(to bottom, nth(g.$partsColor, 1) 100%, nth(g.$partsColor, 1) 100%) no-repeat;
			background-size: 100% 75%;
			opacity: 0;
			position: fixed;
			top: g.$headerHeight;
			left: 0;
			right: 0;
			max-height: calc(100vh - #{g.$headerHeight});
			padding: 60px 0 60px;
			overflow: auto;
			body[data-ready] & {
				transition: .15s;
			}
		}
		&__inner {
			@include g.tabletPmin {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				gap: 60px 0;
				max-width: 1009px;
				margin: 0 auto;
			}//__inner
		}
		.gNavBlock {
			width: 100%;
			&__title,
			&__body {
				opacity: 0;
				transition: opacity .15s, transform .15s .15s;
				transform: translateY(20px);
			}
			&__title {
				border-bottom: none;
				@include g.fz(24);
				margin-bottom: 20px;
				padding-bottom: 0;
				&-icon {
					width: 24px;
					height: 24px;
					margin-top: 1px;
					margin-left: 10px;
				}
			}
			&__item {
				&:not(:last-child) {
					margin-bottom: 0;
				}
			}
			&__group:only-child .gNavBlock {
				&__list {
					display: flex;
					flex-wrap: wrap;
					gap:0 32px;
				}
				&__item {
					width: calc(33.3333% - #{32px * math.div(2, 3)});
				}
			}
			&__group .gNavBlock {
				&__icon {
					position: absolute;
					top: 0;
					bottom: 0;
					left: auto;
					right: 0;
					width: 12px;
					height: 12px;
					margin: auto;
					&::after {
						content: "";
						position: absolute;
						background-repeat: no-repeat;
						background-size: contain;
						top: 0;
						left: 0;
						width: inherit;
						height: inherit;
					}
					&:not(.-blank) {
						&::after {
							background-image: url(#{g.$cmnPath}ico_arrow01.svg);
						}
						@at-root .gHeaderNav .gNavBlock__link:hover .gNavBlock__icon:not(.-blank) {
							&::after {
								animation: iconMove map-get(g.$iconMove, 'sp') map-get(g.$iconMove, 'easing') map-get(g.$iconMove, 'delay') 1 map-get(g.$iconMove, 'end');
							}
						}
					}
					&.-blank {
						top: 0;
						bottom: 0;
						right: 0;
						margin: auto;
					}
				}
				&__link {
					border-bottom: 1px solid nth(g.$partsColor, 2);
					display: block;
					padding: 19px 30px 20px 0;
					&::before {
						content: none;
					}
				}
			}
			&.-type02 .gNavBlock {
				&__item {
					&:not(:last-child) {
						margin-bottom: 0;
					}
				}
				&__child {
					padding: 20px 0 10px;
				}
			}
		}
		&__overlay {
			background-color: rgba(#212121, .25);
			opacity: 0;
			position: fixed;
			top: g.$headerHeight;
			bottom: 0;
			left: 0;
			right: 0;
			body[data-ready] & {
				transition: .5s;
			}
			z-index: 1;
		}//__overlay
	}

// 以下は別パーツ
	&__overlay {
		background-color: rgba(#212121, .25);
		position: fixed;
		top: g.$headerHeight;
		bottom: 0;
		left: 0;
		right: 0;
		cursor: pointer;
		transition: .5s;
		@include g.tabletP {
			top: g.$headerHeightSp;
		}
		body:not([data-nav__is_open = "true"]) & {
			opacity: 0;
			pointer-events: none;
			visibility: hidden;
		}
	}//__overlay
	&__toggle {
		background: none;
		border: none;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
		width: 70px;
		height: 70px;
		&::before {
			content: "";
			background-color: nth(g.$mainColor, 1);
			border-radius: 50%;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transition: background-color .5s, transform .2s cubic-bezier(0.2, 1, 0.8, 1);
		}
		@include g.tabletPmin {
			&:hover {
				&::before {
					transform: scale(1.1429);
				}
			}
		}
		@include g.tabletP {
			width: 56px;
			height: 56px;
		}
		&[aria-expanded = "true"] {
			&::before {
				background-color: #616161;
			}
		}
		&-barbox {
			position: relative;
			width: 24px;
			height: 11px;
			margin: 4px 0 7px;
			@include g.tabletP {
				width: 22px;
				height: 10px;
				margin: 3px 0 6px;
			}
		}
		&-bar {
			background-color: #fff;
			border-radius: 3px / 50%;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 3px;
			margin: auto;
			transition: transform .5s;
			&:nth-child(1) {
				transform: translateY(-3.5px);
				#{$_}__toggle[aria-expanded = "true"] & {
					transform: rotate(30deg);
				}
			}
			&:nth-child(2) {
				transform: translateY(3.5px);
				#{$_}__toggle[aria-expanded = "true"] & {
					transform: rotate(-30deg);
				}
			}
		}
		&-label {
			position: relative;
			color: #fff;
			@include g.fz(10);
			font-family: g.$fontAlpha;
			font-weight: bold;
			letter-spacing: 0.05em;
			line-height: 1;
			@include g.tabletP {
				transform: scale(.8);
			}
			#{$_}__toggle[aria-expanded = "true"] &.-open {
				display: none;
			}
			#{$_}__toggle[aria-expanded = "false"] &.-close {
				display: none;
			}
		}
	}//__toggle
}
