@charset "utf-8";
/*
#overview
modTable
*/

@use "global" as g;


/*
#styleguide
modTable01

-type02：SP時、タイトル～内容が縦方向に段落ちします
```
	<table class="modTable01">
		<tbody class="modTable01__body">
			<tr class="modTable01__tr">
				<th class="modTable01__th -year">1999年</th>
				<th class="modTable01__th -month">9月</th>
				<td class="modTable01__td">
					<p>テキストが入りますテキストが入りますテキストが入ります</p>
					<p>テキストが入りますテキストが入りますテキストが入ります</p>
				</td>
			</tr>
		</tbody>
	</table>

	<table class="modTable01 -type02">
		<tbody class="modTable01__body">
			<tr class="modTable01__tr">
				<th class="modTable01__th">タイトル</th>
				<td class="modTable01__td">
					<p>テキストが入りますテキストが入りますテキストが入ります</p>
				</td>
			</tr>
			<tr class="modTable01__tr">
				<th class="modTable01__th">タイトル</th>
				<td class="modTable01__td">
					<p>テキストが入りますテキストが入りますテキストが入ります</p>
				</td>
			</tr>
		</tbody>
	</table>
```
*/
.modTable01 {
	$_: &;
	width: 100%;
	&__tr {
		&:not(:last-child) {

		}
	}
	&__td,
	&__th {
		border-top: 1px solid nth(g.$partsColor, 2);
		border-bottom: 1px solid nth(g.$partsColor, 2);
		vertical-align: top;
		padding: 30px 0 30px;
		@include g.tabletP {
			padding-top: 20px;
			padding-bottom: 20px;
		}
	}
	&__th {
		color: #616161;
		font-weight: bold;
		width: 312px;
		padding-right: 30px;
		@include g.tabletP {
			width: 100px;
			padding-right: 20px;
		}
		&.-year {
			width: 208px;
			@include g.tabletP {
				white-space: nowrap;
				width: auto;
				padding-right: 10px;
			}
		}
		&.-month {
			width: 104px;
			@include g.tabletP {
				white-space: nowrap;
				width: auto;
				padding-right: 10px;
			}
		}
	}
	&__td {
		& > * {
			&:not(:last-child) {
				margin-bottom: 30px;
				@include g.tabletP {
					margin-bottom: 20px;
				}
			}
		}
	}
	&.-type02 {
		@include g.tabletP {
			display: block;
			thead, tbody, tfoot,
			tr, td, th {
				display: block;
			}
		}
		#{$_} {
			@include g.tabletP {
				&__tr {
					border-top: 1px solid nth(g.$partsColor, 2);
					padding-top: 20px;
					padding-bottom: 20px;
					&:last-child {
						border-bottom: 1px solid nth(g.$partsColor, 2);
					}
				}
				&__td,
				&__th {
					border-top: none;
					border-bottom: none;
					padding-top: 0;
					padding-bottom: 0;
				}
				&__th {
					width: auto;
					margin-bottom: 10px;
					padding-right: 0;
				}
			}
		}
	}
}//.modTable01

/*
#styleguide
xxx

```
```
*/
.xxx {
	$_: &;
}//.xxx