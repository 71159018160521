@charset "utf-8";

@use "_variables" as g;
@use 'sass:math';


@keyframes iconMove {//左右移動
	0% {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
	50.0% {
		transform: translate3d(60%, 0, 0);
		opacity: 0;
	}
	50.1% {
		transform: translate3d(-60%, 0, 0);
		opacity: 0;
	}
	100% {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}
