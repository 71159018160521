@charset "utf-8";

@use "global" as g;

#footer {}
.gFooter {
	$_: &;
	position: relative;
	z-index: 2;
    @include g.print{
        display: none;
    }
	body:not(#page_top) .gContents + & {
		&::before {
			content: "";
			background-color: #eee;
			display: block;
			border-top: 1px solid nth(g.$partsColor, 2);
			height: 4px;
		}
	}

	&__group01 {
		&-inner {
			padding: 60px 0 60px;
			@include g.tabletPmin {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				justify-content: space-between;
				min-height: 260px;
				gap: 30px 30px;
			}
		}
	}//__group01
	&__group02 {
		background-color: nth(g.$mainColor, 1);
		&-inner {
			display: flex;
			align-items: center;
			min-height: 70px;
			padding: 10px 0 10px;
			@include g.tabletP {
				justify-content: space-between;
				min-height: 60px;
			}
		}
	}//__group02
	&__logo {
		flex-shrink: 0;
		@include g.tabletP {
			width: 200px;
			margin-bottom: 40px;
			img {
				width: 100%;
			}
		}
	}//__logo
	&__list01 {
		$_2: &;
		display: flex;
		gap: 0 30px;
		margin-bottom: 20px;
		@include g.tabletP {
			flex-wrap: wrap;
			gap: 20px 0;
			margin-bottom: 40px;
		}
		&-item {
			font-weight: bold;
			@include g.tabletP {
				width: 100%;
			}
		}
		&-icon {
			display: block;
			position: relative;
			width: 20px;
			height: 20px;
			margin-left: 8px;
			background-color: nth(g.$mainColor, 1);
			border-radius: 50%;
			overflow: hidden;
			@include g.tabletP {
				width: 18px;
				height: 18px;
				margin-left: 6px;
			}
		}
		&-link {
			display: flex;
			align-items: center;
			&.-blank {
				#{$_2}-icon {
					&::after {
						content: "";
						background: url(#{g.$cmnPath}ico_circle_blank.svg) no-repeat center / cover;
						position: absolute;
						top: 0;
						left: 0;
						width: inherit;
						height: inherit;
						transition: map-get(g.$iconMove02, 'sp') map-get(g.$iconMove02, 'easing');
					}
				}
				@include g.tabletPmin {
					&:hover {
						#{$_2}-icon {
							&::after {
								transform: scale(-1, -1);
							}
						}
					}
				}
			}
		}
	}
	&__list02 {
		$_2: &;
		&-group {
			display: flex;
			gap: 0 20px;
			@include g.tabletP {
				flex-wrap: wrap;
				justify-content: space-between;
				gap: 20px 20px;
			}
			& + & {
				margin-top: 10px;
				@include g.tabletP {
					margin-top: 20px;
				}
			}
		}
		&-item {
			@include g.fz(12);
			@include g.tabletP {
				@include g.fz(10);
				width: calc(50% - 10px);
			}
		}
		&-icon {
			display: block;
			position: relative;
		}
		&-link {
			display: flex;
			align-items: center;
			&.-blank {
				#{$_2}-icon {
					width: 10px;
					height: 10px;
					margin-left: 5px;
					@include g.tabletP {
						width: 8px;
						height: 8px;
					}
					&::after {
						content: "";
						background: url(#{g.$cmnPath}ico_blank.svg) no-repeat center/ cover;
						position: absolute;
						top: 0;
						left: 0;
						width: inherit;
						height: inherit;
						transition: map-get(g.$iconMove02, 'sp') map-get(g.$iconMove02, 'easing');
					}
				}
				@include g.tabletPmin {
					&:hover {
						#{$_2}-icon {
							&::after {
								transform: scale(-1, -1);
							}
						}
					}
				}
			}
		}
	}//__list02
	&__snslist {
		display: flex;
		gap: 0 10px;
		&-link {
			display: block;
			@include g.tabletPmin {
				transition: .15s cubic-bezier(0.2, 1, 0.8, 1);
				&:hover {
					transform: scale(1.2);
				}
			}
		}
	}//__snslist
	&__copyright {
		color: #fff;
		@include g.fz(12);
		letter-spacing: 0.03em;
		margin-left: 30px;
	}//__copyright
}
