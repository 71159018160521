@charset "utf-8";

/*
pr
*/


@use "global" as g;

$_id:presskit;
$_imgPath:'../img/#{$_id}/';

body#page_#{$_id} {

	.myPresskit{
		& a{
			text-decoration: underline;
		}
		& .modTitle01 br{
			display: inline-block;
			@include g.tabletL {
				display: none;
			}
		}
		& .modTxtBlock01{
			@include g.tabletL {
				margin-bottom: 30px;
			}
		}
		&__guideline{
			border: 1px solid #e0e0e0;
			margin-bottom: 80px;
			@include g.tabletL {
				margin-bottom: 60px;
			}
			&-main{
				height: 380px;
				overflow-y: scroll;
				padding: 40px;
				@include g.tabletL {
					padding: 20px 16px;
				}
			}
			&-title{
				font-weight: 900;
				margin-bottom: 1.4em;
			}
			&-item{
				margin-bottom: 1.4em;
				list-style: decimal;
				margin-left: 1.2em;
				&:last-of-type{
					margin-bottom: 0;
				}
			}
			&-agreement{
				background-color: #F5F2F2;
				border-top: 1px solid #e0e0e0;
				padding: 40px;
				text-align: center;
				@include g.tabletL {
					padding: 16px;
				}
				&-label{
					display: flex;
					font-weight: 900;
					margin-bottom: 12px;
					justify-content: center;
					height: 24px;
					line-height: 24px;
				}
				&-checkbox{
					width: 24px;
					height: 24px;
					margin-right: 10px;
					appearance: none;
					border-radius: 4px;
					border: 1px solid #e0e0e0;
					background-color: white;
					cursor: pointer;
					&:checked{
						border: none;
						background-color: red;
						background-image: url(../img/pr/presskit/ico_check.svg);
					}
				}
				&-note{
					font-size: 12px;
				}
			}
		}
		&__download{
			&-wrap{
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				@include g.tabletL {
					display: block;
				}
			}
			&-item{
				width: calc((100% - 32px)/ 2);
				margin-bottom: 80px;
				&:last-of-type{
					margin-bottom: 0;
				}
				@include g.tabletL {
					width: 100%;
					max-width: 600px;
					margin: 0 auto 48px;
				}
			}
			&-title{
				font-size: 24px;
				font-weight: 900;
				margin-bottom: 20px;
			}
			&-text{
				margin-bottom: 20px;
			}
			&-figure{
				border: 1px solid #ddd;
				border-radius: 5px;
				overflow: hidden;
				margin-bottom: 32px;
				& a{
					pointer-events: none;
					& img{
						transition: transform .25s ease-in-out;
					}
					&:hover img{
						transform: scale(1.06) rotate(.001deg);
					}
				}
			}
			&-btn a{
				pointer-events: none;
				text-decoration: none;
				width: 280px;
                height: 64px;
				line-height: 64px;
				padding: 0 25px;
				margin: auto;
				background-color: #BDBDBD;
				border-color: #BDBDBD;
				& .icon{
					width: 26px;
					height: 26px;
					display: block;
					position: absolute;
					right: 30px;
					top: calc(50% - 13px);
					fill: #ffffff;
				}
				@include g.tabletPmin{
					&:hover .icon{
						fill: #da3734;
						&-path01{
							animation: iconMove-download01 .25s cubic-bezier(.215,.61,.355,1) .25s 1 forwards;
							opacity: 0;
						}
						&-path02{
							animation: iconMove-download02 .25s cubic-bezier(.215,.61,.355,1) .25s 1 forwards;
							opacity: 0;
						}
					}
				}
			}
		}
	}
	@keyframes iconMove-download01 {
		0%{
			opacity: 0;
			transform: translateY(-10px);
		}
		100%{
			opacity: 1;
			transform: translateY(0);
		}
	}
	@keyframes iconMove-download02 {
		0%{
			opacity: 0;
		}
		100%{
			opacity: 1;
		}
	}
	.myPresskit.agree{
		& .myPresskit__download-figure a{
			pointer-events: auto;
		}
		& .myPresskit__download-btn a{
			pointer-events: auto;
			background-color: #da3734;
			border-color: #da3734;
		}
	}
	//.myPresskit
}
