@charset "utf-8";

/*
inquiry_index
*/


@use "global" as g;
@use "sass:math";

$_id_parent:inquiry;
$_id:index;
$_imgPath:'../img/#{$_id_parent}/#{$_id}/';

body#page_#{$_id_parent}.-#{$_id} {


	.myBlockIndex{
		margin-bottom: 120px;
		@include g.tabletP{
			margin-bottom: 80px;
		}
	}//.myBlockIndex

	.myListLink{
		$_:'.myListLink';

		margin-bottom: 50px;

		@include g.tabletPmin{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		@include g.tabletP{
			margin-bottom: 40px;
		}

		&__item{
			@include g.tabletPmin{
				width: calc(50% - 5px);
				margin-top: 10px;
				&:nth-child(-n + 2){
					margin-top: 0;
				}
			}

			@include g.tabletP{
				&:not(:last-child){
					margin-bottom: 10px;
				}
			}

		}

		&__link{
			display: block;
			position: relative;
			border-radius: 5px;
			padding: 24px 54px 28px 32px;
			background-color: nth(g.$partsColor,1);
			transition:background-color 0.15s g.$easeOut;
			height: 100%;
			text-decoration: none;
			font-weight: bold;

			@include g.tabletPmin{
				&:hover{
					opacity: 1;
					background-color: nth(g.$mainColor,1);
					color:#fff;
					#{$_}__link-icon{
						background-color: #fff;
						&::after{
							background-image: url(#{g.$cmnPath}ico_circle_arrow_color02.svg);
							animation: iconMove map-get(g.$iconMove, 'sp') map-get(g.$iconMove, 'easing') map-get(g.$iconMove, 'delay') 1 map-get(g.$iconMove, 'end');
						}
					}
				}
			}

			@include g.tabletP{
				padding: 18px 45px 21px 20px;
			}

			&-icon{
				position: absolute;
				right: 30px;
				inset-block:0;
				margin-block:auto;
				width: 20px;
				height: 20px;
				background-color: nth(g.$mainColor, 1);
				border-radius: 50%;
				transition: inherit;
				overflow: hidden;
				@include g.tabletP {
					right: 20px;
				}

				&::after {
					content: "";
					display: block;
					position: absolute;
					top: 0;
					background-position: 0 0;
					background-repeat: no-repeat;
					background-image: url(#{g.$cmnPath}ico_circle_arrow.svg);
					background-size: 20px 20px;
					width: 20px;
					height: 20px;

					@include g.tabletP {
					}
				}

			}

		}

	}//.myListLink










}
