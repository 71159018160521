@charset "utf-8";
@use "global" as g;


/* link
------------------------------------------------------------*/
a {
	color: g.$baseLinkColor;
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
}
a,button{
	transition: color .08s ease-out;
	@include g.tabletPmin {
		&:hover {
			color: nth(g.$mainColor, 1);
		}
	}
}

/* Fonts
------------------------------------------------------------*/
body{
	color: g.$baseColor;
	@include g.fz;
	font-family:g.$baseFontFamily;
	line-height:g.$baseLineHeight;
	overflow-wrap : break-word;
	-webkit-text-size-adjust: 100%;
	word-wrap : break-word;
	@include g.tabletP {
		@include g.fz(14);
	}
	//IE11以下は別のフォントを指定する場合
	// &.IE {
	// 	font-family: "メイリオ",Verdana, "Hiragino Kaku Gothic ProN",  Meiryo, sans-serif;
	// }
}

input,button,textarea,select{
	color: g.$baseColor;
	font-family:g.$baseFontFamily;
	line-height:g.$baseLineHeight;
	font-size: 100%;
	//IE11以下は別のフォントを指定する場合
	// .IE &{
	// 	font-family: "メイリオ",Verdana, "Hiragino Kaku Gothic ProN",  Meiryo, sans-serif;
	// }
}

.container {
	margin-left: auto;
	margin-right: auto;
	max-width:g.$container;
	padding-left:  #{g.$containerPadding}px;
	padding-right: #{g.$containerPadding}px;
	@include g.tabletP {
		padding-left:  #{g.$containerPaddingSp}px;
		padding-right: #{g.$containerPaddingSp}px;
	}

	&.-small{
		max-width: g.$container-sm;
	}
}
.modPcOnly {
	@include g.tabletP {
		display:none;
	}
}

.modSpOnly {
	@include g.tabletPmin {
		display:none;
	}
}

br{
	&.pc{
		@include g.tabletP{
			display: none;
		}
	}
	&.sp{
		@include g.tabletPmin{
			display: none;
		}
	}
}


[role="tabpanel"] {
	&[aria-hidden="true"] {
		display: none;
	}
	&[aria-hidden="false"] {
		display: block;
	}
}

[data-select__contents]{
	&[aria-hidden="true"] {
		display: none;
	}
	&[aria-hidden="false"] {
		display: block;
	}
}


a[href*="tel:"] {
	@include g.sphonePmin{
		pointer-events: none;
	}
}

.section {

	& + #{&}{
		margin-top: 120px;
		@include g.tabletP{
			margin-top: 80px;
		}
	}

}

:root {
  color-scheme: light;
}
