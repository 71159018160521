@charset "utf-8";
/*
#overview
modForm
*/

@use "global" as g;

/*
#styleguide
modFormBlock
@-input
@-conf

```
<dl class="modFormBlock -input">
	<dt class="modFormBlock__title">お名前（漢字）<span class="modFormBlock__title-required">必須</span></dt>
	<dd class="modFormBlock__data">
		<div class="modFormName">
			<div class="modFormName__item">
				<input type="text" placeholder="姓">
			</div>
			<div class="modFormName__item">
				<input type="text" placeholder="名">
			</div>
		</div>
	</dd>
</dl>

<dl class="modFormBlock -conf">
	<dt class="modFormBlock__title">お名前（漢字）</dt>
	<dd class="modFormBlock__data">
		出前　太郎
	</dd>
</dl>

```
*/

.modFormBlock{
	$_:&;
	margin-bottom: 60px;
	@include g.tabletP{
		margin-bottom: 40px;
	}

	&.-conf{
		border-bottom: solid 1px #ddd;

		@include g.sphonePmin{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}


		#{$_}__title,
		#{$_}__data{
			@include g.sphonePmin{
				padding-block:30px;
				border-top: solid 1px #ddd;
			}
		}
	}


	&__title{
		font-weight: 700;
		@include g.sphoneP{

		}

		&-required{
			display: inline-block;
			border-radius: 999999px;
			border:solid 1px nth(g.$mainColor,1);
			padding: 1px 9px;
			margin-left: 10px;
			color:nth(g.$mainColor,1);
			@include g.fz(12);
			@include g.sphoneP{
				@include g.fz(10);
			}
		}
	}


	&.-input{
		#{$_}__title{
			margin-bottom: 15px;
			@include g.sphoneP{
				margin-bottom: 10px;
			}
		}
	}

	&.-conf{
		#{$_}__title{
			@include g.sphonePmin{
				width: 12.5em;
			}
			@include g.sphoneP{
				border-top: solid 1px #ddd;
				padding-top: 20px;
				margin-bottom: 10px;

			}
		}
	}

	&__data{

	}


	&.-input{
		#{$_}__data{
			&:not(:last-child){
				margin-bottom: 40px;
				@include g.sphoneP{
					margin-bottom: 30px;
				}
			}
		}
	}

	&.-conf{
		#{$_}__data{
			@include g.sphonePmin{
				width: calc(100% - 12.5em);
				padding-left: 10px;

			}
			@include g.sphoneP{
				padding-bottom: 20px;
			}
		}
	}

}//.modFormBlock


/*
#styleguide
modFormName
```
<div class="modFormName">
	<div class="modFormName__item">
		<input type="text" placeholder="姓">
	</div>
	<div class="modFormName__item">
		<input type="text" placeholder="名">
	</div>
</div>
```
*/

.modFormName{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	&__item{
		width: calc((100% - 32px)/2);
		@include g.sphoneP{
			width: calc((100% - 19px)/2);

		}
	}

}//.modFormName

/*
#styleguide
modFormTxtError
```
<p class="modFormTxtError">お名前（メイ）を入力してください</p>
```
*/
.modFormTxtError{
	margin-top: 5px;
	color: map-get( map-get(g.$formColor, 'error'), color );
	font-weight: bold;
	@include g.fz(12);
	@include g.sphoneP{
		@include g.fz(10);
	}
}//.modFormTxtError


/*
#styleguide
modFormTxtLead
modFormTxtFoot

margin-bottom違い
```
	<p class="modFormTxtLead">こちらのフォームは「料理の配達を請け負っていただけるパートナー企業様」向けのお問い合わせフォームです。「出前館への出店をご希望の店舗様」は「<a href="#">飲食店の出前館出店について</a>」をご利用ください。</p>
	<p class="modFormTxtFoot">こちらのフォームは「料理の配達を請け負っていただけるパートナー企業様」向けのお問い合わせフォームです。「出前館への出店をご希望の店舗様」は「<a href="#">飲食店の出前館出店について</a>」をご利用ください。</p>
```
*/
.modFormTxtFoot,
.modFormTxtLead{

	a{
		color:nth(g.$mainColor,1);
		text-decoration: underline;
		&:hover{
		  text-decoration: none;
		}
	}
}//.modFormTxtLead


.modFormTxtLead{
	margin-bottom: 80px;
	@include g.sphoneP{
		margin-bottom: 60px;
	}
}//.modFormTxtLead

.modFormTxtFoot{
	margin-bottom: 60px;
	@include g.tabletP{
		margin-bottom: 40px;
	}
}//.modFormTxtFoot

/*
#styleguide
modFormTxtComp
```
	<p class="modFormTxtComp">お問い合わせいただき、ありがとうございます。<br>この文章はダミーテキストです。この文章はダミーテキストです。この文章はダミーテキストです。この文章はダミーテキストです。この文章はダミーテキストです。この文章はダミーテキストです。</p>
```
*/
.modFormTxtComp{
	margin-bottom: 60px;
	@include g.sphoneP{
		margin-bottom: 40px;
	}
}//.modFormTxtComp

/*
#styleguide
modFormTxtSub
```
	<p class="modFormTxtSub">（車両NO.車体の特徴等　わかる範囲でお願い致します）</p>
```
*/
.modFormTxtSub{
	margin-bottom: 5px;
	color:#616161;

	@include g.tabletP{
		margin-top: -5px;
		margin-bottom: 10px;
	}

}//.modFormTxtSub

/*
#styleguide
modFormListBtn
@-column01
@-column02
```
<div class="modFormListBtn -column01">
	<button class="modBtn01 modFormListBtn__item">確認する<span class="modBtn01__icon -arrowR"></span></button>
</div>
<div class="modFormListBtn -column02">
	<a href="#" class="modBtn01 -color02 modFormListBtn__item">戻る<span class="modBtn01__icon -arrowL"></span></a>
	<button class="modBtn01 modFormListBtn__item -submit">この内容で送信する<span class="modBtn01__icon -arrowR"></span></button>
</div>
```
*/
.modFormListBtn{
	margin-top: 60px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	@include g.sphoneP{
		margin-top: 40px;
	}

	&__item{
		width: calc((100% - 32px)/2);

		&:only-child{
			width: 100%;
			max-width: 592px;
			margin-inline:auto;
		}

		@include g.sphoneP{
			width: 100%;
			order:100;
			margin-bottom: 10px;

			&.-submit{
				order:1;
			}
		}
	}


}//.modFormListBtn

/*
#styleguide
modFormListCheck
```
<ul class="modFormListCheck">
	<li class="modFormListCheck__item">
		<label class="modFormRadio"><input type="radio" class="modFormRadio__input" name="content"><span class="modFormRadio__label">資料が欲しい</span></label>
	</li>
	<li class="modFormListCheck__item">
		<label class="modFormRadio"><input type="radio" class="modFormRadio__input" name="content"><span class="modFormRadio__label">話が聞きたい</span></label>
	</li>
</ul>
<ul class="modFormListCheck -horizontal">
	<li class="modFormListCheck__item">
		<label class="modFormRadio"><input type="radio" class="modFormRadio__input" name="content"><span class="modFormRadio__label">資料が欲しい</span></label>
	</li>
	<li class="modFormListCheck__item">
		<label class="modFormRadio"><input type="radio" class="modFormRadio__input" name="content"><span class="modFormRadio__label">話が聞きたい</span></label>
	</li>
</ul>
```
*/
.modFormListCheck{
	$_:&;

	&.-horizontal{
		display: flex;
		flex-wrap: wrap;

		#{$_}__item{
			margin-right: 32px;
			@include g.tabletP{
				margin-right: 40px;
			}
		}
	}

}//.modFormListCheck




/*
#styleguide
modFormSelect
```
<div class="modFormSelect">
	<select name="search" class="modFormSelect__input">
		<option>年を選択</option>
		<option>2022年</option>
		<option>2021年</option>
	</select>
	<span class="modFormSelect__icon"></span>
</div>
<div class="modFormSelect -middle">
	<select name="search" class="modFormSelect__input">
		<option>年を選択</option>
		<option>2022年</option>
		<option>2021年</option>
	</select>
	<span class="modFormSelect__icon"></span>
</div>
<div class="modFormSelect -color02">
	<select name="search" class="modFormSelect__input">
		<option>年を選択</option>
		<option>2022年</option>
		<option>2021年</option>
	</select>
	<span class="modFormSelect__icon"></span>
</div>
```
*/
.modFormSelect {
	$_: &;
	position: relative;
	&.-middle{
		max-width: 384px;
	}

	&.-color02{
		#{$_}__input{
			color:map-get(g.$formColor, 'placeholder');
		}
	}

	&__icon {
		background: url(#{g.$cmnPath}ico_arrow02.svg) no-repeat center / contain;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 17px;
		width: 7px;
		height: 12px;
		margin: auto;
		pointer-events: none;
		transform: rotate(90deg);
		@include g.tabletP {
			width: 5.83px;
			height: 10px;
		}
	}
}//.modFormSelect
