@charset "utf-8";

/*
ir_information
*/


@use "global" as g;
@use 'sass:math';

$_id:ir_information;
$_imgPath:'../img/#{$_id}/';

body#page_#{$_id} {
	// ■e-ir対応
	.eir {
		.s_eirModule_category {
			display: flex;
			bottom: auto;
		}
	}
  &.-policy.-index {
    .modListIndex01__list {
      @include g.tabletPmin {
        &-item:nth-child(n+3) {
          width: calc((100% - 32px * 2) / 3);

          .modListIndex01__link {
            &-title {
              @include g.fz(20);
            }

            &-icon {
              margin-left: 8px;
              width: 20px;
              height: 20px;

              &:after {
                width: 20px;
                height: 20px;
                background-size: 20px 20px;
              }
            }
          }
        }
      }
    }
  }
  &.-esg {
    @include g.tabletP {
      .section:nth-child(-n+2) {
        margin-top: 0;
      }
      .modTitle01 {
        @include g.fz(24.5);
      }
    }
    .mySectionInfo {
      @include g.tabletP{
        display: none;
      }
    }
    .myBlockEsg {
      &__def {
        &-inner {
          padding-bottom: 39px;
          @include g.tabletP{
            padding-bottom: 30px;
          }
          &:last-of-type{
            padding-bottom: 0;
            @include g.tabletP {
              .myBlockEsg__defTxt {
                @include g.fz(16);
              }
            }
          }
        }
        &Ttl {
          @include g.fz(28);
          font-weight: 900;
          line-height: math.div(48,32);
          letter-spacing: 0.03em;
          margin-bottom: 20px;
          @include g.tabletP {
            @include g.fz(21);
            line-height: math.div(37, 25);
            letter-spacing: normal;
          }
          &-cat {
            display: block;
            font-family: g.$fontAlpha;
            @include g.fz(16);
            color: nth(g.$mainColor, 1);
            font-weight: 700;
            line-height: math.div(28,16);
            letter-spacing: 0.03em;
            margin-bottom: 6px;
            @include g.tabletP {
              @include g.fz(14);
              line-height: math.div(25, 14);
            }
          }
        }
        &Txt {
          line-height: math.div(28,16);
          @include g.tabletP{
            line-height: math.div(25,14);
          }
        }
      }
    }
    .myBlockSdgs {
      &__def {
        margin-top: 60px;
        @include g.tabletP{
          margin-top: 40px;
        }
        &-inner {
          border-bottom: 1px solid #DDD;
          padding-top: 40px;
          padding-bottom: 80px;
          @include g.tabletP{
            padding-top: 30px;
            padding-bottom: 60px;
          }
          &:first-of-type {
            border-top: 1px solid #DDD;
          }
          &:last-of-type{
            padding-bottom: 0;
            border-bottom: none;
            @include g.tabletP{
              margin-bottom: 30px;
            }
          }
        }
        &Ttl {
          @include g.fz(28);
          font-weight: 900;
          line-height: math.div(48,32);
          letter-spacing: 0.03em;
          margin-bottom: 20px;
          @include g.tabletP {
            @include g.fz(21);
            line-height: math.div(37, 25);
          }
        }
        &Tag {
          display: flex;
          align-items: center;
          gap: 0 10px;
          margin-bottom: 10px;
          &-item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 81px;
            height: 27px;
            border-radius: 30px;
            border: 1px solid nth(g.$mainColor, 1);
            @include g.tabletP {
              width: 77px;
              height: 24px;
            }
            span {
              position: relative;
              padding-right: 17px;
              font-family: g.$baseFontFamily;
              @include g.fz(12);
              color: nth(g.$mainColor, 1);
              font-weight: 700;
              line-height: math.div(21,12);
              letter-spacing: 0.03em;
              @include g.tabletP {
                @include g.fz(10);
                line-height: math.div(25, 14);
              }
              &:after {
                position: absolute;
                content: "";
                top: 48%;
                right: 0;
                display: block;
                width: 13px;
                height: 13px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                transform: translateY(-50%);
              }
            }
            &.-environment {
              span {
                &:after {
                  background-image: url(#{$_imgPath}policy/esg/sdgs/icon/environment.svg);
                }
              }
            }
            &.-social {
              span {
                &:after {
                  background-image: url(#{$_imgPath}policy/esg/sdgs/icon/social.svg);
                }
              }
            }
          }
        }
        &Txt {
          margin-bottom: 30px;
          line-height: math.div(28, 16);
          @include g.tabletP {
            line-height: math.div(25, 14);
          }
        }
        &Image {
          display: flex;
          gap: 0 32px;
          @include g.tabletP {
            gap: 0 20px;
          }
          picture {
            width: 106px;
            height: 106px;
            @include g.tabletP {
              width: 98px;
              height: 98px;
            }
          }
        }
      }
    }
    .myBlockEsgNewsList {
      $_:'.myBlockEsgNewsList';
      padding-top: 10px;
      @include g.sphonePmin{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 20px;
      }
      &__item{
        @include g.sphonePmin{
          width: calc((100% - 32px*1)/2);
          margin-top: 60px;
          &:nth-child(-n + 2){
            margin-top: 0;
          }
        }

        @include g.sphoneP{
          &:not(:last-child){
            margin-bottom: 40px;
          }
        }
        &-date {
          flex-shrink: 0;
          color: #616161;
          @include g.tabletPmin {
            margin-right: 15px;
          }
          @include g.tabletP {
            min-width: 82px;
            margin-right: 15px;
          }
        }
        &-category {
          vertical-align: bottom;
          @include g.tabletPmin {
            padding-bottom: 5px;
          }
          @include g.tabletP {
            padding-right: 26px;
            padding-bottom: 4px;
          }
        }
        &-txt {
          display: block;
          flex-basis: 100%;
          position: relative;
          margin-top: 4px;
          padding-right: 10px;
          line-height: math.div(28, 16);
          @include g.tabletP {
            margin-top: 7px;
            padding-right: 8px;
          }
        }
        &-icon {
          display: inline-flex;
          flex-shrink: 0;
          position: relative;
          margin-left: 10px;
          width: 20px;
          height: 20px;
          background-color: nth(g.$mainColor, 1);
          border-radius: 50%;
          overflow: hidden;
          vertical-align: -0.125em;
          @include g.tabletP {
            margin-left: 6px;
            vertical-align: -0.3em;
          }
          #{$_}__item-txt & {
            position: absolute;
            top: 4px;
            bottom: 0;
            right: -30px;
            @include g.tabletP {
              top: 2px;
              right: -40px;
            }
          }
          &::after {
            content: "";
            background: url(#{g.$cmnPath}ico_circle_arrow.svg) no-repeat center / 20px;
            display: block;
            width: 20px;
            height: 20px;
          }
          #{$_}__item-inner.-blank & {
            &::after {
              background-image: url(#{g.$cmnPath}ico_circle_blank.svg);
              transition: map-get(g.$iconMove02, 'sp') map-get(g.$iconMove02, 'easing');
            }
          }
          #{$_}__item-inner.-pdf & {
            &::after {
              background-image: url(#{g.$cmnPath}ico_circle_pdf.svg);
            }
          }
        }
      }

      &__link {
        display: block;
        $_:'.myBlockEsgNewsList__item';

        @include g.tabletPmin{
          &:hover{
            opacity: 1;

            #{$_}-txt{
              color:nth(g.$mainColor,1);
            }

            #{$_}-icon{
              &::after {
                animation: iconMove map-get(g.$iconMove, 'sp') map-get(g.$iconMove, 'easing') map-get(g.$iconMove, 'delay') 1 map-get(g.$iconMove, 'end');
              }
              &.-arrowR{
                &::after{
                  animation: iconMove map-get(g.$iconMove, 'sp') map-get(g.$iconMove, 'easing') map-get(g.$iconMove, 'delay') 1 map-get(g.$iconMove, 'end');
                }
              }
              &.-blank{
                &::after{
                  transform: scale(-1, -1);
                }
              }
            }

          }
        }
        &-image{
          margin-bottom: 20px;
          border-radius: 5px;
          overflow: hidden;
          text-align: center;
          border: 1px solid nth(g.$partsColor, 2);
          @include g.tabletP{
            margin-bottom: 15px;
            aspect-ratio: 335 / 209.38;
          }

          img{
            width: 100%;
          }

          &.-border {
            border: 1px solid #DDD;
            border-radius: 5px;
          }

        }
      }

    }

    .myBlockEsgData {
      .modListNews__item-date {
        margin-right: 30px;
      }
      .modBlockPdf__adobe-bnr {
        @include g.tabletPmin {
          width: 22.5%;
        }
      }
      .modBlockPdf {
        @include g.tabletPmin {
          margin-top: 81px;
        }
      }
      .modBlockPdf__adobe {
        margin-bottom: 0;
      }
      .modBlockPdf__adobe-detail {
        @include g.tabletPmin {
          padding-left: 28px;
        }
      }
    }
  }

}
