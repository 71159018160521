@charset "utf-8";

@use "global" as g;
@use "sass:math";

#header {}
.gHeader {
	$_: &;
	background-color: #fff;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 5;
	transition: box-shadow .3s;
    @include g.print{
        display: none;
    }
	&.-moved {
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
	}

	&__container {
		@include g.tabletPmin {
			max-width: none;
			padding: 0 30px;
		}
	}//__container
	&__inner {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		height: g.$headerHeight;
		gap: 0 30px;
		@include g.tabletP {
			height: g.$headerHeightSp;
			margin-right: -10px;
		}
	}//__inner
	&__logo {
		width: 150px;
		margin: 0 auto 0 0;
		@include g.tabletPmin {
			margin-top: 4px;
		}
		@include g.tabletP {
			width: 120px;
		}
		img {
			width: 100%;
		}
	}//__logo
	&__list01 {
		display: flex;
		height: 100%;
		transition: opacity .5s, visibility .5s;
		@include g.max(1199) {
			display: none;
		}
		body[data-nav__is_open = "true"] & {
			opacity: 0;
			pointer-events: none;
			visibility: hidden;
		}
		&-item {
			position: relative;
			font-weight: bold;
			&Inner {
				position: relative;
				height: 100%;
				padding-bottom: 2px;
				z-index: 2;
			}
		}
		&-icon {
			position: relative;
			width: 12px;
			height: 12px;
			margin-left: 8px;
			&.-blank {
				&::after {
					content: "";
					background: url(#{g.$cmnPath}ico_blank.svg) no-repeat center / cover;
					position: absolute;
					top: 0;
					left: 0;
					width: inherit;
					height: inherit;
					transition: transform map-get(g.$iconMove02, 'sp') map-get(g.$iconMove02, 'easing');
				}
			}
		}
		&-link {
			display: flex;
			align-items: center;
			position: relative;
			height: 100%;
			padding: 0 15px;
			&::before {
				content: "";
				border-top: 5px solid nth(g.$mainColor, 1);
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				border-radius: 3px;
				opacity: 0;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				width: calc(100% - 15px * 2);
				height: 0;
				margin: calc(#{g.$baseLineHeight}em * 2 + 9px) auto 0;
				pointer-events: none;
				transition: opacity .08s ease-out;
			}
			$_hover_list: "";
			@each $_var in (company, business, pr, ir_information, inquiry) {
				$_hover_list: $_hover_list + "#page_#{$_var} &.-#{$_var},";
			}
			@include g.tabletPmin {
				#{$_hover_list} #{$_}__list01-itemInner:hover &,
				#{$_hover_list} &:hover {
					transition: color .08s ease-out;
					&::before {
						opacity: 1;
					}
					#{$_}__list01-icon.-blank {
						&::after {
							transform: scale(-1, -1);
						}
					}
				}
			}
		}
	}//__list01
	&__list02 {
		display: flex;
		transition: opacity .5s, visibility .5s;
		@include g.tabletPmin {
			gap: 0 30px;
			margin-left: -15px;
		}
		@include g.max(1199) {
			display: none;
		}
		@include g.tabletP {
			flex-wrap: wrap;
			justify-content: space-between;
		}
		body[data-nav__is_open = "true"] & {
			opacity: 0;
			pointer-events: none;
			visibility: hidden;
		}
		&-item {
			font-weight: bold;
			@include g.tabletPmin {
				color: nth(g.$mainColor, 1);
			}
			@include g.tabletP {
				color: #fff;
			}
		}
		&-link {
			display: flex;
			align-items: center;
			position: relative;
			color: inherit;
			&::before{
				content: "";
				display: block;
				position: absolute;
				z-index: -1;
				top: -2px;
				right: -2px;
				bottom: -2px;
				left: -2px;
				margin: auto;
				border-radius: 999999px;
				opacity: 0;
				transition: opacity 0.35s cubic-bezier(0.23, 1, 0.58, 1), transform 0.5s cubic-bezier(0.23, 1, 0.58, 1);
				transform: scale(0);
				background-color: nth(g.$mainColor, 1);
				will-change: transform;
			}
			@include g.tabletPmin {
				border: 2px solid nth(g.$mainColor, 1);
				border-radius: 30px;
				padding: 7px 29px 9px;
				&:hover {
					transition: background-color 0.1s 0.15s cubic-bezier(0.23, 1, 0.58, 1);
					opacity: 1;
					color: #fff;
					&::before{
						opacity: 1;
						transition: all 0.3s cubic-bezier(0.23, 1, 0.58, 1);
						transform: scale(1);
					}
					#{$_}__list02-icon {
						&.-blank{
							background-image: url(#{g.$cmnPath}ico_blank02.svg);
							transform: scale(-1, -1);
						}
					}
				}
			}
			@include g.tabletP {
				background-color: nth(g.$mainColor, 1);
				border-radius: 40px;
				@include g.fz(16);
				padding: 25px 30px 27px;
			}
		}
		&-icon {
			&.-blank {
				background: url(#{g.$cmnPath}ico_blank.svg) no-repeat center / cover;
				width: 12px;
				height: 12px;
				transition: transform map-get(g.$iconMove02, 'sp') map-get(g.$iconMove02, 'easing');
				@include g.tabletPmin {
					margin: 0 -12px 0 8px;
				}
			}
		}
	}//__list02
	&__list03 {
		display: flex;
		position: absolute;
		right: 30px;
		margin-right: #{70px + 30px};
		transition: opacity .5s, visibility .5s;
		@include g.tabletP {
			right: 10px;
			margin-right: #{56px + 20px};
		}
		body:not([data-nav__is_open = "true"]) &:not(.-show) {
			opacity: 0;
			pointer-events: none;
			visibility: hidden;
		}


		&-item {
			position: relative;
			font-weight: bold;
			font-family: g.$fontAlpha;
			&:not(:last-child) {
				margin-right: 25px;
				&::after {
					content: "";
					background-color: nth(g.$partsColor, 2);
					position: absolute;
					top: 0;
					bottom: 0;
					right: -13px;
					width: 1px;
					height: 16px;
					margin: auto;
				}
			}
		}
		&-link {
			display: block;
			align-items: center;
			position: relative;
			&[href] {
				font-weight: 400;
				@include g.tabletPmin {
					&:not(:hover) {
						color: #616161;
					}
				}
			}
		}
	}//__list03



	body#page_top &{
		transition:background-color 0.3s ease;

		@keyframes topHeaderMoved {
			0% {
				transform: translateY(-100%);
				opacity: 0
			}

			to {
				transform: translateY(0);
				opacity: 1
			}
		}

		#{$_}__inner {
			transition:height 0.3s ease;
		}

		&.-moved{
			animation: topHeaderMoved .6s cubic-bezier(.23,1,.32,1) 0s;
		}

		&:not(.-moved){
			background-color: transparent;

			@media (any-hover:hover) {
				&:hover{
					background-color: #fff;
				}
			}

			&.-open{
				background-color: #fff;
			}

			#{$_}__inner {
				@include g.tabletPmin{
					height: g.$headerHeightTop;
				}
			}//__inner

			#{$_}__logo{
				@include g.tabletPmin{
					width: 180px;
				}
			}

			#{$_}__list01-link{
				&::before{

					@include g.tabletPmin{
						margin-top: calc(#{g.$baseLineHeight}em * 2 + 24px);
					}
				}
			}

			.gHeaderNav__overlay,
			.gHeaderNav__outer,
			.gNav,
			.gNav__overlay{
				@include g.tabletPmin{
					top: g.$headerHeightTop;
				}
			}

		}

		//hover効かない用
		@at-root body#page_top[data-nav__is_open="true"] .gHeader:not(.-moved){
			background-color: #fff;
		}

		@at-root body#page_top:not([data-nav__is_open="true"]) .gHeader:not(.-moved){
			@media (any-hover:none) {
				#{$_}__logo{
					@include g.tabletPmin{
					}
					img{
						filter: brightness(0) invert(1);//白
					}
				}

				#{$_}__list01-link{
					color:#fff;
					&::before{
						border-top-color:#fff;
					}
				}

				#{$_}__list02-link{
					border-color:#fff;
					color:#fff;
					&::before{
						background-color: #fff;
					}
					@include g.tabletPmin{
						&:hover{
							color:nth(g.$mainColor,1);
							#{$_}__list02-icon.-blank{
								background-image: url(#{g.$cmnPath}ico_blank.svg);
								filter:none;
							}

						}
					}
				}

				#{$_}__list01-icon.-blank,
				#{$_}__list02-icon.-blank{
					filter: brightness(0) invert(1);//白
				}

				#{$_}__list03-link{
					color:#fff;
				}


				.gNav__toggle{
					&::before{
						background-color: #fff;
					}

					.gNav__toggle-bar{
						background-color: nth(g.$mainColor,1);
					}

					.gNav__toggle-label{
						color:nth(g.$mainColor,1);
					}

				}
			}
		}

		//hover効く用
		&:not(.-moved):not(:hover):not(.-open):not(.-menuOpen){

			@media (any-hover:hover) {
				#{$_}__logo{
					@include g.tabletPmin{
					}
					img{
						filter: brightness(0) invert(1);//白
					}
				}

				#{$_}__list01-link{
					color:#fff;
					&::before{
						border-top-color:#fff;
					}
				}

				#{$_}__list02-link{
					border-color:#fff;
					color:#fff;
					&::before{
						background-color: #fff;
					}
					@include g.tabletPmin{
						&:hover{
							color:nth(g.$mainColor,1);
							#{$_}__list02-icon.-blank{
								background-image: url(#{g.$cmnPath}ico_blank.svg);
								filter:none;
							}

						}
					}
				}

				#{$_}__list01-icon.-blank,
				#{$_}__list02-icon.-blank{
					filter: brightness(0) invert(1);//白
				}

				#{$_}__list03-link{
					color:#fff;
				}


				.gNav__toggle{
					&::before{
						background-color: #fff;
					}

					.gNav__toggle-bar{
						background-color: nth(g.$mainColor,1);
					}

					.gNav__toggle-label{
						color:nth(g.$mainColor,1);
					}

				}

			}
		}
	}//page_top

	body#page_en &{
		&__list03{
			right: 30px;
			margin-right: 0;
			@include g.tabletP{
				right: 20px;
			}
		}
	}




}
