@charset "utf-8";

/*
company
*/


@use "global" as g;
@use 'sass:math';

$_id:company;
$_imgPath:'../img/#{$_id}/';

body#page_#{$_id} ,body#page_en.-policy.-missionvisionvalue,body#page_en.-policy.-principles{

	.myVision {
		@include g.tabletPmin{
			display: flex;
			justify-content: space-between;
		}
		&__head {
			@include g.tabletPmin{
				width: math.percentage(math.div(280,1216));
				margin-right: 32px;
			}
			@include g.tabletP{
				margin-bottom: 27px;
			}
		}
		&__ttl {
			position: relative;
			font-family: g.$fontAlpha;
			color: nth(g.$mainColor, 1);
			@include g.fz(24);
			font-weight: 700;
			letter-spacing: 0.03em;
			padding-top: 40px;

			@include g.tabletP{
				@include g.fz(17.5);
				padding-top: 46px;
			}

			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				width: 40px;
				height: 3px;
				border-radius: 10px;
				background-color: nth(g.$mainColor, 1);
			}
		}
		&__detail {
			flex: 1;
		}
		&__subTtl {
			@include g.fz(48);
			font-weight: 900;
			line-height: math.div(72, 48);
			letter-spacing: 0.03em;
			margin-top: 33px;
			margin-bottom: 40px;

			@include g.tabletP {
				@include g.fz(36);
				line-height: math.div(54, 36);
				margin-top: 0;
				margin-bottom: 30px;
			}
		}
		&__txt {
			line-height: math.div(32,16);
			@include g.tabletP{
				line-height: math.div(25,14);
			}
		}
		&__def {
			margin-top: 80px;
			@include g.tabletP{
				margin-top: 60px;
			}
			&-inner {
				border-bottom: 1px solid #DDD;
				padding-top: 40px;
				padding-bottom: 80px;
				@include g.tabletP{
					padding-top: 30px;
					padding-bottom: 60px;
				}
				&:first-of-type {
					border-top: 1px solid #DDD;
				}
				&:last-of-type{
					margin-bottom: 40px;
					@include g.tabletP{
						margin-bottom: 30px;
					}
				}
			}
			&Ttl {
				@include g.fz(32);
				font-weight: 900;
				line-height: math.div(48,32);
				letter-spacing: 0.03em;
				margin-bottom: 20px;
				@include g.tabletP {
					@include g.fz(25);
					line-height: math.div(37, 25);
					letter-spacing: normal;
				}
				&-cat {
					display: block;
					font-family: g.$fontAlpha;
					@include g.fz(16);
					color: nth(g.$mainColor, 1);
					font-weight: 700;
					line-height: math.div(28,16);
					letter-spacing: 0.03em;
					margin-bottom: 10px;
					@include g.tabletP {
						@include g.fz(14);
						line-height: math.div(25, 14);
					}
				}
			}
			&Txt {
				line-height: math.div(32,16);
				@include g.tabletP{
					line-height: math.div(25,14);
				}
			}
		}
	}//.myVision

	.myExecutive01 {
		border-top: 1px solid #DDD;
		@include g.tabletPmin{
			display: flex;
			justify-content: space-between;
			padding-top: 40px;
			margin-bottom: 55px;
		}
		@include g.tabletP{
			padding-top: 30px;
			margin-bottom: 40px;
		}
		&:first-of-type {
			margin-top: 63px;
			@include g.tabletP{
				margin-top: 40px;
			}
		}
		&__head {
			@include g.tabletPmin{
				width: math.percentage(math.div(280,1216));
				margin-right: 32px;
			}
			@include g.tabletP{
				margin-bottom: 30px;
			}
		}
		&__ttl {
			@include g.fz(28);
			font-weight: 900;
			line-height: math.div(42, 28);
			letter-spacing: 0.03em;
			@include g.tabletP {
				@include g.fz(21);
				line-height: math.div(32, 21);
			}
			&-post {
				display: block;
				color: nth(g.$mainColor, 1);
				@include g.fz(16);
				font-weight: 700;
				line-height: math.div(28, 16);
				letter-spacing: normal;
				margin-bottom: 10px;
				@include g.tabletP {
					@include g.fz(14);
					line-height: math.div(25, 14);
					margin-bottom: 5px;
				}
			}
		}
		&__list {
			@include g.tabletPmin{
				flex: 1;
			}
		}
		&__item {
			display: flex;
			line-height: math.div(28,16);
			margin-bottom: 5px;
			@include g.tabletP{
				line-height: math.div(25,14);
			}
			&-age {
				display: inline-block;
				font-weight: 700;
				letter-spacing: normal;
				margin-right: 32px;
				min-width: 98px;
				@include g.tabletP{
					margin-right: 10px;
					min-width: 90px;
				}
			}
			&-txt {
				display: inline-block;
				font-weight: 400;
				letter-spacing: normal;
			}
		}
	}//.myExecutive01

	.myExecutive02 {
		margin-top: 63px;
		@include g.tabletP{
			margin-top: 41px;
		}
		&__list {
			@include g.tabletPmin{
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				gap: 20px 32px;
			}
			&::before {
				@include g.tabletPmin{
					content:"";
					display: block;
					width: math.percentage(math.div(280,1216));
					order:1;
				}
			}
			&::after{
				@include g.tabletPmin{
					content:"";
					display: block;
					width: math.percentage(math.div(280,1216));
				}
			}
		}
		&__item {
			font-weight: 700;
			line-height: math.div(28, 16);
			letter-spacing: normal;
			@include g.tabletPmin{
				width: math.percentage(math.div(280,1216));
			}
			@include g.tabletP {
				line-height: math.div(25, 14);
				margin-bottom: 5px;
			}
		}
	}//.myExecutive02

	.myGuideline { //myGuideline
		.modTitle01 {
			padding-top: 43px;
			margin-bottom: 37px;
			@include g.fz(48);
			font-family: g.$fontAlpha;
			@include g.tabletP{
				padding-top: 33px;
				margin-bottom: 30px;
				@include g.fz(36);
			}
		}
		p {
			line-height: 2;
			@include g.tabletP {
				line-height: 1.75;
			}
		}
		.modTxtBold {
			@include g.tabletP {
				@include g.fz(16);
			}
			& + p {
				padding-top: 8px;
				@include g.tabletP {
					padding-top: 13px;
				}
				span {
					font-family: g.$fontAlpha;
				}
			}
		}
		&__detail {
			flex: 1;
		}
		&__subTtl {
			@include g.fz(48);
			font-weight: 900;
			line-height: math.div(72, 48);
			letter-spacing: 0.03em;
			margin-top: 33px;
			margin-bottom: 40px;

			@include g.tabletP {
				@include g.fz(36);
				line-height: math.div(54, 36);
				margin-top: 0;
				margin-bottom: 30px;
			}
		}
		&__txt {
			line-height: math.div(32,16);
			@include g.tabletP{
				line-height: math.div(25,14);
			}
		}
		&__def {
			margin-top: 80px;
			@include g.tabletP{
				margin-top: 60px;
			}
			&-inner {
				border-bottom: 1px solid #DDD;
				padding-top: 40px;
				padding-bottom: 80px;
				@include g.tabletP{
					padding-top: 32px;
					padding-bottom: 60px;
				}
				&:first-of-type {
					border-top: 1px solid #DDD;
				}
				&:last-of-type {
					margin-bottom: 40px;
					@include g.tabletP{
						margin-bottom: 31px;
					}
				}
			}
			&Ttl {
				@include g.fz(32);
				font-weight: 900;
				line-height: math.div(48,32);
				letter-spacing: 0.03em;
				margin-bottom: 20px;
				@include g.tabletP {
					@include g.fz(24.5);
					line-height: 1.5;
					letter-spacing: 0.03em;
				}
				&-cat {
					display: block;
					font-family: g.$fontAlpha;
					@include g.fz(16);
					color: nth(g.$mainColor, 1);
					font-weight: 700;
					line-height: math.div(28,16);
					letter-spacing: 0.03em;
					margin-bottom: 10px;
					@include g.tabletP {
						@include g.fz(14);
						line-height: 1.75;
						letter-spacing: 0;
					}
				}
			}
			&Txt {
				line-height: math.div(32,16);
				@include g.tabletP{
					line-height: 1.77;
				}
			}
		}
	}
}
