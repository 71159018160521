@charset "utf-8";

/*
under
*/


@use "global" as g;

$_id_parentTop:ir_information;
$_id_parent:stocks;
$_id:analyst;
$_imgPath:'../img/#{$_id_parentTop}/#{$_id_parent}/#{$_id}/';

body#page_#{$_id_parentTop}.-#{$_id_parent}.-#{$_id},body#page_en.-stocks.-analyst {

	.myTxtLead{
		margin-bottom: 60px;
		@include g.tabletP{
			margin-bottom: 40px;
		}
	}

	.myTable{
		$_:'.myTable';
		table-layout: fixed;
		border-collapse: separate;
		margin-bottom: 20px;
		@include g.tabletP{
			margin-bottom: 15px;
		}

		&__body{


		}

		&__item{
			#{$_}__title,
			#{$_}__data{
				&:not(:last-child){
					border-right: solid 1px #ddd;
				}
			}

			&:last-child{
				#{$_}__data{
					border-bottom: solid 1px #ddd;
				}
			}

		}

		&__title,
		&__data{
			padding: 20px;
			border-top: solid 1px #ddd;
			@include g.tabletP{
				padding: 15px;
			}
		}

		&__title{
			background-color: #FEF5F5;
			font-weight: 700;
			color: #616161;


		}

		&__data{
			background-color: #fff;

		}
	}

	.myTxtSub{
		margin-bottom: 60px;
		@include g.fz(14);
		color: #616161;
		@include g.tabletP{
			margin-bottom: 40px;
			@include g.fz(12);
		}
	}




}
