@charset "utf-8";
/*
#overview
modBtn
*/

@use "global" as g;
@use 'sass:math';

/*
#styleguide
modBtn01

```

<a href="#" class="modBtn01" href="#">ボタン</a>
<a href="#" class="modBtn01 -color02" href="#">色違い</a>
<a href="#" target="_blank" class="modBtn01">別窓ボタン<span class="modBtn01__icon -blank"></span></a>
<a href="#" class="modBtn01">矢印右<span class="modBtn01__icon -arrowR"></span></a>
<a href="#" class="modBtn01">矢印左<span class="modBtn01__icon -arrowL"></span></a>
<button type="button" class="modBtn01">プラス・マイナス<span class="modBtn01__icon -plus"></span></button>
```
*/

.modBtn01 {
	display: block;
	padding: 25px 60px;
	position: relative;
	width: 100%;
	overflow: hidden;
    z-index: 1;
	background: nth(g.$mainColor, 1);
	border-radius: 999999px;
	border:solid 2px nth(g.$mainColor,1);
	transition: background-color 0s;
	color: #fff;
	@include g.fz(16);
	font-weight: 700;
	line-height: math.div(28, 16);
	letter-spacing: normal;
	text-align: center;

	@include g.tabletP {
		@include g.fz(14);
		line-height: math.div(25, 14);
		padding: 17px 45px 18px;
	}

	$_:&;


	@include g.tabletPmin{
		&:hover{
			transition: background-color 0.1s 0.15s cubic-bezier(0.23, 1, 0.58, 1);
			opacity: 1;
			color:nth(g.$mainColor,1);
			&::before{
				opacity: 1;
				transition: all 0.3s cubic-bezier(0.23, 1, 0.58, 1);
				transform: scale(1);
			}
		}
	}

	&::before{
		content: "";
		display: block;
		position: absolute;
		z-index: -1;
		inset: 0;
		margin: auto;
		border-radius: 999999px;
		opacity: 0;
		transition: opacity 0.35s cubic-bezier(0.23, 1, 0.58, 1), transform 0.5s cubic-bezier(0.23, 1, 0.58, 1);
		transform: scale(0);
		background-color: #fff;
		will-change: transform;
	}

	&.-color02{
		background-color: #616161;
		border-color:#616161;
		@include g.tabletPmin{
			&:hover{
				color:#616161;
			}
		}
	}


	&__icon{
		position: absolute;
		inset-block:0;
		margin: auto 0;

		&.-arrowR,
		&.-arrowL{
			width: 16px;
			height: 13px;
			@include g.tabletP {
				width: 14px;
				height: 11px;
			}

			&::after {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				background-position: 0 0;
				background-repeat: no-repeat;
				background-image: url(#{g.$cmnPath}ico_arrow01_color02.svg);
				background-size: 16px 13px;
				width: 16px;
				height: 13px;

				@include g.tabletP {
					right: 25px;
					background-size: 14px 11px;
					width: 14px;
					height: 11px;
				}
			}

		}
		&.-arrowR{
			right: 40px;
			@include g.tabletP{
				right: 26px;
			}

			.modFormListBtn.-column02 &{
				right: 26px;
			}

			&::after {
				left: 0;
			}
		}
		&.-arrowL{
			left: 40px;
			transform: scale(-1, 1);
			@include g.tabletP{
				left: 26px;
			}

			.modFormListBtn.-column02 &{
				left: 26px;
			}

			&::after {
				right: 0;
			}
		}


		&.-blank {
			width: 16px;
			height: 16px;
			right: 40px;
			@include g.tabletP {
				right: 20px;
			}

			&::after {
				content: "";
				position: absolute;
				left: 0;
				inset-block:0;
				margin-block:auto;
				background-position: 0 0;
				background-repeat: no-repeat;
				background-image: url(#{g.$cmnPath}ico_blank02.svg);
				background-size: 16px 16px;
				width: 16px;
				height: 16px;
				transition: transform map-get(g.$iconMove02, 'sp') map-get(g.$iconMove02, 'easing');

				@include g.tabletP {
					right: 25px;
					background-size: 14px 14px;
					width: 14px;
					height: 14px;
				}
			}
		}

		&.-plus {
			width: 16px;
			height: 16px;
			right: 40px;
			@include g.tabletP {
				right: 20px;
			}

			&::after {
				content: "";
				position: absolute;
				left: 0;
				inset-block:0;
				margin-block:auto;
				background-position: 0 0;
				background-repeat: no-repeat;
				background-image: url(#{g.$cmnPath}ico_plus_color02.svg);
				background-size: 16px 16px;
				width: 16px;
				height: 16px;

				@include g.tabletP {
					right: 25px;
					background-size: 14px 14px;
					width: 14px;
					height: 14px;
				}
			}
		}
	}//icon


	@include g.tabletPmin{
		&:hover{
			#{$_}__icon{
				&.-arrowR,
				&.-arrowL{
					&::after{
						background-image: url(#{g.$cmnPath}ico_arrow01.svg);
						animation: iconMove map-get(g.$iconMove, 'sp') map-get(g.$iconMove, 'easing') map-get(g.$iconMove, 'delay02') 1 map-get(g.$iconMove, 'end');
					}
				}
			}

			&.-color02{
				#{$_}__icon{
					&.-arrowL{
						&::after{
							background-image: url(#{g.$cmnPath}ico_arrow01_color03.svg);
						}
					}
				}
			}


			#{$_}__icon{
				&.-blank{
					&::after{
						background-image: url(#{g.$cmnPath}ico_blank.svg);
						transform: scale(-1, -1);
					}
				}
			}

			#{$_}__icon{
				&.-plus{
					&::after{
						background-image: url(#{g.$cmnPath}ico_plus.svg);
					}
				}
			}

		}
	}

}//.modBtn01


/*
#styleguide
modBtnBox01

```
<p class="modBtnBox01">
	<a class="modBtn01" href="#" target="_blank">ボタン囲み</a>
</p>
```
*/

.modBtnBox01{
	max-width: 592px;
	margin-inline: auto;
	margin-top: 60px;

	@include g.tabletP{
		margin-top: 40px;
	}
}
