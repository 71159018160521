@charset "utf-8";

/*
company
*/


@use "global" as g;
@use 'sass:math';

$_id:securitypolicy;
$_imgPath:'../img/#{$_id}/';

body#page_#{$_id} {

	main#contents {
		section {
			.modBlockISMS {
				display: flex;
					@media only screen and (max-width: 600px) {
						flex-direction: column-reverse;
					}
				&__logo
				{
					width: 155px;
					margin-right: 40px;
					margin-bottom: 40px;
				}
				&__Txt {
					flex: 1;
					@include g.fz(16);
					line-height: math.div(28,16);
					letter-spacing: 0.03em;
					@media only screen and (max-width: 600px) {
						width: 100%;
						margin-bottom: 30px;
					}
				}
			}
		}
	}
}
