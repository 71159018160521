@charset "utf-8";

@use "_variables" as g;
@use 'sass:math';

@mixin pc {
	@media only screen and (max-width: g.$baseWidthWide) {
		@content;
	}
}

@mixin pcmin {
	@media only screen and (min-width: #{g.$baseWidthWide + 1}) {
		@content;
	}
}

@mixin tabletL {
	@media only screen and (max-width: g.$bpTabletL) {
		@content;
	}
}
@mixin tabletLmin {
	@media only screen and (min-width: #{g.$bpTabletL + 1}) {
		@content;
	}
}

@mixin tabletP {
	@media only screen and (max-width: g.$bpTabletP) {
		@content;
	}
}

@mixin tabletPmin {
	@media only screen and (min-width: #{g.$bpTabletP + 1}) {
		@content;
	}
}

@mixin sphoneP {
	@media only screen and (max-width: g.$bpSphoneP) {
		@content;
	}
}

@mixin sphonePmin {
	@media only screen and (min-width: #{g.$bpSphoneP + 1}) {
		@content;
	}
}

@mixin sphoneSE {
	@media only screen and (max-width: 320px) {
		@content;
	}
}

@mixin max($max) {
	@media only screen and (max-width: #{$max}px) {
		@content;
	}
}

@mixin min($min) {
	@media only screen and (min-width: #{$min}px) {
		@content;
	}
}

@mixin print {
  @media print{
    @content;
  }
}

@mixin retina {
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
        @content;
    }
}


// webfont icon
@mixin iconFont() {
	font-family: FontAwesome;
	line-height:1;
}

// clearfix
@mixin clearfix() {
	&:after {
		clear:both;
		content:".";
		display:block;
		height:0;
		visibility:hidden;
	}
}

// font-size
@mixin fz($size: g.$baseFont, $important:false) {
	//IE8以下はpx指定にする場合
	//font-size: if($important, $size + px!important, $size + px);

	$result:math.div($size,16);
	font-size: if($important,($result + rem!important),($result + rem));

}

@function get_vw($size, $viewport:375){
	$rate: math.div(100,$viewport);
	@return $rate * $size * 1vw;
}
@mixin fz_vw($font_size:10,$viewport:375){
	font-size: get_vw($font_size,$viewport);
}
@mixin vw($property:font-size,$size:10,$viewport:375){
	#{$property}: get_vw($size,$viewport);
}

@mixin animation($animation-name,$s,$easing,$count,$end:none,$delay:0) {
	animation: $animation-name;
	animation-delay: $delay;
	animation-duration:$s;
	animation-fill-mode: $end;
	animation-iteration-count:$count;
	animation-timing-function: $easing;
}

@mixin accessibilityHidden {
	border: 0;
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}
