@charset "utf-8";

/*
ir_information_news
*/


@use "global" as g;
@use "sass:math";

$_id_parent:ir_information;
$_id:news;
$_imgPath:'../img/#{$_id_parent}/#{$_id}/';

body#page_#{$_id_parent}.-#{$_id} {
	// ■e-ir対応
	.eir_area_area_news_003 {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: space-between;

		.s_yearController {
			order: 1;
			margin-bottom: 60px;
			@include g.tabletPmin {
				margin: auto 0;
			}
		}

		.s_newsTypeController {
			width: 100%;
			margin-bottom: 20px;
			@include g.tabletPmin {
				width: calc(100% - 280px - 30px + 10px);
				margin: -15px 0 0;
				& + .s_eirBlock {
					margin-top: 80px;
				}
			}

			.s_eirTab {
				.activeTarget:not(.active):not(:hover) {
					a {
						color: g.$baseLinkColor;
					}
				}
			}
		}

		.s_eirBlock {
			order: 10;
			width: 100%;
		}
	}

}
