@charset "utf-8";

/*
#overview
modNav
*/

@use "global" as g;
@use "sass:math";
$_imgPath: "../img/";

/*
#styleguide
modNav01

```
<section class="modNav01">
	<div class="modNav01__inner container">
		<h2 class="modNav01__title">企業情報</h2>
		<ul class="modNav01__list"><li class="modNav01__list-item -index"><a href="/company/" class="modNav01__list-link">企業情報トップ<span class="modNav01__list-icon -arrowR"></span></a></li>
				<li class="modNav01__list-item -message"><a href="/company/message.html" class="modNav01__list-link">トップメッセージ<span class="modNav01__list-icon -blank"></span></a></li>
				<li class="modNav01__list-item -profile"><a href="/company/profile.html" class="modNav01__list-link">会社概要<span class="modNav01__list-icon -arrowR"></span></a></li>
				<li class="modNav01__list-item -vision"><a href="/company/vision.html" class="modNav01__list-link">ミッション・ビジョン・バリュー<span class="modNav01__list-icon -arrowR"></span></a></li>
				<li class="modNav01__list-item -guideline"><a href="/company/guideline.html" class="modNav01__list-link">行動指針<span class="modNav01__list-icon -arrowR"></span></a></li>
				<li class="modNav01__list-item -cg"><a href="/company/cg.html" class="modNav01__list-link">コーポレートガバナンス<span class="modNav01__list-icon -arrowR"></span></a></li>
				<li class="modNav01__list-item -history"><a href="/company/history.html" class="modNav01__list-link">沿革<span class="modNav01__list-icon -arrowR"></span></a></li>
				<li class="modNav01__list-item -executive"><a href="/company/executive.html" class="modNav01__list-link">役員紹介<span class="modNav01__list-icon -arrowR"></span></a></li>

		</ul>
	</div>
</section>
```
*/
.modNav01 {
	background-color: #F5F5F5;
	padding-block:80px;

	@include g.tabletP{
		padding-block:60px;
	}

	&__title{
		margin-bottom: 40px;
		font-weight: 900;
		@include g.fz(28);
		line-height: 1.5;
		letter-spacing: 0.03em;
		@include g.tabletP{
			margin-bottom: 25px;
			@include g.fz(21);
		}

		body#page_en &{
			font-family: g.$fontAlpha;
		}

	}

	&__list{
		$_:'.modNav01__list';

		@include g.tabletPmin{
			display: flex;
			flex-wrap: wrap;
		}

		&-item{
			display: flex;
			@include g.tabletPmin{
				width: calc((100% - 8px*2)/3);
				margin-right: 8px;
				margin-top: 10px;
				&:nth-child(-n + 3){
					margin-top: 0;
				}
				&:nth-child(3n){
					margin-right: 0;
				}
			}

			@include g.tabletP{
				&:not(:last-child){
					margin-bottom: 8px;
				}
			}

			//2階層目まで
			$_navList:(
				company:(index,message,profile,vision,guideline,cg,history,executive),
				business:(index,demaecan),
				en:(index,news,kpi,mailnews,contact,disclaimer)
			);

			@each $_name, $_ary in $_navList {

				@each $_name-child,$_ary-child in $_ary{

					@at-root body#page_#{$_name}.-#{$_name-child}{
						#{$_}-item.-#{$_name-child} #{$_}-link{
							background-color: nth(g.$mainColor,1);
							color:#fff;

							#{$_}-icon{
								display: none;
							}
						}

					}

				}

			}

			//各トップのみactiveにする
			$_navList:(
				en:(policy,business,library,stocks)
			);

			@each $_name, $_ary in $_navList {

				@each $_name-child,$_ary-child in $_ary{

					@at-root body#page_#{$_name}.-#{$_name-child}.-index{
						#{$_}-item.-#{$_name-child} #{$_}-link{
							background-color: nth(g.$mainColor,1);
							color:#fff;

							#{$_}-icon{
								display: none;
							}
						}

					}

				}

			}

			//各階層トップもナビに入る場合
			$_navList1_2:(
			en:(policy,business,library,stocks,news)
			);

			@each $_name, $_ary in $_navList1_2 {

				@each $_name-child,$_ary-child in $_ary{

					@at-root body#page_#{$_name}.-#{$_name-child}{

						#{$_}-item.-index #{$_}-link{
							background-color: #fff;
							color:g.$baseColor;

							@include g.tabletPmin{
								&:hover{
									background-color: nth(g.$mainColor,1);
									color:#fff;
								}
							}

							#{$_}-icon{
								display: block;
							}
						}

					}

				}

			}



			//3階層目
			$_navList2:(
				ir_information:(
					stocks:(index,basicinfo,quote,kpi,meeting,pn,analyst),
					library:(index,report01,report02,doc01),
					policy:(index,disclosure,esg),
					investors:(index,kpi,dividend)
				),
			);

			@each $_name, $_ary in $_navList2 {

				@each $_name-child,$_ary-child in $_ary{

					@each $_name-child-child in $_ary-child{

						@at-root body#page_#{$_name}.-#{$_name-child}.-#{$_name-child-child}{
							#{$_}-item.-#{$_name-child-child} #{$_}-link{
								background-color: nth(g.$mainColor,1);
								color:#fff;

								#{$_}-icon{
									display: none;
								}
							}

						}
					}

				}

			}


		}


		//イレギュラー？4階層目は親ページがactive
		@at-root body#page_ir_information.-stocks.-meeting_archives{
			#{$_}-item.-meeting #{$_}-link{
				background-color: nth(g.$mainColor,1);
				color:#fff;

				#{$_}-icon{
					&::after{
						content:inherit;
					}
				}
			}

		}


		&-link{
			background-color: #fff;
			border-radius: 5px;
			position: relative;
			transition:color,background-color 0.3s ease;
			display: flex;
			align-items: center;
			width: 100%;
			padding: 25px 70px 25px 31px;
			font-weight: 700;
			@include g.tabletPmin{
				&:hover{
					opacity: 1;
					background-color: nth(g.$mainColor,1);
					color:#fff;

					#{$_}-icon{
						background-color: #fff;
						&.-arrowR{
							&::after{
								background-image: url(#{g.$cmnPath}ico_circle_arrow_color02.svg);
								animation: iconMove map-get(g.$iconMove, 'sp') map-get(g.$iconMove, 'easing') map-get(g.$iconMove, 'delay') 1 map-get(g.$iconMove, 'end');
							}
						}
						&.-blank{
							&::after{
								background-image: url(#{g.$cmnPath}ico_circle_blank_color02.svg);
								transform: scale(-1, -1);
							}
						}
					}



				}
			}

			@include g.tabletP{
				padding: 20px 50px 20px 20px;
			}

		}//link

		&-icon{
			position: absolute;
			right: 30px;
			inset-block:0;
			margin-block:auto;
			width: 20px;
			height: 20px;
			background-color: nth(g.$mainColor, 1);
			border-radius: 50%;
			transition: inherit;
			overflow: hidden;
			@include g.tabletP {
				right: 20px;
			}

			&::after{
				content:"";
				position: absolute;
				left: 0;
				inset-block:0;
				width: 20px;
				height: 20px;
				background-size: 20px 20px;
				background-repeat: no-repeat;
				background-position: 0 0;
				margin-block:auto;
				@include g.tabletP{
				}
			}

			&.-arrowR{
				&::after{
					background-image: url(#{g.$cmnPath}ico_circle_arrow.svg);
				}
			}

			&.-blank {
				&::after {
					background-image: url(#{g.$cmnPath}ico_circle_blank.svg);
					transition: transform map-get(g.$iconMove02, 'sp') map-get(g.$iconMove02, 'easing');
				}
			}

		}
	}

}//.modNav01

/*
#styleguide
modNavIndex

```
<div class="modNavIndex">
    <div class="container">
        <div class="modNavIndex__list">
            <div class="modNavIndex__block">
                <p class="modNavIndex__title"><a href="#" class="modNavIndex__title-link">タイトル<span class="modNavIndex__title-icon"></span></a></p>
                <p class="modNavIndex__item"><a href="#" class="modNavIndex__item-link">リンク<span class="modNavIndex__item-icon"></span></a></p>
                <p class="modNavIndex__item"><a href="#" target="_blank" class="modNavIndex__item-link">別窓リンク<span class="modNavIndex__item-icon -blank"></span></a></p>
            </div>
            <div class="modNavIndex__block">
                <p class="modNavIndex__title"><a href="#" class="modNavIndex__title-link">タイトル<span class="modNavIndex__title-icon"></span></a></p>
                <p class="modNavIndex__item"><a href="#" class="modNavIndex__item-link">リンク<span class="modNavIndex__item-icon"></span></a></p>
                <p class="modNavIndex__item"><a href="#" target="_blank" class="modNavIndex__item-link">別窓リンク<span class="modNavIndex__item-icon -blank"></span></a></p>
            </div>
            <div class="modNavIndex__block">
                <p class="modNavIndex__title"><a href="#" class="modNavIndex__title-link">タイトル<span class="modNavIndex__title-icon"></span></a></p>
                <p class="modNavIndex__item"><a href="#" class="modNavIndex__item-link">リンク<span class="modNavIndex__item-icon"></span></a></p>
                <p class="modNavIndex__item"><a href="#" target="_blank" class="modNavIndex__item-link">別窓リンク<span class="modNavIndex__item-icon -blank"></span></a></p>
            </div>
            <div class="modNavIndex__block">
                <p class="modNavIndex__title"><a href="#" class="modNavIndex__title-link">タイトル<span class="modNavIndex__title-icon"></span></a></p>
                <p class="modNavIndex__item"><a href="#" class="modNavIndex__item-link">リンク<span class="modNavIndex__item-icon"></span></a></p>
                <p class="modNavIndex__item"><a href="#" target="_blank" class="modNavIndex__item-link">別窓リンク<span class="modNavIndex__item-icon -blank"></span></a></p>
            </div>
        </div>
    </div>
</div>
```
*/
.modNavIndex {
	$_: &;
	background-color: nth(g.$partsColor, 1);
	padding: 80px 0 80px;
	@include g.tabletP {
		padding: 60px 0 60px;
	}
	&__list {
		@include g.tabletPmin {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin-bottom: -60px;
			&::after {
				content: "";
				display: block;
			}
		}
	}//__inner
	&__list::after,
	&__block {
		@include g.tabletLmin {
			width: calc(33.3333% - #{32px * math.div(2, 3)});
		}
	}
	&__block {
		@include g.tabletPmin {
			margin-bottom: 60px;
		}
		&:not(:last-child) {
			@include g.tabletP {
				margin-bottom: 40px;
			}
		}
	}
	&__title {
		$_2: &;
		border-bottom: 1px solid nth(g.$partsColor, 2);
		display: flex;
		align-items: center;
		position: relative;
		@include g.fz(20);
		font-weight: 900;
		letter-spacing: 0.03em;
		margin-bottom: 20px;
		padding-bottom: 20px;
		@include g.tabletP {
			@include g.fz(15.8);
		}
		&-link {
			display: flex;
			align-items: center;
			position: relative;
			@include g.tabletPmin {
				&:hover {
					#{$_2}-icon {
						&::after {
							animation: iconMove map-get(g.$iconMove, 'sp') map-get(g.$iconMove, 'easing') map-get(g.$iconMove, 'delay') 1 map-get(g.$iconMove, 'end');
						}
					}
				}
			}
		}
		&-icon {
			position: relative;
			top: .5px;
			flex-shrink: 0;
			width: 20px;
			height: 20px;
			margin-left: 8px;
			background-color: nth(g.$mainColor, 1);
			border-radius: 50%;
			overflow: hidden;
			&::after {
				content: "";
				background: url(#{g.$cmnPath}ico_circle_arrow.svg) no-repeat center / contain;
				position: absolute;
				top: 0;
				left: 0;
				width: inherit;
				height: inherit;
			}
			@include g.tabletP {
				width: 16px;
				height: 16px;
				margin-left: 6px;
			}
		}
	}
	&__item {
		&:not(:last-child) {
			margin-bottom: 10px;
		}
	}
	&__icon {
		position: absolute;
		&.-blank {
			width: 12px;
			height: 12px;
			margin: .6em 0 0 9px;
			&::after {
				content: "";
				background: url(#{g.$cmnPath}ico_blank.svg) no-repeat center / cover;
				position: absolute;
				top: 0;
				left: 0;
				width: inherit;
				height: inherit;
				transition: transform map-get(g.$iconMove02, 'sp') map-get(g.$iconMove02, 'easing');
			}
			@include g.tabletPmin {
				#{$_}__link:hover & {
					&::after {
						transform: scale(-1, -1);
					}
				}
			}
			@include g.tabletP {
				width: 10px;
				height: 10px;
				margin-left: 5px;
			}
		}
	}
	&__link {
		display: inline-block;
		position: relative;
		font-weight: bold;
		padding-left: 17px;
		&::before {
			content: "";
			background: url(#{g.$cmnPath}ico_arrow02.svg) no-repeat center / contain;
			position: absolute;
			top: .55em;
			bottom: 0;
			left: 0;
			width: 7px;
			height: 12px;
			@include g.tabletP {
				width: 6px;
				height: 10.29px;
			}
		}
		@include g.tabletP {
			padding-left: 14px;
		}
	}
}//.modNavIndex

/*
#styleguide
xxx

```
```
*/
.xxx {
	$_: &;
}//.xxx
