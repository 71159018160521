@charset "utf-8";

/*
company_profile
*/


@use "global" as g;
@use "sass:math";

$_id_parent:company;
$_id:profile;
$_imgPath:'../img/#{$_id_parent}/#{$_id}/';

body#page_#{$_id_parent}.-#{$_id},body#page_en.-policy.-profile {
	.myBlockMap {
		$_: ".myBlockMap";
		border-radius: 5px;
		position: relative;
		overflow: hidden;
		&::before {
			content: "";
			display: block;
			padding-top: 50%;
		}
		&:not(:first-child) {
			@include g.tabletP {
				margin-top: -10px;
			}
		}
		&:not(:last-child) {
			@include g.tabletP {
				margin-bottom: 10px;
			}
		}
		& > * {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}// .myBlockMap

	.myList01 {
		$_: ".myList01";
		&__item {
			&:not(:last-child) {
				margin-bottom: 20px;
				@include g.tabletP {
					margin-bottom: 10px;
				}
			}
			&-title {
				font-weight: bold;
			}
		}
	}// .myList01

	.myList02 {
		$_: ".myList02";
		display: table;
		&:not(:last-child) {
			margin-bottom: 20px;
		}
		&__item {
			display: table-row;
			&-title,
			&-body {
				display: table-cell;
			}
			&-title {
				padding-right: 32px;
				@include g.tabletP {
					padding-right: 20px;
				}
			}
		}
	}// .myList02
}


body#page_en.-policy.-profile{

	.myList02 {
		@include g.tabletP{
			table-layout: fixed;
		}
		&__item {
			&-title {
				@include g.tabletP {
					width: calc(10em + 20px);
				}
			}
		}
	}// .myList02
}
