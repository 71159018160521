
@charset "utf-8";
/*
#overview
base
*/

@use "global" as g;

@use 'layout/_header';
@use 'layout/_contents';
@use 'layout/_footer';
@use 'layout/_breadcrumb';
@use 'layout/_nav';
@use 'layout/_wrapper';
@forward 'layout/_form';

body {
	&.-noscroll,
	&[data-nav__is_open = "true"] {
		overflow: hidden;
		@include g.tabletP {
			height: 100vh;
			left: 0;
			position: fixed;
			width: 100%;
		}
	}
}
